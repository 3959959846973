.LoginContainer{
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333f4f;
}
.LoginWrapper{
    max-width: 500px;
    min-width: 500px;
    width: 500px;



}
.LoginBody{
    background-color: #222b35;
    box-shadow: 0px 0px 10px 0px #222b35;
    padding: 2em 2em;
    margin-top: 1em;
}
.LoginBody :global(.form-control){
    background-color: transparent;
    border: 1px solid #b3b3b3;
    padding: 0.7rem 0.75rem;
}
.LoginBody h4{
    text-align: center;
    width: fit-content;
    margin: 0 auto 2em auto;
    padding: 1em;
    background: #1c242c;
    text-transform: uppercase;
    font-size: 1.3rem;
    border-radius: 4px;
}
.StartCenterValue{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.StartCenterValue label{
    margin: 0;
}
.EndCenterValue{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.CountryCode{
    width: 80px;
    border-end-end-radius: 0px;
    border-start-end-radius: 0px;
}
.CountryPhoneNumber{
    border-start-start-radius: 0px;
    border-end-start-radius: 0px;
    border-left: 0 !important;
}
.CountryCodeDropDown :global(.dropdown-menu){
    max-height: 380px;
    overflow-y: scroll;
}
.CountryCodeDropDown :global(.dropdown-toggle){
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-end-end-radius: 0px;
    border-start-end-radius: 0px;
    background: #29333e;
    height: 100%;
    border: 1px solid #b3b3b3 !important;
    padding: 0.3rem  0.75rem;
}
.CountryCodeDropDown :global(.dropdown-item){
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
