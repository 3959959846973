.Container{
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.Error404{

}
.Error404 .Title{
    font-size: 60px;
    font-weight: bold;
}
.Error404 .Description{

}