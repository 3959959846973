
.dark {
    --breakpoint-sm: 37.5rem;
    --breakpoint-md: 60rem;
    --max-width: var(--breakpoint-md);
    --space-1: 0.25rem;
    --space-2: 0.5rem;
    --space-3: 0.75rem;
    --space-4: 1rem;
    --color-background: #000;
    --color-background-gray: #1b1b1b;
    --text-color: #fff;
    --color-primary: #f90505;
    --color-secondary: #03DAC6;
    --color-board-number: #1dee19;
    --color-gray: rgba(255,255,255, 0.11);
    --font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    --font-size-1: 0.625rem;
    --font-size-2: 0.75rem;
    --z-index-1: 1010;
    --z-index-2: 1020;
}

.light {
    --breakpoint-sm: 37.5rem;
    --breakpoint-md: 60rem;
    --max-width: var(--breakpoint-md);
    --space-1: 0.25rem;
    --space-2: 0.5rem;
    --space-3: 0.75rem;
    --space-4: 1rem;
    --color-background: #fff;
    --color-background-gray: #f1f1f1;
    --text-color: #000;
    --color-primary: #6200EE;
    --color-secondary: #03DAC6;
    --color-board-number: #1dee19;
    --color-gray: rgba(0,0,0, 0.11);
    --font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    --font-size-1: 0.625rem;
    --font-size-2: 0.75rem;
    --z-index-1: 1010;
    --z-index-2: 1020;
}
.ChipMove{
    position: absolute;
    transition: all .2s ease-in-out !important;
    background: transparent !important;
    z-index: 99999;
    width: 32px !important;
    height: 32px !important;
}
.ChipMove img{
    width: 37px !important;
    height: 37px !important;
}

@media (max-width: 720px) {
    .ChipMove{
        width: 24px !important;
        height: 24px !important;
    }
    .ChipMove img{
        width: 29px !important;
        height: 29px !important;
    }
}
.white-background{
    background: #fff !important;
}