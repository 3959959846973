
.PopupOverlay{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.ForcePopup{
    z-index: 999999999;
}
.PopupContainer{
    background: #333;
    border-radius: 4px;
    box-shadow: 0 0 7px #101010, 0 0 10px #101010;
    display: table;
}
.Dark{
    background: #000;
}
.Dark .PopupHeader{
    border-color: #000 !important;
}
.Dark .PopupFooter{
    border-color: #000 !important;
}
.SmallPopup{
    min-width: 33%;
    max-width: 33%;
}
.LargePopup{
    min-width: 40%;
    max-width: 40%;
}
.PopupHeader{
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #404040;
    display: flex;
    align-items: center;
}
.PopupFooter{
    width: 100%;
    border-top: 1px solid #404040;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5em 1em;
}
.PopupBody{

    display: table-row;
    height: 100%;
}
.PopupBodyWrapper{
    padding: 1em;
}
.PopupDescription{
    font-size: 0.9em;
    color: #ababab;
}
.PopupHeader p{
    flex: 1;
    color: #fff;
    margin: 0;
    padding: 0 1em;
}
.PopupHeader button{
    background: none;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-right: 10px;
}
@media (max-width: 720px) {
    .SmallPopup{
        min-width: 98%;
        max-width: 98%;
    }
    .PopupFooter button{
        font-size: 0.8em;
    }
}