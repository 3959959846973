.LoginContainer{
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333f4f;
}
.WebsiteLoginContainer{
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}
.RegisterComplete{
    align-items: flex-start;
}
.WebsiteLoginContainer .LoginWrapper{
    max-width: 100%;
    min-width: 100%;
    width: 100%;

}
.LoginWrapper{
    min-width: 500px;
    max-width: 500px;

}

.LoginBody{
    background-color: #222b35;
    box-shadow: 0px 0px 10px 0px #222b35;
    padding: 2em 2em;
    margin-top: 1em;
}
.WebsiteLoginContainer label{
    font-size: 15px;
    font-weight: normal;
    line-height: 40px;
    color: #000;
}
.WebsiteLoginContainer .LoginBody{
    background: #fff;
    box-shadow: none;
    margin-top: 0;
}
.LoginBody :global(.form-control){
    background-color: transparent;
    border: 1px solid #b3b3b3;
    padding: 0.7rem 0.75rem;
}
.LoginBody select option{
    background: #ffffff;
    color: #7a7a7a;
}

.LoginBody h4{
    text-align: center;
    width: fit-content;
    margin: 0 auto 2em auto;
    padding: 1em;
    background: #1c242c;
    text-transform: uppercase;
    font-size: 1.3rem;
    border-radius: 4px;
}
.StartCenterValue{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.StartCenterValue label{
    margin: 0;
}
.EndCenterValue{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.LoadingContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.WebsiteLoginContainer h5{
    font-size: 15px;
    color: #7a7a7a;
    font-weight: normal;
    line-height: 25px;
    margin-bottom: 1em;
    text-align: center;
}
.WebsiteLoginContainer button{
    border-radius: 0 !important;
    font-size: 14px;
    padding: 13px 34px;
    text-transform: uppercase;
}
.SubLoginText{
    margin-top: 2em;
    font-size: 15px;
}
.WebsiteLoginContainer a{
    color: #d50f0a;
}
.WebsiteLoginContainer a:hover{
    color: #333;
    transition: all ease-in-out 0.5s;
}
.Label{
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    color: #333;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    font-size: 15px;
    font-weight: normal;
    line-height: 40px;
}
.WebsiteLoginContainer :global(.form-control){
    border: 2px solid #e3e3e3;
    border-radius: 0;
    color: #333;
}
.WebsiteLoginContainer :global(.form-control):focus{
    border: 2px solid #d50f0a;
    box-shadow: none;
}
.WebsiteLoginContainer :global(.form-control):disabled{
    color: #7a7a7a;
}
footer {
    clear: both;
    position: relative;
    min-height: auto;
    margin-top: 0;
    background: #242424;
    padding: 30px 0;
}
.FullHeight{
    height: 100%;
    background: #fff;
    overflow-y: scroll;
}

.LogoCopyright{
    text-align: center;
}
.Copyright{
    color: #d0cecf;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 1.9em;
}
footer ul{
    list-style: none;
    max-width: 90%;
    margin: 0px auto;

}
footer ul li{
    display: inline-block;
    line-height: 30px;
    display: inline-block;
    margin: 0 10px;
}
footer ul li a{
    color: #d0cecf;
    font-size: 11px;
}
footer ul li a:hover{
    color: #d50f0a;
    transition: all ease-in-out 0.5s;
}
.FooterMenu{
    padding-bottom: 5px;
    border-bottom: 1px solid #404040;
    margin-bottom: 20px;

}
@media (min-width: 600px) {
    .WebsiteLoginContainer .LoginWrapper{
        min-width: 600px !important;
        max-width: 600px !important;;
        height: 600px !important;;
    }
    footer{
        min-height: 200px;
        margin-top: -100px !important;

    }
}
.NoFloat{
    min-height: auto;
    margin-top: 280px;
}
.SuccessLogo{
    font-size: 32px;
    margin-bottom: 0.3em;
}

.CountryCode{
    width: 80px;
    border-end-end-radius: 0px;
    border-start-end-radius: 0px;

}
.CountryPhoneNumber{
    border-start-start-radius: 0px;
    border-end-start-radius: 0px;
}
.CountryCodeDropDown :global(.dropdown-menu){
    max-height: 380px;
    overflow-y: scroll;
}
.CountryCodeDropDown :global(.dropdown-toggle){
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-end-end-radius: 0px;
    border-start-end-radius: 0px;
    background: #fff;
    color: #333;
    height: 100%;
    border: 2px solid #e3e3e3 !important;
    padding: 0.3rem  0.75rem;
    border-right: 0 !important;
}
:global(.show .btn-secondary.dropdown-toggle):focus{
    box-shadow: none !important;
}
:global(.show .btn-secondary.dropdown-toggle){
    background: #fff;
    color: #333
}
.CountryCodeDropDown :global(.dropdown-item){
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
hr{
    color: #333;
}
.RegisterFooter{
    min-height: auto;
    margin-top: 30px;
}
.RegisterCheckBox{
    display: flex;
    align-items: center;
}
.RegisterCheckBox label{
    font-size: 12px;
    margin-left: 0.7em;
}
.RegisterCheckBox input{
    margin-top: 0;
    width: 1.3em;
    height: 1.3em;

}
.RegisterCheckBox :global(.invalid-feedback),
.RegisterCheckBox :global(.valid-feedback){
    display: none;
}
@media (max-width: 720px) {
    footer{
        display: none;
    }
    .FullHeight{
        min-height: 100vh;
        height: 100vh;
    }
    .LoginBody h5{
        font-size: 12px;
    }
    .SubLoginText{
        font-size: 11px;
    }
    .RegisterCheckBox label{
        line-height: 17px;
    }
    .LoginWrapper{
        max-width: 95%;
        min-width: 95%;
    }
    .LoginWrapper .LoginBody{
        padding: 1em;
    }
}
.SocialIcon{
    font-size: 19px;
    color: #fff;
    background: #d50f0a;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    display: block;
    text-align: center;
    line-height: 38px;
}
.SocialIcon:hover{
    background-color: #fff;
    color: #d50f0a;
    transition: all ease-in-out 0.3s;
}
.EyePassword{
    position: absolute;
    right: 24px;
    font-size: 19px;
    cursor: pointer;
}

.EyePassword svg{
    color: #787878;
}
.EyePassword:hover > svg{
    color: #333;
}