@keyframes SpinAnimation {
    to{
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.FeedScreen{
    width: 100%;
    height: 100%;
}
.NeighboursBar{
    position: absolute;
    right: -77px;
}
.WheelDate{
    height: 45px;
    line-height: 44px;
    margin: 1em 0px 0px 0px;
    text-align: center;
    color: #1dee19;
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
    font-style: italic;
}
.BlockAccount{
    width: 100%;
    height: 100%;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.WheelNumberContainer{
    position: relative;
}
.Container{
    position: relative;
    padding: 0 2em 1em 2em;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;

}
.TimerText{
    color: #3dc733;
    margin-left: 0.5em;
}
.Display{
    width: 100%;
    display: flex;
    align-content: center;
}
.TableContainer{
    display: flex;
    height: 100%;
}
.Monitor{
    color: #fff;
    font-family: "LCD-Font", "IranSANS";
    font-size: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    height: 100px;
    letter-spacing: 5px;
    padding: 0.2em 0em;
    border-radius: 10px;
    justify-content: center;
    background: #0000;
}
.LogoRightOfMonitor{
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.LogoRightOfMonitorRoulette{
    height: 110px;
}
.LanguagesRightOfMonitor,
.LanguagesRightOfMonitorMobile{
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}
.LanguagesRightOfMonitor a,
.LanguagesRightOfMonitorMobile a
{
    color: #fff;
    padding: 5px 10px;
    background: #3e3e3e;
    margin: 0 5px;
    border-radius: 5px;
}
.LanguagesRightOfMonitor a:hover,
.LanguagesRightOfMonitorMobile a:hover{
    background-color: #f90505;
    transition: background-color ease-in-out 0.3s;
}
.LanguagesRightOfMonitorActive{
    background-color: #f90505 !important;
    cursor: none !important;
}
.MobileLogoOfMonitor{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Monitor div{
}
.VideoMonitor{
    width: 100%;
    display: flex;
    height: calc(60% - 100px);
    max-height: calc(60% - 100px);
    /*height: 450px*/
}
.WonContainer{
    width: 100%;
    display: flex;
    align-items: flex-start;
}
.TopCells{
    width: 100px;
}
.MonitorContainer{
    width: 100%;
}
.LeftDisplay{
    width: 52%;
    height: 100%;
    order: 1;
}
.LeftGreenDisplay{
    width: 48%;
}
.LeftGreenDisplayContainer{
    height: 100px;
    display: flex;
    align-items: center;
}
.GrayCircleButton{
    background: #e4e4e4  !important;
}
.LeftGreenDisplayContainer .CircleButton{
    width: 55px;
    height: 55px;
    margin: 0;
}
.LeftGreenDisplayContainer .CircleButton .CircleButtonWrapper{
    height: 47px;
    width: 47px;
    min-width: 47px;
    font-size: 19px;
}
.RedCircleButton{
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(236,236,236,1) 25%, rgba(210,210,210,1) 39%, rgba(13,13,13,1) 41%, rgba(24,24,24,1) 77%, rgba(83,83,83,1) 95%, rgba(156,156,156,1) 100%) !important;
}
.RedCircleButton .CircleButtonWrapper{
    color: #f90505 !important;
}
.LeftGreenDisplayWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.NumberCounterWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.LeftGreenDisplayLastNumber{
    top: 25px;
    left: 0;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.RightDisplay{
    width: 48%;
    padding: 0 0 0 1em;
    height: 100%;
    order: 2;
}
.RightDisplayContainer{
    height: calc(100% - 100px);
    display: flex;
}
.Table{
    width: 60%;
    height: 100%;
    position: relative;
    order: 1;
}
.FullTable{
    width: 100%;
    direction: ltr !important;
}
.Table table{
    height: 100%;
}
.FullTable table{
    width: 70%;
    margin: 1em auto 0 auto;
    height: calc(100% - 120px) !important
}
.Table table td{
    font-family: "Agressive-Font";
    border: 0;
    max-width: 61px;
    min-width: 61px;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    position: relative;
}
.CellNumber{
    padding: 0.71em 0.4em;
    position: relative;
}

.TextSmall.CellNumber{
    padding: 1.1em 0.4em;
}
.TableCellActive{
    color: #000 !important;
}
.Table table td.Vertical{
    writing-mode: tb-rl;
    text-orientation: mixed;
}
.Table table td:hover{
}
.TextGreenForce{
    color: #3dc733 !important;
}
.TextGreen{
    color: #3dc733;
}
.TextRight{
    text-align: right;
}
.TextSmall{
    font-size: 17px !important;
}
.TextRed{
    color: #f90505;
}
.ColAction{
    padding: 0 0.5em !important;
    text-align: center;
    font-size: 14px;
    color: #b70001;
    cursor: pointer;
}
.ColAction:hover{
    color: #f90505;
    transition: all ease-in-out 0.4s;
}
.MonitorScreen{
    width: 100%;
    /*border: 20px solid #2a2a2a;*/
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

}


.MonitorScreen p{
    height: initial !important;
    margin: 0;
    padding: 0;
}
.Diamond {
    height: 70px;
}
.DiamondRed{
    overflow: hidden;
    width: 28px;
    height: 28px;
    background: #a60000;
}
.DiamondBlack{
    overflow: hidden;
    width: 28px;
    height: 28px;
    background: #212121;
}
.TableRightBorder{
    width: 12px;
    height: 100%;
    background: transparent;
    position: absolute;
    right: -6px;
    top: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.VerticalLine{
    width: 2px;
    height: 100%;
    background: #393939;
}
.TableLeftBorder{
    width: 12px;
    height: 100%;
    background: transparent;
    position: absolute;
    left: -6px;
    top: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.TableTopBorder{
    width: 100%;
    height: 12px;
    background: transparent;
    position: absolute;
    left: 0px;
    right: 0px;
    top: -6px;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.WheelWrapper{
    margin-top: 30px;
}
.HorizontalLine{
    height: 2px;
    width: 100%;
    background: #393939;
}
.TableBottomBorder{
    width: 100%;
    height: 12px;
    background: transparent;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: -6px;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.TableTopLeftCorner{
    width: 16px;
    height: 16px;
    background: transparent;
    position: absolute;
    top: -8px;
    left: -8px;
    z-index: 99999;
}
.ToolsChipsContainer{
    width: 40%;
    order: 2;
}
.SubToolsContainer{
    width: 65%;
    margin: 1em auto 1em auto;
}
.Chips{

}
.NeighboursBar label{
    display: inline-block;
}
.Tools{
    width: 100%;
}
.ChipsWrapper{
    padding-top: 33px;
    margin: 0 auto;
}
.CellChipButton{
    width: 32px;
    height: 32px;
    background: transparent;
    border: none;
    border-radius: 100%;
    position: absolute;
    z-index: 99999;

}
.CellChipButton img{
    width: 32px;
    height: 32px;
}
.ChipButton{
    width: 62px;
    height: 62px;
    background: transparent;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.2em 2em;
}
.ChipButton img{
    width: 52px;
    height: 52px;
}
.ChipButton:hover, .ChipActive{
    background-color: #006f32;
    border: 2px solid #22b22d;

    transition: all ease-in-out 0.3s;
}
.ToolsWrapper{
    padding-top: 57px;
    margin: 0 auto;
}
.ToolsButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(236,236,236,1) 25%, rgba(210,210,210,1) 39%, rgba(13,13,13,1) 41%, rgba(24,24,24,1) 77%, rgba(83,83,83,1) 95%, rgba(156,156,156,1) 100%);
    border-radius: 45px;
    height: 45px;
    position: relative;
}
.DateButton{
    height: 50px;
    border: 0;
    background: none;
}
.DateToolsButtonWrapper{
    height: 41px !important;
    background: black;
    color: #1dee19 !important;
}
.CircleGrayButton{
    height: 45px;
    width: 45px;
    max-height: 45px;
    max-width: 45px;
    min-height: 45px;
    min-width: 45px;
    background: #4f4f4f;
    border: none;
    border-radius: 100%;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
}
.MobileFloatingMenuWrapperActive{
    color: #3dc733 !important;
}
.ActiveCircleGrayButton{
    background-color:#3dc733 !important;
    color: #333 !important;
    transition: all ease-in-out 0.3s;
}
.CircleGrayButton:hover{
    background: #e4e4e4;
    transition: all ease-in-out 0.2s;
}
.ActiveCircleGrayButton:hover{
    background-color:#3dc733;
}
.ToolsButtonWrapper{
    background: #000;
    color: #fff;
    width: 100%;
    height: 34px;
    border-radius: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    font-style: italic;
}
.ToolsButtonDisabled .ToolsButtonWrapper{
    background: #000;
    cursor: initial;
}
.ToolsButton:hover > .ToolsButtonWrapper{
    color: #1dee19;
    transition: all ease-in-out 0.3s;
}
.CircleButton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(236,236,236,1) 25%, rgba(210,210,210,1) 39%, rgba(13,13,13,1) 41%, rgba(24,24,24,1) 77%, rgba(83,83,83,1) 95%, rgba(156,156,156,1) 100%);
    border-radius: 100%;
    height: 100px;
    width: 100px;
}
.MenuDisabled{
    color: #484848 !important;
}
.CircleButtonWrapper{
    background: #000;
    color: #fff;
    height: 90px;
    min-width: 90px;
    width: 90px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    font-style: italic;
}
.CircleButton:hover > .CircleButtonWrapper{
    color: #1dee19;
    transition: all ease-in-out 0.3s;
}
.RedCircleButtonWrapper{
    background: #fb0102;
    color: #fff !important;
}
.GreenCircleButtonWrapper{
    background: #02b051;
}
.GreenBackground{
    background: #3dc733;
}
.Loading{
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
.Loading svg{
    font-size: 24px;
    margin-bottom: 3px;
}

.StatsWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.StatsLeftBar{
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.StatsCenterBar{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.StatsTableWrapper{
    width: 100%;
    height: 100%;
}
.StatsTable{
    width: 100%;
    margin-bottom: 15px;
}
.StatsTableWrapper table:last-child{
    margin-bottom: 0 !important;
}
.StatsTable th{
    color: #02b051;
    font-size: 13px;
    font-weight: bold;
    font-family: "Agressive-Font";
    text-align: center;
    border: 1px solid #5d5d5d;
    padding: 0.8em 0.3em;
    width: 25%;
}
.StatsTable td{
    text-align: center;
    border: 1px solid #5d5d5d;
    background: #262626;
    padding: 0.8em 1em;
    font-weight: bold;
    width: 25%;
}
.StatsCellNoBorder{
    border: 0 !important;
    background: none !important;
}
.StatsColRed{
    background: transparent !important;
}
.StatsRightBar{
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.StatsBarItems{
    width: 35px;
    border: 1px solid #5d5d5d;
    list-style: none;
    margin: 0;
    padding: 0;
}
.StatsBarItems li{
    height: 35px;
    color: #e6e4e7;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.StatsBarItems li.StatsBarColdIcon{
    background: #054068;
}
.StatsBarItems li.StatsBarFireIcon{
    background: #5a1706;
}
.TopCellWrapper{
    width: 60px;
    margin-right: 15px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.TopCellTable{
    height: 100%;
}
.TopCellTable td{
    border: 1px solid #cfcfcf;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    font-family: "Agressive-Font";
}
.TopCellTable td.NoBorder{
    border: none
}
.TopCellTable .CellRed{
    background: #fb0102;
}
.TopCellTable .CellGreen{
    background: #02b051;
}
.WheelContainer{
    width: 44%;
    /*position: relative;*/
}
.WonBoard{
    width: 56%;
}
.WonBoardToolbar{
    width: 100%;
    display: flex;
    align-items: center;
    margin: 1em 0px 0px 0px;
    position: relative;
}
.NeighbourButton{
    background: transparent;
    color: #e2e2e2;
    font-size: 12px;
    border: 1px solid #393939;
    max-width: 35px;
    max-height: 35px;
    min-width: 35px;
    min-height: 35px;
}
.NeighbourButton:hover, .NeighbourButtonActive{
    background: #02b051;
    border-color: #02b051;
    transition: all ease-in-out 0.3s;
}
.SpeakerWonBoardButton{

}
.WonBoardButton, .SpeakerWonBoardButton{
    background: none;
    border: none;
    max-width: 45px;
    max-height: 45px;
    min-width: 45px;
    min-height: 45px;
    color: #a8a8a8;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 10px;
}
.WonBoardButtonCircle{
    background: #4c4c4c;
    max-width: 33px;
    max-height: 33px;
    min-width: 33px;
    min-height: 33px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}
.VolumeBox{
    background: #4c4c4c;
    max-width: 170px;
    min-width: 170px;
    min-height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: calc(100% + 10px);
    left: 0;
    z-index: 99999999999;
}
  
.WonBoardButtonActive, .WonBoardButton:hover{
    background-color: #006f32;
    border: 2px solid #22b22d;
    padding: 5px;
    transition: all ease-in-out 0.3s;

}
.WonBoardLastButton{
    margin-right: 8em
}
.WonBoardButton span{
    font-size: 10px;
    line-height: 1.1em;
    font-weight: 600;
    padding-top: 2px;
}
.WonBoardToolbar label{
    font-size: 11px;
    color: #e2e2e2;
    margin: 0 10px;
    font-weight: 600;
    text-transform: uppercase;
}
.WonBoardWrapper{
    width: 100%;
    font-family: "LCD-Font", "IranSANS";
    font-size: 16px;
    border-radius: 10px;
    margin-top: 1em;
}
.WonBoardFooter{
    border-top: 1px solid #717171;
}
.WonBoardHeader th{
    border-bottom: 1px solid #717171;
    padding: 0.3em 0 0.3em 0;
    font-size: 13px;
}
.WonBoardWrapper table td{
    padding: 0.3em 0 0.3em 0;
}
.OverlayWindow{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
}
.OverlayLock{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
}
.MobileOverlayLock{
    background-color: rgba(0,0,0,0.7);
}
.MobileOverlayWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.MobileOverlayWrapper h5{
    font-size: 24px;
    text-align: center;
    line-height: 1.7em;
}
.MobileOverlayTimer{
    text-align: center;
    font-size: 110px !important;
}
.MobileTools{
    display: none;
}
.MobileIcons{
    display: none;
}
.MobileIcon{
    background: none;
    border: none;
}
.WheelSvg{
    height: 100%;
}
.MobileGreenDisplayContainer,
.MobileFloatingMenu{
    display: none;
}
.AccountButtons{
    display: flex;
    justify-content: center;
    align-items: center;
}
.DeviceNotSupport{
    display: none;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.DeviceNotSupport .Wrapper{
    width: 60%;
    text-align: center;
}
.DeviceNotSupport .Wrapper h1{
    color: #fff900;
}
.MobileNumberCounterWrapper{
    margin-top: 10px;
}
@media (min-width: 1660px) {
    .NeighboursBar{
        right: -100px;
    }
}
.DiamondCell{

}

@media (max-width: 720px) {
    .VideoMonitor,
    .Monitor,
    .SubToolsContainer,
    .LeftGreenDisplayContainer,
    .WonBoard,
    .AccountButtons{
        display: none;
    }
    .MobileGreenDisplayContainer{
        display: block;
    }
    .WheelContainer{
        width: 100%;
        position: relative;
    }
    .MobileNumberCounterWrapper{
        margin-top: 40px !important;
    }
    .MobileNumberCounterWrapper :global(.counter-cell){
        margin: 0 0.5em 0em 0.5em;
    }
    .WheelSvg{
        width: 100%;
    }
    .Container{
        overflow: initial;
        padding: 0 20px 1em 20px;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
    }
    .ToolsChipsContainer{
        width: 100%;
    }
    .RightDisplayContainer{
        display: block;
    }
    .TableContainer{
        /*display: none;*/
        display: block;
    }
    .LeftDisplay{
        width: 100%;
    }
    .RightDisplay{
        width: 100%;
        padding: 0;
    }
    .Table{
        width: 100%;
        height: auto;
    }
    .Table table{
        height: calc(100vh - 230px);
    }

    .TextSmall{
        font-size: 11px !important;
    }
    .Table table td{
        font-size: 13px;
    }
    .Diamond {
        height: 40px;
    }
    .CellChipButton{
        width: 24px;
        height: 24px;
    }
    .CellChipButton img{
        width: 24px;
        height: 24px;
    }
    .CellNumber{
        padding: 0.5em 0.5em;
    }
    .LeftGreenDisplayLastNumber{
        display: none;
    }
    .Chips{
        position: fixed;
        top: 0;
        right: -75px;
        background: #1b1b1b;
        z-index: 999999;
        height: 100%;
        display: flex;
        align-items: center;
        transition: right linear 0.3s;
    }
    .ChipButton{
        width: 42px;
        height: 42px;
        margin: 1em;
    }
    .ChipButton img{
        width: 32px;
        height: 32px;
    }
    .LogoRightOfMonitor{
        height: auto;
        display: none;
    }
    .LanguagesRightOfMonitor{
        display: none;
    }
    .LogoRightOfMonitor img{
        width: 110px;
    }

    .WheelWrapper{
        position: fixed;
        width: 100%;
        top: 70px;
        left: -100%;
        background: rgba(0,0,0,0.7);
        z-index: 999999;
        height: 100%;
        display: flex;
        align-items: center;
        transition: left linear 0.3s;
    }
    .OpenWheelWrapper{
        left: 0;
        transition: left linear 0.3s;
    }
    .WheelButton{
        border-radius: 100%;
        background: #606060;
        color: #fff;
        width: 50px;
        height: 50px;
        font-size: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .TopCellWrapper{
        top: -56px;
        left: 0;
        padding: 0 1.5em;
        position: fixed;
        width: 100%;
        background: #000;
        z-index: 99999988 !important;
        height: 55px;
        margin: 0;
        transition: top linear 0.3s;
    }
    .OpenTopCellWrapper{
        top: 0;
        transition: top linear 0.3s;
    }
    .TopCellWrapper table{
        height: 30px;
    }
    .TopCellWrapper table td{
        line-height: 30px;
        font-size: 13px;
        min-width: 32px;
        max-width: 32px;
    }
    .MobileTools{
        display: flex;
        width: 100%;
        height: 60px;
        margin-top: 1em;
        position: fixed;
        bottom: 0;
        left: 0;
        background: #222;
        z-index: 9999999;
    }
    .MobileIcons{
        display: table-cell;
    }
    .MobileIcon{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
    }
    .OpenMobileChips{
        right: 0;
        transition: right linear 0.3s;
    }
    .Tools{
        position: fixed;
        top: 0;
        right: -255px;
        max-width: 250px;
        height: 100%;
        background: #1b1b1b;
        z-index: 999999;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: right linear 0.3s;
    }
    .OpenMobileTools{
        right: 0;
        transition: right linear 0.3s;
    }
    .ToolsWrapper{
        padding: 0;
        width: 100%;
    }
    .MobileButtonAppBar{
        width: 100%;
        height: 60px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        color: #fff;
        font-size: 21px
    }
    .MobileButtonAppBarActive{
        background: #1b1b1b;
    }
    .MobileButtonAppBarDisabled{
        color: #4e4e4e;
    }
    .MobileButtonAppBar:focus{
        outline: 0;
        border: 0;
    }
    .MobileFloatingMenu{
        width: 250px;
        height: calc(100% - 60px);
        position: fixed;
        bottom: 60px;
        right: -255px;
        z-index: 9999998;
        background: #1b1b1b;
        display: flex;
        align-items: flex-start;
        transition: right linear 0.3s;
    }
    .OpenMobileFloatingMenu{
        right: 0px;
        transition: right linear 0.3s;
    }
    .MobileVolumeButton{
        position: absolute;
        background: #333;
        border: none;
        width: 32px;
        height: 32px;
        left: 10px;
        bottom: 10px;
        border-radius: 100%;
    }
    .MobileFloatingMenuWrapper{
        height: fit-content;
        width: 100%;
    }
    .MobileFloatingMenuWrapper ul{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .MobileFloatingMenuWrapper ul li{
        text-align: center;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
    }
    .MobileFloatingMenuWrapper ul li a{
        color: white;
        padding: 0.7em 0;
        display: block;
        font-weight: 600;
        font-size: 13px;
    }
    .MobileFloatingMenuWrapper ul li a:focus{
        background: #333;
    }
    .MobileButtonAppBar span{
        font-size: 11px;
        font-weight: bold;
        line-height: 1;
    }
    .MobileFloatingMenu .MobileButtonAppBar{
        width: 46px;
        height: 46px;
        background: #333333;
        border-radius: 100%;
        margin: 0.5em auto;
    }
    .OverlayLock{
        position: fixed;
    }
    .NumberCounterWrapper{
        margin-top: 10px;
    }
    .MobileFloatingMenuLogo{
        text-align: center;
        display: block;
        width: 100%;
        margin: 1em 0 1em 0;
    }
    .ToolsButton{
        background: none;
        border: none;
        height: auto;
        margin: 0;
        padding: 0;
        border-radius: 0;
    }
    .ToolsButton:focus{
        background: #333;
    }
    .ToolsButtonWrapper{
        background: none;
        height: auto;
        font-style: initial;
        color: white;
        padding: 0.9em 0;
        display: block;
        font-weight: 600;
        font-size: 14px;
        border-radius: 0;
    }
    .StatsWrapper{
        position: fixed;
        bottom: 60px;
        right: -100%;
        width: 100%;
        background: #000000;
        z-index: 999999;
        padding: 2em 1em;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: right linear 0.3s;
    }
    .Top5BoardWrapper{

    }
    .WonBoardWrapper{
        position: fixed;
        top: 73px;
        right: -100%;
        width: 100%;
        background: #000000;
        z-index: 999999;
        padding: 1em 1em 2em 1em;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: right linear 0.3s;
        margin-top: 0;
        font-size: 16px;
        border-radius: 0;
        min-height: 30%;
    }
    .VideoStreamWrapper{
        position: fixed;
        top: 73px;
        right: -200%;
        width: 100%;
        height: calc(100% - 130px);
        background: #000000;
        z-index: 9999999999;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: right linear 0.3s;
        margin-top: 0;
        font-size: 16px;
        border-radius: 0;
        min-height: 30%;
    }
    .VideoPlayer{

        height: 100% !important;
        overflow: visible !important;
        transform: scale(1.1);

    }
    .VideoPlayer video{
        width: 800px !important;
    }
    .WonBoardHeader th{
        font-size: 12px;
    }
    .OpenStatsWrapper,
    .OpenWonBoardWrapper,
    .OpenTop5BoardWrapper,
    .OpenVideoStreamWrapper{
        right: 0px;
        transition: right linear 0.3s;
    }
    .StatsTable{
        font-size: 11px;
    }
    .StatsTable th {
        font-size: 10px;
    }
    .TopCellTable{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .TopCellTable div{
        width: 26px;
        height: 26px;
        border-radius: 100%;
        border: 2px solid #cfcfcf;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px;
    }
    .TopCellTable svg{
        margin: 2px 5px;
    }
    .TopCellTable .LastNumber{
        border-radius: 0px;
        margin-right: 10px;
        border: 1px solid #cfcfcf;
    }
    .WheelWrapperContainer{
        width: 100%;
        height: 100%;
        position: relative;
    }
    .NeighboursBar{
        position: absolute;
        right: 0;
        bottom: 20px;
        z-index: 9999999;
        width: 100%;
    }
    .NeighboursBarWrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .NeighbourButton{
        border-radius: 100%;
        border-width: 2px;
        margin: 0 5px;
        font-size: 14px;
        font-weight: 500;
        max-width: 45px;
        max-height: 45px;
        min-width: 45px;
        min-height: 45px;
    }
    .NeighboursBar label{
        display: none;
        text-align: center;
        margin-top: 5px;
        font-size: 11px;
        font-weight: 400;
    }
    .AccountPageContainer{
        position: fixed;
        top: 0px;
        bottom: 60px;
        left: -100%;
        height: 100%;
        width: 100%;
        background: #000000;
        z-index: 99999999;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: left linear 0.1s;
        margin-top: 0;
        font-size: 16px;
        border-radius: 0;
        min-height: 30%;
    }
    .OpenAccountPageContainer{
        left: 0px;
        transition: left linear 0.1s;
    }
    .AccountPageContainer iframe{
        width: 100%;
        height: calc(100% - 60px);
        display: none;
    }
}
@media (max-width: 720px) and (max-height: 700px){
    .MobileFloatingMenu :global(.mt-4){
        margin-top: 1em !important;
    }
    .MobileFloatingMenu :global(.mb-5){
        margin-bottom: 1.1em !important;
    }

    .Table table td{
        font-size: 12px;
    }
}
.Timer{
    font-family: "LCD";
    font-size: 25px;
    color: #3dc733;
    transition: all ease-in-out;
}
@media (min-width: 721px) and (max-width: 1023px) and (max-height: 665px){
    .Container{
        display: none;
    }
    .DeviceNotSupport{
        display: flex;

    }
}
@media (min-width: 721px) and (max-width: 1023px) and (min-height: 666px){
    .VideoMonitor,
    .Monitor,
    .SubToolsContainer,
    .LeftGreenDisplayContainer,
    .WonBoard,
    .AccountButtons{
        display: none;
    }
    .MobileGreenDisplayContainer{
        display: block;
    }
    .WheelContainer{
        width: 100%;
        position: relative;
    }
    .WheelSvg{
        width: 100%;
    }
    .Container{
        overflow: initial;
        padding: 0 20px 1em 20px;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
    }
    .ToolsChipsContainer{
        width: 100%;
    }
    .RightDisplayContainer{
        display: block;
    }
    .TableContainer{
        /*display: none;*/
        display: block;
    }
    .LeftDisplay{
        width: 100%;
    }
    .RightDisplay{
        width: 100%;
        padding: 0;
    }
    .Table{
        width: 100%;
        height: auto;
    }
    .OverlayWindow{
        top: 100px;
    }
    .Table table{
        height: calc(100vh - 230px);
    }
    .TextSmall{
        font-size: 11px !important;
    }
    .Table table td{
        font-size: 13px;
    }
    .Diamond {
        height: 40px;
    }
    .CellChipButton{
        width: 24px;
        height: 24px;
    }
    .CellChipButton img{
        width: 24px;
        height: 24px;
    }
    .CellNumber{
        padding: 0.5em 0.5em;
    }
    .LeftGreenDisplayLastNumber{
        display: none;
    }
    .Chips{
        position: fixed;
        top: 0;
        right: -75px;
        background: #1b1b1b;
        z-index: 999999;
        height: 100%;
        display: flex;
        align-items: center;
        transition: right linear 0.3s;
    }
    .ChipButton{
        width: 42px;
        height: 42px;
        margin: 1em;
    }
    .ChipButton img{
        width: 32px;
        height: 32px;
    }
    .LogoRightOfMonitor{
        height: auto;
        display: none;
    }
    .LanguagesRightOfMonitor{
        display: none;
    }
    .LogoRightOfMonitor img{
        width: 110px;
    }

    .WheelWrapper{
        position: fixed;
        width: 100%;
        top: 100px;
        left: -100%;
        background: rgba(0,0,0,0.7);
        z-index: 999999;
        height: 100%;
        display: flex;
        align-items: center;
        transition: left linear 0.3s;
    }
    .OpenWheelWrapper{
        left: 0;
        transition: left linear 0.3s;
    }
    .WheelButton{
        border-radius: 100%;
        background: #606060;
        color: #fff;
        width: 50px;
        height: 50px;
        font-size: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .TopCellWrapper{
        top: -56px;
        left: 0;
        padding: 0 1.5em;
        position: fixed;
        width: 100%;
        background: #000;
        z-index: 99999988 !important;
        height: 55px;
        margin: 0;
        transition: top linear 0.3s;
    }
    .OpenTopCellWrapper{
        top: 0;
        transition: top linear 0.3s;
    }
    .TopCellWrapper table{
        height: 30px;
    }
    .TopCellWrapper table td{
        line-height: 30px;
        font-size: 13px;
        min-width: 32px;
        max-width: 32px;
    }
    .MobileTools{
        display: flex;
        width: 100%;
        height: 60px;
        margin-top: 1em;
        position: fixed;
        bottom: 0;
        left: 0;
        background: #333;
        z-index: 9999999;
    }
    .MobileIcons{
        display: table-cell;
    }
    .MobileIcon{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
    }
    .OpenMobileChips{
        right: 0;
        transition: right linear 0.3s;
    }
    .Tools{
        position: fixed;
        top: 0;
        right: -255px;
        max-width: 250px;
        height: 100%;
        background: #1b1b1b;
        z-index: 999999;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: right linear 0.3s;
    }
    .OpenMobileTools{
        right: 0;
        transition: right linear 0.3s;
    }
    .ToolsWrapper{
        padding: 0;
        width: 100%;
    }
    .MobileButtonAppBar{
        width: 100%;
        height: 60px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        color: #fff;
        font-size: 21px
    }
    .MobileButtonAppBarActive{
        background: #1b1b1b;
    }
    .MobileButtonAppBarDisabled{
        color: #4e4e4e;
    }
    .MobileButtonAppBar:focus{
        outline: 0;
        border: 0;
    }
    .MobileFloatingMenu{
        width: 250px;
        height: calc(100% - 60px);
        position: fixed;
        bottom: 60px;
        right: -255px;
        z-index: 9999998;
        background: #1b1b1b;
        display: flex;
        align-items: flex-start;
        transition: right linear 0.3s;
    }
    .OpenMobileFloatingMenu{
        right: 0px;
        transition: right linear 0.3s;
    }
    .MobileVolumeButton{
        position: absolute;
        background: #333;
        border: none;
        width: 40px;
        height: 40px;
        left: 10px;
        bottom: 10px;
        border-radius: 100%;
    }
    .MobileFloatingMenuWrapper{
        height: fit-content;
        width: 100%;
    }
    .MobileFloatingMenuWrapper ul{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .MobileFloatingMenuWrapper ul li{
        text-align: center;
        text-transform: uppercase;
    }

    .MobileFloatingMenuWrapper ul li a{
        color: white;
        padding: 0.6em 0;
        display: block;
        font-weight: 600;
        font-size: 14px;
    }
    .MobileFloatingMenuWrapper ul li a:focus{
        background: #333;
    }
    .MobileButtonAppBar span{
        font-size: 11px;
        font-weight: bold;
        line-height: 1;
    }
    .MobileFloatingMenu .MobileButtonAppBar{
        width: 46px;
        height: 46px;
        background: #333333;
        border-radius: 100%;
        margin: 0.5em auto;
    }
    .OverlayLock{
        position: fixed;
    }
    .NumberCounterWrapper{
        margin-top: 10px;
    }
    .MobileFloatingMenuLogo{
        text-align: center;
        display: block;
        width: 100%;
        margin: 1em 0 1em 0;
    }
    .ToolsButton{
        background: none;
        border: none;
        height: auto;
        margin: 0;
        padding: 0;
        border-radius: 0;
    }
    .ToolsButton:focus{
        background: #333;
    }
    .ToolsButtonWrapper{
        background: none;
        height: auto;
        font-style: initial;
        color: white;
        padding: 0.9em 0;
        display: block;
        font-weight: 600;
        font-size: 14px;
        border-radius: 0;
    }
    .StatsWrapper{
        position: fixed;
        bottom: 60px;
        right: -100%;
        width: 100%;
        background: #000000;
        z-index: 999999;
        padding: 2em 1em;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: right linear 0.3s;
    }
    .Top5BoardWrapper{

    }
    .WonBoardWrapper{
        position: fixed;
        top: 100px;
        right: -100%;
        width: 100%;
        background: #000000;
        z-index: 999999;
        padding: 1em 1em 2em 1em;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: right linear 0.3s;
        margin-top: 0;
        font-size: 16px;
        border-radius: 0;
        min-height: 30%;
    }
    .WonBoardHeader th{
        font-size: 12px;
    }
    .OpenStatsWrapper,
    .OpenWonBoardWrapper,
    .OpenTop5BoardWrapper{
        right: 0px;
        transition: right linear 0.3s;
    }
    .StatsTable{
        font-size: 11px;
    }
    .StatsTable th {
        font-size: 10px;
    }
    .TopCellTable{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .TopCellTable div{
        width: 26px;
        height: 26px;
        border-radius: 100%;
        border: 2px solid #cfcfcf;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px;
    }
    .TopCellTable svg{
        margin: 2px 5px;
    }
    .TopCellTable .LastNumber{
        border-radius: 0px;
        margin-right: 10px;
        border: 1px solid #cfcfcf;
    }
}
@media (min-width: 1200px) and (max-width: 1408px){
    .NeighboursBar{
        right: -64px;
    }
}

@media (min-width: 1024px) and (max-width: 1199px){
    .LeftDisplay{
        width: 45%;
    }
    .RightDisplay{
        width: 55%;
    }
    .WonBoardLastButton{
        margin-right: 0;
    }
    .CellNumber{
        padding: 0.5em 0.3em;
    }
    .FullTable .CellNumber{
        padding: 0.4em 0.3em;
    }
    .Table table td{
        font-size: 14px;
    }
    .TextSmall{
        font-size: 12px !important;
        padding: 1em 0.3em !important;
    }
    .CircleButton{
        width: 73px;
        height: 73px;
        margin: 0 10px;
    }
    .CircleButtonWrapper{
        height: 60px;
        width: 60px;
        min-width: 60px;
        font-size: 11px;
    }
    .Monitor{
        font-size: 18px;
    }
    .Tools{
        width: 100%;
    }
    .WheelContainer{
        width: 60%;
    }
    .WonBoard{
        width: 40%;
    }
    .WonBoardWrapper{
        font-size: 14px;
        margin-top: 90px;
    }
    .ChipButton{
        width: 45px;
        height: 45px;
        margin: 0.6em 1.3em;
    }
    .ChipButton img{
        width: 35px;
        height: 35px;
    }
    .CircleButton{
        width: 63px;
        height: 63px;
        margin: 5px 10px;
    }
    .CircleButtonWrapper{
        height: 50px;
        width: 50px;
        min-width: 50px;
        font-size: 11px;
    }
    .ToolsButton{
        height: 32px;
        margin-bottom: 7px;
    }
    .CircleGrayButton{
        height: 32px;
        width: 32px;
        max-height: 32px;
        max-width: 32px;
        min-height: 32px;
        min-width: 32px;
    }
    .Diamond{
        height: 45px;
    }
    .ToolsButtonWrapper{
        font-size: 10px;
        height: 25px;
    }
    .SubToolsContainer{
        width: 60%;
        margin: 0.5em auto 0.5em auto;
    }
    .ToolsWrapper{
        padding-top: 40px;
    }
    .WonContainer{
        position: relative;
    }
    .NeighboursBar{
        right: 0px;
    }
    .WheelWrapper{
        margin-top: 70px;
    }
    .StatsBarItems{
        width: 20px;
    }
    .StatsBarItems li{
        font-size: 9px;
        height: 27px;
    }
    .StatsTable th{
        font-size: 7px;
    }
    .StatsTable td{
        font-size: 9px;
        padding: 0.8em 0.8em;
    }
    .WonBoardHeader th{
        font-size: 9px;
    }
    .WonBoardWrapper td{
        font-size: 10px;
    }
    .WonBoardToolbar{
        position: absolute;
        top: 0;
        left: 0px;
        width: 100%;
    }
}
@media (min-width: 1200px) and (max-width: 1299px) {

    .WonBoardLastButton{
        margin-right: 0;
    }
    .CellNumber{
        padding: 0.5em 0.3em;
    }
    .FullTable .CellNumber{
        padding: 0.4em 0.3em;
    }
    .Table table td{
        font-size: 14px;
    }
    .TextSmall{
        font-size: 12px !important;
        padding: 1em 0.3em !important;
    }
    .CircleButton{
        width: 73px;
        height: 73px;
        margin: 0 10px;
    }
    .CircleButtonWrapper{
        height: 60px;
        width: 60px;
        min-width: 60px;
        font-size: 11px;
    }
    .Monitor{
        font-size: 18px;
    }
    .Tools{
        width: 100%;
    }
    .WheelContainer{
        width: 40%;
    }
    .WonBoard{
        width: 60%;
    }
    .WonBoardWrapper{
        font-size: 14px;
    }
    .ChipButton{
        width: 45px;
        height: 45px;
        margin: 0.6em 1.3em;
    }
    .ChipButton img{
        width: 35px;
        height: 35px;
    }
    .CircleButton{
        width: 63px;
        height: 63px;
        margin: 5px 10px;
    }
    .CircleButtonWrapper{
        height: 50px;
        width: 50px;
        min-width: 50px;
        font-size: 11px;
    }
    .ToolsButton{
        height: 32px;
        margin-bottom: 7px;
    }
    .CircleGrayButton{
        height: 32px;
        width: 32px;
        max-height: 32px;
        max-width: 32px;
        min-height: 32px;
        min-width: 32px;
    }
    .Diamond{
        height: 45px;
    }
    .ToolsButtonWrapper{
        font-size: 10px;
        height: 25px;
    }
    .SubToolsContainer{
        width: 60%;
        margin: 0.5em auto 0.5em auto;
    }
    .ToolsWrapper{
        padding-top: 40px;
    }

}
@media (min-width: 1300px) and (max-width: 1399px) {

    .WonBoardLastButton{
        margin-right: 0;
    }
    .CellNumber{
        padding: 0.4em 0.3em;
    }
    .FullTable .CellNumber{
        padding: 0.3em 0.3em;
    }
    .SubToolsContainer{
        width: 100%;
        margin: 1em;
    }
    .LeftDisplay{
        width: 50%;
    }
    .RightDisplay{
        width: 50%;
    }
    .Table table td{
        font-size: 15px;
    }
    .WheelContainer{
        width: 38%;
    }
    .WonBoard{
        width: 62%;
    }
    .ChipButton{
        width: 50px;
        height: 50px;
        margin: 0.5em 2em;
    }
    .ChipButton img{
        width: 40px;
        height: 40px;
    }
    .TextSmall{
        font-size: 14px !important;
        padding: 1em 0.3em !important;
    }
    .CircleButton{
        width: 73px;
        height: 73px;
        margin: 5px 10px;
    }
    .CircleButtonWrapper{
        height: 60px;
        width: 60px;
        min-width: 60px;
        font-size: 11px;
    }
    .Monitor{
        font-size: 19px;
    }
    .Tools{
        width: 100%;
    }
    .ToolsButton{
        height: 34px;
        margin-bottom: 5px;
    }
    .CircleGrayButton{
        height: 34px;
        width: 34px;
        max-height: 34px;
        max-width: 34px;
        min-height: 34px;
        min-width: 34px;
    }
    .ToolsButtonWrapper{
        height: 25px;
        font-size: 10px;
    }
    .SubToolsContainer{
        width: 65%;
        margin: 0 auto 0em auto;
    }
    .ToolsWrapper{
        padding-top: 40px;
    }
}
@media (min-width: 1400px) and (max-width: 1499px) {

    .WonBoardLastButton{
        margin-right: 0;
    }
    .LeftDisplay{
        width: 52%;

    }
    .LeftGreenDisplay{
        width: 48%;
    }
    .WheelContainer{
        width: 45%;
    }
    .WonBoard{
        width: 55%;
    }
    .CellNumber{
        padding: 0.61em 0.3em;
    }
    .FullTable .CellNumber{
        padding: 0.51em 0.3em;
    }
    .Table table td{
        font-size: 17px;
    }
    .TextSmall{
        font-size: 14px !important;
        padding: 1.1em 0.3em !important;
    }
    .ChipButton{
        width: 55px;
        height: 55px;
        margin: 0.7em 2em;
    }
    .ChipButton img{
        width: 45px;
        height: 45px;
    }
    .CircleButton{
        width: 80px;
        height: 80px;
        margin: 0 10px;
    }
    .CircleButtonWrapper{
        height: 70px;
        min-width: 70px;
        width: 70px;
    }
    .Monitor{
        font-size: 21px;
    }
    .SubToolsContainer{
        width: 65%;
        margin: 1em auto 1em auto;
    }
    .ToolsWrapper{
        padding-top: 50px;
    }
    .ToolsButtonWrapper{
        height: 31px;
        font-size: 11px;
    }
    .ToolsButton{
        margin-bottom: 6px;
        height: 40px;
    }
    .CircleGrayButton{
        height: 40px;
        width: 40px;
        max-height: 40px;
        max-width: 40px;
        min-height: 40px;
        min-width: 40px;
    }
}
@media (min-width: 1500px) and (max-width: 1600px) {
    .NeighboursBar label{
        display: inline-block;
    }
    .WonBoardLastButton{
        margin-right: 0;
    }
    .LeftDisplay{
        width: 52%;

    }
    .LeftGreenDisplay{
        width: 48%;
    }
    .WheelContainer{
        width: 35%;
    }
    .WonBoard{
        width: 65%;
    }
    .CellNumber{
        padding: 0.61em 0.3em;
    }
    .FullTable .CellNumber{
        padding: 0.41em 0.3em;
    }
    .Table table td{
        font-size: 17px;
    }
    .TextSmall{
        font-size: 16px !important;
        padding: 1em 0.3em !important;
    }
    .ChipButton{
        width: 55px;
        height: 55px;
        margin: 0.7em 2em;
    }
    .ChipButton img{
        width: 45px;
        height: 45px;
    }
    .CircleButton{
        width: 80px;
        height: 80px;
        margin: 0 10px;
    }
    .CircleButtonWrapper{
        height: 70px;
        min-width: 70px;
        width: 70px;
    }
    .Monitor{
        font-size: 21px;
    }
    .SubToolsContainer{
        width: 65%;
        margin: 1em auto 1em auto;
    }
    .ToolsWrapper{
        padding-top: 50px;
    }
    .ToolsButtonWrapper{
        height: 31px;
        font-size: 11px;
    }
    .ToolsButton{
        margin-bottom: 6px;
        height: 40px;
    }
    .CircleGrayButton{
        height: 40px;
        width: 40px;
        max-height: 40px;
        max-width: 40px;
        min-height: 40px;
        min-width: 40px;
    }
}

@media (min-width: 1601px) and (max-width: 1700px) {
    .NeighboursBar label{
        display: inline-block;
    }
    .WonBoardLastButton{
        margin-right: 0;
    }
    .WheelContainer{
        width: 45%;
    }
    .WonBoard{
        width: 55%;
    }
    .CellNumber{
        padding: 0.77em 0.3em;
    }
    .FullTable .CellNumber{
        padding: 0.51em 0.3em;
    }
    .Table table td{
        font-size: 19px;
    }
    .ChipButton{
        width: 67px;
        height: 67px;

    }
    .ChipButton img{
        width: 57px;
        height: 57px;
    }
    .TextSmall{
        font-size: 16px !important;
        padding: 1em 0.3em !important;
    }
    .CircleButton{
        width: 90px;
        height: 90px;
    }
    .CircleButtonWrapper{
        height: 80px;
        min-width: 80px;
        font-size: 14px;
        width: 80px;
    }
    .Monitor{
        font-size: 21px;
    }
    .SubToolsContainer{
        width: 65%;
        margin: 1em auto 1em auto;
    }
    .ToolsButtonWrapper{
        height: 41px;
        font-size: 14px;
    }
    .ToolsButton{
        margin-bottom: 10px;
        height: 50px;
    }
    .CircleGrayButton{
        height: 50px;
        width: 50px;
        max-height: 50px;
        max-width: 50px;
        min-height: 50px;
        min-width: 50px;
    }
}

@media (min-width: 1900px) and (max-width: 1999px) {
    .NeighboursBar label{
        display: inline-block;
    }
    .WheelContainer{
        width: 40%;
    }
    .CellNumber{
        padding: 0.55em 0.3em;
    }
    .FullTable .CellNumber{
        padding: 0.4em 0.3em;
    }
    .WonBoard{
        width: 60%;
    }
}
@media (min-width: 1900px){
    .NeighboursBar label{
        display: inline-block;
    }
}
.HighLightWonCells{
    /*background: rgb(137 255 0 / 20%);*/
    color: #fff;
    text-shadow:
            0 0 3px #0fa,
            0 0 5px #0fa,
            0 0 8px #0fa,
            0 0 42px #0fa,
            0 0 82px #0fa,
            0 0 92px #0fa,
            0 0 102px #0fa,
            0 0 151px #0fa;
}
.HighLightWonBorder{
    z-index: 999999;
}
.HighLightWonBorder .VerticalLine, .HighLightWonBorder .HorizontalLine{
    box-shadow: 0 0 7px #30ff41, 0 0 5px #30ff41;
    background: #30ff41;
}
.HighLightHoverBorder{
    /*z-index: 999999;*/
}
.HighLightHoverBorder .VerticalLine,.HighLightWonBorder .VerticalLine{
    width: 2px;
}
.HighLightHoverBorder .HorizontalLine.HighLightWonBorder .VerticalLine{
    height: 1px;
}
.HighLightHoverBorder .VerticalLine{
    box-shadow: 0 0 7px #e4e4e4, 0 0 5px #e4e4e4;
    background: #e4e4e4;
    transition: all ease-in-out 0.2s;
}
.HighLightHoverBorder .HorizontalLine{
    box-shadow: 0 0 7px #e4e4e4, 0 0 5px #e4e4e4;
    background: #e4e4e4;
    transition: all ease-in-out 0.2s;
}
.ContextMenuOverlay{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
    background: rgba(0,0,0,0.1);
}
.ContextMenu{
    min-width: 250px;
    max-width: 250px;
    background: #333;
    position: absolute;
    z-index: 999999999;
    left: 50%;
    top: 50%;
    box-shadow: 0 0 3px #333, 0 0 3px #333;
    border-radius: 4px;
}
.LeftContextMenu{
    right: 50%;
    left: auto;
}
.RightContextMenu{
    right: 10%;
    left: auto;
}
.ContextMenu ul{
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
}
.ContextMenu ul li{
    padding: 0.5em 0.8em;
    border-bottom: 1px solid #444;
    transition: all ease-in-out 0.2s;
    font-size: 0.9em;
    color: #fff;

}
.ContextMenu ul li:hover{
    background: #444;
    transition: all ease-in-out 0.2s;
}
.ContextMenu ul li:last-child{
    border-bottom: 0;
}

.LoadingContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
@media (max-width: 720px) {
    .LeftGreenDisplayContainer{
        height: auto;
    }
}
@media (min-width: 1200px) {
    .FullTable table{
        width: 70%;
    }

}
@media (max-height: 730px) {
    .Container{
        overflow-y: scroll !important;
        max-height: initial !important;
    }
    .WonBoardToolbar{
        position: absolute;
        top: 0px;
        width: 100%;
    }
}
@media (max-width: 400px) {
    .MobileButtonAppBar{
        font-size: 15px !important;
    }
    .Table table td.Vertical{
        font-size: 10px !important;
        white-space: break-spaces !important;
        padding: 2.7em 0;
    }
    .DiamondCell{
        padding: 1.5em 0 !important;
    }
}