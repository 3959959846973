.BackBar{

}
.BackBar a {
    color: #fff;
}
.BackBar a:hover{
    color: #fff900;
}
.SubmitBox{
    padding: 3em 0;
    background: #2d3a48;
    margin-top: 1em;
    border-radius: 7px;
    box-shadow: 0px 0px 13px #343434;
}
.SubmitBox :global(.col-md-9){
    width: 100% !important;
}
.SubmitBox :global(.col-md-4){
    width: 20% !important;
}