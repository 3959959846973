.TitleBar{
    width: calc(100% - 4em);
    display: table;
    margin: 3em 2em 3em 2em;
}
.FullWidthTitleBar{
    width: 100%;
    display: table;
    margin: 3em 0em 0em 0em;

}
.LeftSection{
    min-width: 40%;
    display: table-cell;
    vertical-align: center;
}
.CenterSection{
    width: fit-content;
    margin: 0 auto;
    display: table-cell;
    vertical-align: center;
    text-align: center;
}
.RightSection{
    display: table-cell;
    vertical-align: center;
}
.FullTitleText{
    width: 100% !important;
}
.TitleText{
    background-color: #333f4f;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff900;
    padding: 0.7em 1.2em;
    text-transform: uppercase;
    font-weight: 500;
    width: fit-content;
    text-align: center;
}
.SearchBox{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.CenterSection .TitleText{
    margin: 0 auto;
}
:global(.float-calendar .react-calendar__navigation){
    background: #2d3846;
}
:global(.float-calendar abbr[title]){
    text-decoration: none !important;
}
:global(.float-calendar abbr[data-bs-original-title]){
    text-decoration: none !important;
}
:global(.float-calendar .react-calendar__tile){
    border: 1px solid #2d3846 !important;
}
:global(.float-calendar .react-calendar){
    background: #333f4f;
    border: 1px solid #222b35;
    font-size: 14px;
}
:global(.float-calendar .react-calendar button){
    color: #fff;
}
:global(.float-calendar .react-calendar button:hover){
    background-color: #d93935;
    color: #fff;
}
:global(.float-calendar .react-calendar__month-view__days__day--weekend){
    color: #d93935 !important;
}
:global(.float-calendar .react-calendar__month-view__days__day--weekend:hover){
    color: #fff !important;
}
:global(.float-calendar .react-calendar__tile--now){
    color: #333 !important;
    background-color: #fff900 !important;
}
:global(.search-item){
    border-radius: 0;
    padding: 0.7rem 0.75rem;
    border: 1px solid #fff;
    color: #fff;
    border-left: 0px;
    min-width: 100px;
    width: 140px;
    text-align: center;
    cursor: pointer;
    position: relative;

}
:global(.search-item a){
    color: #fff;
    display: block;
}
:global(.search-item:hover){
    border-color: #fff;
}
:global(.btn.search-item){
    width: fit-content;
}
:global(.search-item:first-child){
    border-left: 1px solid;
}
.BackButtonContainer{
    position: relative;
    display: table-cell;
    width: fit-content;
}
.BackNavButton{
    position: absolute;
    left: 1em;
    background: none;
    border: none;
    height: 100%;
    width: 80px;
    text-align: left;
    color: #fff;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.BackNavButton:hover{
    color: #fff900;
}
.BackNavButton svg{
    margin-right: 10px;
}