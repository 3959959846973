.Table{
    width: 90%;
    margin: 0 auto;
}
.FullTable{
    width: 100%;
    margin: 0 auto;
}
.TableCell{
    border: 1px solid #fff;
    padding: 0.7em 1em;
    text-align: center;
    font-weight: 400;
}
.BackArrow{
    position: absolute;
    padding: 0.4em 0;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    transition: all ease-in-out 0.3s;
    left: -40px;
    top: 4px;
}
.NextArrow{
    position: absolute;
    padding: 0.4em 0;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    transition: all ease-in-out 0.3s;
    right: -40px;
    top: 4px;
}
.RelativeRow{
    position: relative;
}
.Cell{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Cell svg:first-child{
    margin-left: 10px;
}
.Cell svg{
    font-size: 13px;
    width: 13px !important;
    height: 13px !important;
    margin: 0 2px;
    cursor: pointer;
}
.Cell svg:hover{
    color: #fff !important;
    transition: all ease-in-out 0.2s;
}
.CellActive{
    color: #fff !important;
}
.TableHeader{
    color: #fff900;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    padding: 0.7em 1em;
    font-size: 0.9em;
}
.PaginationWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5em 0;
}
.PaginationWrapper ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
}
.PaginationWrapper ul li{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.NavigatorButton{
    height: 38px;
    width: 55px;
    border-radius: 4px;
    border: 0;
    background: #29333e;
    margin: 0 0.3em;
    border: 1px solid #35404c;
}
.PaginationWrapper ul li:hover{
    background: #a60000;
    border: 1px solid #a60000;
    transition: all ease-in-out 0.5s;
}
.NavigatorActive{
    background: #a60000 !important;
    border: 1px solid #a60000 !important;
    transition: all ease-in-out 0.5s !important;
}
.NavigatorDisabled{
    background: #46494c !important;
    color: #b9b9b9 !important;
    border-color: #46494c !important;
    cursor: initial !important;
}
.Navigator{
    border: 1px solid #35404c;
    margin: 0 0.2em;
    border-radius: 4px;
    background: #29333e;
    min-width: 38px;
    height: 38px;
    padding: 0 0.4em;
}
.Navigator:hover{
    background: #a60000;
    transition: all ease-in-out 0.5s;
}
.FullWidth{
    width: 100%;
}
.RowLink{
    cursor: pointer;
}
.RowLink:hover > td{
    background-color: #00a10d;
}
.ActiveCell{
    background-color: #00a10d;
}
.HiddenDesktop{
    display: none;
}
@media (max-width: 720px) {
    .TableHeader,
    .TableCell{
        display: none;
    }
    .ShowMobileVersion{
        display: table-cell;
    }
    .HiddenDesktop{
        display: table-row;
    }
    .HiddenDesktop td{
        display: block;
    }
    .TableRow{
        display: table-row;
    }
    .TableMobileRow{
        display: block;
    }
    .TableMobileCell{
        display: block;
        margin-bottom: 1em;
    }
    .TableMobileCell label{
        color: #fff900;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        padding: 0.2em 1em;
        font-size: 0.9em;
        width: 100%;
    }
    .TableNumberBox{
        border-radius: 10px;
        border: 1px solid #222b35;
        background: #1b2128;
        padding: 0.7em 1em;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center
    }
    .PaginTableNumberBox{
        padding: 0;
    }
    .PaginTableNumberBox button{
        padding: 0.7em 1.5em;
    }
    .TableNumberBox span{
        flex: 1;
    }
    .TableNumberBox button{
        background: none;
        border: none;
        color: #fff;
    }
    .TableNumberBox button:disabled{
        color: #545454;
    }
    .TableNumberBox button:focus{
        outline: 0;
    }

    .TableHeader{
        border-radius: 10px;
        border: 1px solid #222b35;
        background: #0a0d10;
    }
    .TableCell{
        border-radius: 10px;
        border: 1px solid #222b35;
        background: #151a20;
    }
    :global(.form-search){
        margin: 0 !important;
        width: 100% !important;
    }
    :global(.form-search .input-group-text) {
        margin: 0;
        width: 100%;
        height: fit-content;
        padding: 0.175rem 0;
    }
    :global(.form-search .form-control) {
        margin: 0;
        min-width: 100% !important;
    }
    :global(.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3)),
    :global(.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu)){
        border-top-right-radius: .25rem !important;
        border-top-left-radius: .25rem !important;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

}

.ArrowButtonCell{
    padding: 0.4em 0;
    width: 80px;
}
.ArrowButtonCell div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.ArrowButtonCell button{
    background: none;
    border: 1px solid #fff;
    color: #fff;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    transition: all ease-in-out 0.3s;
}
.ArrowButtonCell button:hover{
    background-color: #00a10d;
}