.SearchLabel{
    margin-right: 1em;
}
.SearchLabels{
    margin: 0 1em;
}
.SearchBox{
    width: 100%;
}
.DropDown{
    max-width: 200px !important;
    min-width: 200px !important;
}
.TimeDropDown{
    max-width: 70px !important;
    min-width: 70px !important;
}
.AutoWidth{
    width: auto;
}