.FullWidth{
    max-width: 80% !important;
}
.TextAlert{
    color: #fff !important;
    text-align: center;
    margin-top: 1em;
    font-size: 1em;
}
.PaymentImage{
    max-width: 60%;
}
@media (max-width: 720px) {
    .PaymentImage{
        max-width: 100%;
    }
}