.FullWidth{
    max-width:  50% !important;
    width:  50%;
    margin-right: 200px;
}
.FullWidth .FormLabel{
    width: 250px;
    white-space: nowrap;
    text-align: right;
    padding-right: 10px;
}
.Title{
    font-size: 14px;
    background: #333f4f;
    margin: 0 2em 0 2em;
    color: #fff900;
    padding: 1em;
    text-align: center;
}