.LoginContainer{
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333f4f;
}
.LoginWrapper{
    max-width: 500px;
    min-width: 500px;
    width: 500px;



}
.LoginBody{
    background-color: #222b35;
    box-shadow: 0px 0px 10px 0px #222b35;
    padding: 2em 2em;
    margin-top: 1em;
}
.LoginBody :global(.form-control){
    background-color: transparent;
    border: 1px solid #b3b3b3;
    padding: 0.7rem 0.75rem;
}
.LoginBody h4{
    text-align: center;
    width: fit-content;
    margin: 0 auto 2em auto;
    padding: 1em;
    background: #1c242c;
    text-transform: uppercase;
    font-size: 1.3rem;
    border-radius: 4px;
}
.StartCenterValue{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.StartCenterValue label{
    margin: 0;
}
.EndCenterValue{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.LoadingContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.EyePassword{
    position: absolute;
    right: 24px;
    font-size: 19px;
    cursor: pointer;
}

.EyePassword svg{
    color: #787878;
}
.EyePassword:hover > svg{
    color: #333;
}