.PopupOverlay{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
    background: rgba(0,0,0,0.2);
}
.PopupContainer{
    background: #333;
    border-radius: 4px;
    box-shadow: 0 0 7px #101010, 0 0 10px #101010;
    position: fixed;
    top: 0;
    left: 0;
}
.Dark{
    background: #000;
}
.Dark .PopupHeader{
    border-color: #000 !important;
}
.Dark .PopupFooter{
    border-color: #000 !important;
}
.SmallPopup{
    min-width: 33%;
    max-width: 33%;
}
.LargePopup{
    min-width: 60%;
    max-width: 60%;
    height: 100%;
    position: fixed;
    left: -60%;

}
.PopupHeader{
    width: 100%;
    height: 60px;
    font-size: 1.3em;
    border-bottom: 1px solid #404040;
    display: flex;
    align-items: center;
}
.CloseButton{
    position: absolute;
    top: 30px;
    left: 10px;
    background: none;
    border: none;
    width: 32px;
    font-size: 1.5em;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    z-index: 99999;
}
.ArrowButton{
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    font-size: 32px;
    color: #fff;
}
.ArrowDisabled{
    color: #404040 !important;
    cursor: initial !important;
}
.ArrowButton:hover{
    color: #006f32;
    transition: all ease-in-out 0.3s;
}
.ArrowLeftButton{
    width: 40px;
    left: 10px;
    position: absolute;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ArrowRightButton{
    width: 40px;
    right: 25px;
    position: absolute;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.LoadingContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.PopupFooter{
    width: 100%;
    border-top: 1px solid #404040;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 2em;
    height: 60px;
}
.PopupBody{
    height: 100%;
    position: relative;
}
.PopupBodyWrapper{
    height: 100%;
}
.PopupDescription{
    font-size: 0.9em;
    color: #ababab;
}
.PopupHeader p{
    flex: 1;
    color: #fff;
    margin: 0;
    padding: 0 1em;
}
.CloseButton:hover{
    color: #a60000;
    transition: all ease-in-out 0.5s;
}
@media (max-width: 720px) {
    .ArrowRightButton{
        position: fixed;
        bottom: 5px;
        height: fit-content;
        left: 0;
        top: auto;
        width: 100%;
    }
    .CloseButton{
        top: 5px;
        left: 5px;
    }
    .ArrowButton{
        width: 32px !important;
        height: 32px !important;;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ArrowButton svg{
        width: 24px !important;
        height: 24px !important;;
    }
    .ArrowRightButton :global(.wrapper){
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .ArrowRightButton :global(.wrapper .round-number){
        font-size: 12px;
        font-weight: 600;
    }

}
@media (max-width: 720px) and (max-height: 700px){

}
@media (max-width: 1000px) {
    .LargePopup{
        min-width: 100%;
        max-width: 100%;
        height: 100%;
    }
}
@media (min-width: 1400px) and (max-width: 1600px) {
    .LargePopup{
        min-width: 45%;
        max-width: 45%;
        height: 100%;
    }
}
@media (min-width: 1600px) {
    .LargePopup{
        min-width: 35%;
        max-width: 35%;
        height: 100%;
    }
}
