.HelpText{
    text-align: left;
    font-size: 12px;
    color: #ccc;
    margin-top: 4px;
    margin-bottom: 0;
    display: flex;

}
.HelpText small{
    width: 100%;
}
.HelpText input{
    width: 1px;
    height: 1px;
}
.UploadedFiles{
    display: flex;
    flex-wrap: wrap;
    margin: 1em 0;
}
.AttachItem{
    width: 120px;
    height: 120px;
    padding: 3px;
    margin: 5px;
    background: rgba(255,255,255,0.4);
    border-radius: 3px;
    position: relative;
}
.AttachItem img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.AttachItemRemove{
    position: absolute;
    top: -6px;
    right: -6px;
    width: 18px;
    height: 18px;
    border: 0;
    background: #d50f0a;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}