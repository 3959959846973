
#root{
    height: 100%;
}
.page-title{
    width: 100%;
    font-size: 1.3rem;
    background: #29333e;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 1em;
    color: #fff900;
}
.text-right{
    text-align: right;
}

.form-control{
    background: #1d1d1d;
    border-color: #1d1d1d;
    color: #fff;

}
.form-control:disabled{
    color: #9f9f9f;
}
.form-control:focus {
    color: #fff;
    background-color: #1d1d1d;
    border-color: #2b2b2b;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(72 72 72 / 25%);
}
.btn-dark{
    padding: 0.675rem 2rem;
    background: transparent;
    color: #fff900;
    text-transform: uppercase;
    font-weight: 500;
    border: 1px solid #b3b3b3;
}
.btn-dark:hover{
    background: #29333e;
    color: #fff;
    border: 1px solid #b3b3b3;
}
.btn-account{
    padding: 0.675rem 2rem;
    background: #d50f0a;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
}
.btn-account:hover{
    background: #910f0b;
    color: #fff;
}
.btn-copy{
    padding: 0.675rem 2rem;
    color: #fff;
    background: transparent;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border: 1px solid #b3b3b3;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
.btn-copy:hover{
    color: #d50f0a;
}
.btn-copy svg{
    font-size: 18px;
    margin-right: 10px;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545 !important;
    color: #dc3545 !important;

}
.btn-account:focus{
    box-shadow: 0 0 0 0.25rem rgba(213, 15, 10, 0.25) !important;
}
.start-center-value{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.end-center-value{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
}
.text-center-value{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.start-align-items{
    align-items: flex-start;
}
.full-width{
    width: 100%
}
.mr-1{
    margin-right: 0.2rem;
}
.mr-2{
    margin-right: 0.5rem;
}
.ml-1{
    margin-left: 0.2rem;
}
.input-group-text{
    height: 100%;
    background-color: #1c2024;
    border: 1px solid #b3b3b3;
    color: #ffeb3b;
    font-size: 14px;
    font-weight: 400;
    border-radius: 0 4px 4px 0;
}
.yellow-text{
    color: #fff900 !important;
}
.orange-text{
    color: #f78409 !important;
}
.green-text{
    color: #8bc34a !important;
}
.red-text{
    color: #f44336 !important;
}
.gray-text{
    color: #606060 !important;;
}
.hover-red{
    cursor: pointer;
}
.hover-red:hover{
    color: #f44336 !important;
    transition: all ease-in-out 0.2s;
}
.hover-green{
    cursor: pointer;
}
.hover-green:hover{
    color: #8bc34a !important;
    transition: all ease-in-out 0.2s;
}
.btn-outline{
    border: 1px solid;
    cursor: pointer;
    border-radius: 0 !important;
    line-height: 1 !important;;
    margin-right: 5px;
}
.btn-outline-green{
    color: #8bc34a !important;
    border-color: #8bc34a !important;
}
.btn-outline-red{
    color: #f44336 !important;
    border-color: #f44336 !important;
}
.btn-outline-gray{
    color: #606060 !important;
    border-color: #606060 !important;
}
.btn-outline-hover-green:hover{
    color: #8bc34a !important;
    border-color: #8bc34a !important;
    transition: all ease-in-out 0.2s;
}
.btn-outline-hover-red:hover{
    color: #f44336 !important;
    border-color: #f44336 !important;
    transition: all ease-in-out 0.2s;
}
.normal-link{
    text-decoration: underline;
    color: #fff;
    font-weight: 500;
}
.normal-link:hover{
    color: #d50f0a;
    transition: all ease-in-out 0.3s;
}

.uppercase{
    text-transform: uppercase;
}
.form-label{
    color: #fff;
    margin-bottom: 0 !important;
}
.text-left{
    text-align: left;
}
select option{
    background: #222b35;
}


.flag {
    width: 32px;
    height: 32px;
    margin-right: 5px;
    background: url('country-flag.png') no-repeat;
}

.flag.flag-ad {
    background-position: -32px 0;
}

.flag.flag-ae {
    background-position: -64px 0;
}

.flag.flag-af {
    background-position: -96px 0;
}

.flag.flag-ag {
    background-position: -128px 0;
}

.flag.flag-ai {
    background-position: -160px 0;
}

.flag.flag-al {
    background-position: -192px 0;
}

.flag.flag-am {
    background-position: -224px 0;
}

.flag.flag-an {
    background-position: -256px 0;
}

.flag.flag-ao {
    background-position: -288px 0;
}

.flag.flag-ar {
    background-position: -320px 0;
}

.flag.flag-as {
    background-position: -352px 0;
}

.flag.flag-at {
    background-position: -384px 0;
}

.flag.flag-au {
    background-position: -416px 0;
}

.flag.flag-aw {
    background-position: -448px 0;
}

.flag.flag-ax {
    background-position: -480px 0;
}

.flag.flag-az {
    background-position: 0 -32px;
}

.flag.flag-ba {
    background-position: -32px -32px;
}

.flag.flag-bb {
    background-position: -64px -32px;
}

.flag.flag-bd {
    background-position: -96px -32px;
}

.flag.flag-be {
    background-position: -128px -32px;
}

.flag.flag-bf {
    background-position: -160px -32px;
}

.flag.flag-bg {
    background-position: -192px -32px;
}

.flag.flag-bh {
    background-position: -224px -32px;
}

.flag.flag-bi {
    background-position: -256px -32px;
}

.flag.flag-bj {
    background-position: -288px -32px;
}

.flag.flag-bl {
    background-position: -320px -32px;
}

.flag.flag-bm {
    background-position: -352px -32px;
}

.flag.flag-bn {
    background-position: -384px -32px;
}

.flag.flag-bo {
    background-position: -416px -32px;
}

.flag.flag-br {
    background-position: -448px -32px;
}

.flag.flag-bs {
    background-position: -480px -32px;
}

.flag.flag-bt {
    background-position: 0 -64px;
}

.flag.flag-bw {
    background-position: -32px -64px;
}

.flag.flag-by {
    background-position: -64px -64px;
}

.flag.flag-bz {
    background-position: -96px -64px;
}

.flag.flag-ca {
    background-position: -128px -64px;
}

.flag.flag-cd {
    background-position: -160px -64px;
}

.flag.flag-cf {
    background-position: -192px -64px;
}

.flag.flag-cg {
    background-position: -224px -64px;
}

.flag.flag-ch {
    background-position: -256px -64px;
}

.flag.flag-ci {
    background-position: -288px -64px;
}

.flag.flag-ck {
    background-position: -320px -64px;
}

.flag.flag-cl {
    background-position: -352px -64px;
}

.flag.flag-cm {
    background-position: -384px -64px;
}

.flag.flag-cn {
    background-position: -416px -64px;
}

.flag.flag-co {
    background-position: -448px -64px;
}

.flag.flag-cr {
    background-position: -480px -64px;
}

.flag.flag-cu {
    background-position: 0 -96px;
}

.flag.flag-cv {
    background-position: -32px -96px;
}

.flag.flag-cw {
    background-position: -64px -96px;
}

.flag.flag-cy {
    background-position: -96px -96px;
}

.flag.flag-cz {
    background-position: -128px -96px;
}

.flag.flag-de {
    background-position: -160px -96px;
}

.flag.flag-dj {
    background-position: -192px -96px;
}

.flag.flag-dk {
    background-position: -224px -96px;
}

.flag.flag-dm {
    background-position: -256px -96px;
}

.flag.flag-do {
    background-position: -288px -96px;
}

.flag.flag-dz {
    background-position: -320px -96px;
}

.flag.flag-ec {
    background-position: -352px -96px;
}

.flag.flag-ee {
    background-position: -384px -96px;
}

.flag.flag-eg {
    background-position: -416px -96px;
}

.flag.flag-eh {
    background-position: -448px -96px;
}

.flag.flag-er {
    background-position: -480px -96px;
}

.flag.flag-es {
    background-position: 0 -128px;
}

.flag.flag-et {
    background-position: -32px -128px;
}

.flag.flag-eu {
    background-position: -64px -128px;
}

.flag.flag-fi {
    background-position: -96px -128px;
}

.flag.flag-fj {
    background-position: -128px -128px;
}

.flag.flag-fk {
    background-position: -160px -128px;
}

.flag.flag-fm {
    background-position: -192px -128px;
}

.flag.flag-fo {
    background-position: -224px -128px;
}

.flag.flag-fr {
    background-position: -256px -128px;
}

.flag.flag-ga {
    background-position: -288px -128px;
}

.flag.flag-gb {
    background-position: -320px -128px;
}

.flag.flag-gd {
    background-position: -352px -128px;
}

.flag.flag-ge {
    background-position: -384px -128px;
}

.flag.flag-gg {
    background-position: -416px -128px;
}

.flag.flag-gh {
    background-position: -448px -128px;
}

.flag.flag-gi {
    background-position: -480px -128px;
}

.flag.flag-gl {
    background-position: 0 -160px;
}

.flag.flag-gm {
    background-position: -32px -160px;
}

.flag.flag-gn {
    background-position: -64px -160px;
}

.flag.flag-gp {
    background-position: -96px -160px;
}

.flag.flag-gq {
    background-position: -128px -160px;
}

.flag.flag-gr {
    background-position: -160px -160px;
}

.flag.flag-gs {
    background-position: -192px -160px;
}

.flag.flag-gt {
    background-position: -224px -160px;
}

.flag.flag-gu {
    background-position: -256px -160px;
}

.flag.flag-gw {
    background-position: -288px -160px;
}

.flag.flag-gy {
    background-position: -320px -160px;
}

.flag.flag-hk {
    background-position: -352px -160px;
}

.flag.flag-hn {
    background-position: -384px -160px;
}

.flag.flag-hr {
    background-position: -416px -160px;
}

.flag.flag-ht {
    background-position: -448px -160px;
}

.flag.flag-hu {
    background-position: -480px -160px;
}

.flag.flag-ic {
    background-position: 0 -192px;
}

.flag.flag-id {
    background-position: -32px -192px;
}

.flag.flag-ie {
    background-position: -64px -192px;
}

.flag.flag-il {
    background-position: -96px -192px;
}

.flag.flag-im {
    background-position: -128px -192px;
}

.flag.flag-in {
    background-position: -160px -192px;
}

.flag.flag-iq {
    background-position: -192px -192px;
}

.flag.flag-ir {
    background-position: -224px -192px;
}

.flag.flag-is {
    background-position: -256px -192px;
}

.flag.flag-it {
    background-position: -288px -192px;
}

.flag.flag-je {
    background-position: -320px -192px;
}

.flag.flag-jm {
    background-position: -352px -192px;
}

.flag.flag-jo {
    background-position: -384px -192px;
}

.flag.flag-jp {
    background-position: -416px -192px;
}

.flag.flag-ke {
    background-position: -448px -192px;
}

.flag.flag-kg {
    background-position: -480px -192px;
}

.flag.flag-kh {
    background-position: 0 -224px;
}

.flag.flag-ki {
    background-position: -32px -224px;
}

.flag.flag-km {
    background-position: -64px -224px;
}

.flag.flag-kn {
    background-position: -96px -224px;
}

.flag.flag-kp {
    background-position: -128px -224px;
}

.flag.flag-kr {
    background-position: -160px -224px;
}

.flag.flag-kw {
    background-position: -192px -224px;
}

.flag.flag-ky {
    background-position: -224px -224px;
}

.flag.flag-kz {
    background-position: -256px -224px;
}

.flag.flag-la {
    background-position: -288px -224px;
}

.flag.flag-lb {
    background-position: -320px -224px;
}

.flag.flag-lc {
    background-position: -352px -224px;
}

.flag.flag-li {
    background-position: -384px -224px;
}

.flag.flag-lk {
    background-position: -416px -224px;
}

.flag.flag-lr {
    background-position: -448px -224px;
}

.flag.flag-ls {
    background-position: -480px -224px;
}

.flag.flag-lt {
    background-position: 0 -256px;
}

.flag.flag-lu {
    background-position: -32px -256px;
}

.flag.flag-lv {
    background-position: -64px -256px;
}

.flag.flag-ly {
    background-position: -96px -256px;
}

.flag.flag-ma {
    background-position: -128px -256px;
}

.flag.flag-mc {
    background-position: -160px -256px;
}

.flag.flag-md {
    background-position: -192px -256px;
}

.flag.flag-me {
    background-position: -224px -256px;
}

.flag.flag-mf {
    background-position: -256px -256px;
}

.flag.flag-mg {
    background-position: -288px -256px;
}

.flag.flag-mh {
    background-position: -320px -256px;
}

.flag.flag-mk {
    background-position: -352px -256px;
}

.flag.flag-ml {
    background-position: -384px -256px;
}

.flag.flag-mm {
    background-position: -416px -256px;
}

.flag.flag-mn {
    background-position: -448px -256px;
}

.flag.flag-mo {
    background-position: -480px -256px;
}

.flag.flag-mp {
    background-position: 0 -288px;
}

.flag.flag-mq {
    background-position: -32px -288px;
}

.flag.flag-mr {
    background-position: -64px -288px;
}

.flag.flag-ms {
    background-position: -96px -288px;
}

.flag.flag-mt {
    background-position: -128px -288px;
}

.flag.flag-mu {
    background-position: -160px -288px;
}

.flag.flag-mv {
    background-position: -192px -288px;
}

.flag.flag-mw {
    background-position: -224px -288px;
}

.flag.flag-mx {
    background-position: -256px -288px;
}

.flag.flag-my {
    background-position: -288px -288px;
}

.flag.flag-mz {
    background-position: -320px -288px;
}

.flag.flag-na {
    background-position: -352px -288px;
}

.flag.flag-nc {
    background-position: -384px -288px;
}

.flag.flag-ne {
    background-position: -416px -288px;
}

.flag.flag-nf {
    background-position: -448px -288px;
}

.flag.flag-ng {
    background-position: -480px -288px;
}

.flag.flag-ni {
    background-position: 0 -320px;
}

.flag.flag-nl {
    background-position: -32px -320px;
}

.flag.flag-no {
    background-position: -64px -320px;
}

.flag.flag-np {
    background-position: -96px -320px;
}

.flag.flag-nr {
    background-position: -128px -320px;
}

.flag.flag-nu {
    background-position: -160px -320px;
}

.flag.flag-nz {
    background-position: -192px -320px;
}

.flag.flag-om {
    background-position: -224px -320px;
}

.flag.flag-pa {
    background-position: -256px -320px;
}

.flag.flag-pe {
    background-position: -288px -320px;
}

.flag.flag-pf {
    background-position: -320px -320px;
}

.flag.flag-pg {
    background-position: -352px -320px;
}

.flag.flag-ph {
    background-position: -384px -320px;
}

.flag.flag-pk {
    background-position: -416px -320px;
}

.flag.flag-pl {
    background-position: -448px -320px;
}

.flag.flag-pn {
    background-position: -480px -320px;
}

.flag.flag-pr {
    background-position: 0 -352px;
}

.flag.flag-ps {
    background-position: -32px -352px;
}

.flag.flag-pt {
    background-position: -64px -352px;
}

.flag.flag-pw {
    background-position: -96px -352px;
}

.flag.flag-py {
    background-position: -128px -352px;
}

.flag.flag-qa {
    background-position: -160px -352px;
}

.flag.flag-re {
    background-position: -192px -352px;
}

.flag.flag-ro {
    background-position: -224px -352px;
}

.flag.flag-rs {
    background-position: -256px -352px;
}

.flag.flag-ru {
    background-position: -288px -352px;
}

.flag.flag-rw {
    background-position: -320px -352px;
}

.flag.flag-sa {
    background-position: -352px -352px;
}

.flag.flag-sb {
    background-position: -384px -352px;
}

.flag.flag-sc {
    background-position: -416px -352px;
}

.flag.flag-sd {
    background-position: -448px -352px;
}

.flag.flag-se {
    background-position: -480px -352px;
}

.flag.flag-sg {
    background-position: 0 -384px;
}

.flag.flag-sh {
    background-position: -32px -384px;
}

.flag.flag-si {
    background-position: -64px -384px;
}

.flag.flag-sk {
    background-position: -96px -384px;
}

.flag.flag-sl {
    background-position: -128px -384px;
}

.flag.flag-sm {
    background-position: -160px -384px;
}

.flag.flag-sn {
    background-position: -192px -384px;
}

.flag.flag-so {
    background-position: -224px -384px;
}

.flag.flag-sr {
    background-position: -256px -384px;
}

.flag.flag-ss {
    background-position: -288px -384px;
}

.flag.flag-st {
    background-position: -320px -384px;
}

.flag.flag-sv {
    background-position: -352px -384px;
}

.flag.flag-sy {
    background-position: -384px -384px;
}

.flag.flag-sz {
    background-position: -416px -384px;
}

.flag.flag-tc {
    background-position: -448px -384px;
}

.flag.flag-td {
    background-position: -480px -384px;
}

.flag.flag-tf {
    background-position: 0 -416px;
}

.flag.flag-tg {
    background-position: -32px -416px;
}

.flag.flag-th {
    background-position: -64px -416px;
}

.flag.flag-tj {
    background-position: -96px -416px;
}

.flag.flag-tk {
    background-position: -128px -416px;
}

.flag.flag-tl {
    background-position: -160px -416px;
}

.flag.flag-tm {
    background-position: -192px -416px;
}

.flag.flag-tn {
    background-position: -224px -416px;
}

.flag.flag-to {
    background-position: -256px -416px;
}

.flag.flag-tr {
    background-position: -288px -416px;
}

.flag.flag-tt {
    background-position: -320px -416px;
}

.flag.flag-tv {
    background-position: -352px -416px;
}

.flag.flag-tw {
    background-position: -384px -416px;
}

.flag.flag-tz {
    background-position: -416px -416px;
}

.flag.flag-ua {
    background-position: -448px -416px;
}

.flag.flag-ug {
    background-position: -480px -416px;
}

.flag.flag-us {
    background-position: 0 -448px;
}

.flag.flag-uy {
    background-position: -32px -448px;
}

.flag.flag-uz {
    background-position: -64px -448px;
}

.flag.flag-va {
    background-position: -96px -448px;
}

.flag.flag-vc {
    background-position: -128px -448px;
}

.flag.flag-ve {
    background-position: -160px -448px;
}

.flag.flag-vg {
    background-position: -192px -448px;
}

.flag.flag-vi {
    background-position: -224px -448px;
}

.flag.flag-vn {
    background-position: -256px -448px;
}

.flag.flag-vu {
    background-position: -288px -448px;
}

.flag.flag-wf {
    background-position: -320px -448px;
}

.flag.flag-ws {
    background-position: -352px -448px;
}

.flag.flag-ye {
    background-position: -384px -448px;
}

.flag.flag-yt {
    background-position: -416px -448px;
}

.flag.flag-za {
    background-position: -448px -448px;
}

.flag.flag-zm {
    background-position: -480px -448px;
}

.flag.flag-zw {
    background-position: 0 -480px;
}

.underline-link{
    color: #fff;
    text-decoration: underline;
}
.underline-link:hover{
    color: #fff900 !important;
    transition: all ease-in-out 0.2s;
}
.btn-action{
    color: #f6f6f6;
    font-size: 0.9em;
    padding: 0.5em 1em;
    border-radius: 5px;
    background: #29333e;
    margin: 1em 0.3em;
}
.btn-action:hover{
    color: #ccc;
}
.btn-action-success{
    background: #006f32;
}
.btn-action-success:hover{
    background: #05582b;
    color: #fff;
}
.btn-action-danger{
    background: #b31d1d;
}
.btn-action-danger:hover{
    background: #a60000;
    color: #fff;
}
.small-counter-text .small-text{
    font-size: 33px !important;
}

.btn-white{
    color: #fff;
}
.btn-white:hover{
    color: #fff900;
}
.float-calendar{
    position: absolute;
    top: 42px;
    left: 0;
    z-index: 999;
}
.calendar-container{
    position: relative;
}
.no-link{
    cursor: initial;
}
.no-link:hover{
    color: #fff;
}
.loading-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.ml-1{
    margin-left: 0.5em;
}
.ml-2{
    margin-left: 1em;
}
.ml-3{
    margin-left: 1.5em;
}
.ml-4{
    margin-left: 2em;
}
.ml-5{
    margin-left: 2.5em;
}
.mr-5{
    margin-right: 2.5em;
}
.font-size-1x{
    font-size: 1rem !important;
}
.font-size-2x{
    font-size: 1.5rem !important;
}
.font-size-3x{
    font-size: 2rem !important;
}
.white-text{
    color: #fff !important;;
}
@media (max-width: 720px) {
    .form-label{
        width: 100%;
        text-align: center;
        color: #fff900;
        margin-bottom: 0.3em !important;
    }
    .form-control{
        text-align: center;
    }
}
.details-table{
    border: 0;
    border-collapse: collapse;
}
.details-table td{
    padding: 5px;
    font-size: 13px;
}
.details-table tr td:first-child{
    font-weight: 500;
    color: #c7c7c7;
    text-transform: uppercase;
}
.details-table tr td:last-child{
    word-break: break-word;
}
.small-text{
    font-size: 0.9em;
    color: #ababab;
}
.ticket-label{
    padding: 2px 15px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
}
.ticket-open{
    background-color: #f8c611 !important;
    color: #fff !important;
}
.ticket-new{
    background-color: #f78409 !important;
    color: #fff !important;
}
.ticket-resolved{
    background-color: #8b8b8b !important;
    color: #fff !important;
}
.ticket-answered{
    background-color: #4caf50 !important;
    color: #fff !important;
}
.d-block{
    display: block;
}
.no-letter-space{
    letter-spacing: normal !important;
}
.font-bold{
    font-weight: 600 !important;
}
.rtl-font{
    font-family: "IranSANS" !important;
    font-style: normal !important;
}
.rtl{
    direction: rtl !important;
    text-align: right;
}
.rtl-section{
    direction: rtl !important;
    text-align: right !important;
    font-family: "IranSANS" !important;
    font-style: normal !important;
}
.rtl-section::-webkit-input-placeholder { text-align:right !important;; }
/* mozilla solution */
.rtl-section input:-moz-placeholder { text-align:right !important; }

.body-rtl .input-group-text{
    border-radius: 4px 0px 0px 4px;
}
.body-rtl .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.body-rtl .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu){
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0;
}
.body-rtl .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
    border-radius: 0;
}
.body-rtl .btn-copy{
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.p-font-normal{
    font-size: 16px !important;
}
@media (min-width: 1400px) and (max-width: 1499px) {
    .p-font-normal{
        font-size: 14px !important;
    }
}
@media (min-width: 1500px) and (max-width: 1600px) {
    .p-font-normal{
        font-size: 13px !important;
    }
}
@media (min-width: 1300px) and (max-width: 1399px){
    .p-font-normal{
        font-size: 12px !important;
    }
}
@media (min-width: 1024px) and (max-width: 1199px){
    .p-font-normal{
        font-size: 12px !important;
    }
}