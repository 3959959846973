.ContextMenuOverlay{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
    background: rgba(0,0,0,0.5);
    writing-mode: initial;
}
.ContextMenu{
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    border-radius: 4px;
    background: #333;
    display: table;
    min-width: 230px;
    max-width: 230px;
    z-index: 999999999;
    color: #fff;
    position: absolute;
    right: 50%;
    top: 50%;
    box-shadow: 0 0 3px #333, 0 0 3px #333;
    writing-mode: initial;
}
.ContextBody{

    display: table-row;
    height: 100%;
}
.ContextBodyWrapper{
    padding: 0em;
}
.LeftContextMenu{
    right: 50%;
    left: auto;
}
.ContextMenu ul{
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
}
.ContextMenu ul li{
    padding: 0.8em 0.8em;
    border-bottom: 1px solid #444;
    transition: all ease-in-out 0.2s;
    font-size: 0.9em;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}
.ContextMenu ul li:hover{
    background: #444;
    transition: all ease-in-out 0.2s;
}
.ContextBodyTotal{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.8em 0.8em;
    cursor: initial;

}
.ContextBodyTotal div{
    flex: 1;
    text-align: left;
    font-size: 0.9em;
    color: #fff;
}
.ContextBodyTotal span{
    width: 40px;
    text-align: center;
    font-size: 0.9em;
    color: #fff;
}
.ContextFooter{
    width: 100%;
    border-top: 1px solid #404040;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5em 1em;
}
.CloseButton{
    font-size: 0.9em;
    padding: 0.3em 1em;
    background: #202020;
    border: 1px solid #202020;
    color: #fff;
}
.CloseButton:hover{
    background: #a60000;
    border: 1px solid #a60000;
    color: #fff;
}
@media (max-width: 720px) {
    .ContextMenu{
        min-width: 200px;
        max-width: 200px;

    }
    .ContextMenuFixed{
        position: fixed;
    }
}