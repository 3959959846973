@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://cdn.ninthware.com/scripts/css/LCD.css);
@font-face {
    font-family: 'LCD-Font';
    src:url(/static/media/LCDBOLD.69b4b6b4.woff) format('woff'),
    url(/static/media/LCDBOLD.26af221e.eot),
    url(/static/media/LCDBOLD.e4c734c4.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Agressive-Font';
    src:url(/static/media/AGRESSIVE.4d3bb41b.woff) format('woff'),
    url(/static/media/AGRESSIVE.2f098ee3.eot),
    url(/static/media/AGRESSIVE.a45dbf3a.otf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

body,
html{
    font-family: "Poppins", "IranSANS", sans-serif;
    background-color: var(--color-background);
    color: var(--text-color);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    height: 100%;
    margin: 0;

}

a{
    text-decoration: none;
    color: var(--color-primary);
}
a:hover{
    color: var(--color-secondary);
    transition: all ease-in-out 0.3s;
}

.App_Link__37Bkj{
    text-decoration: none;
    color: #d50f0a;
}
.App_Link__37Bkj:hover{
    color: #fff;
}
.App_LoadingContainer__16cRO{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: transparent;
    height: calc(100vh - 100px);

}

.dark {
    --breakpoint-sm: 37.5rem;
    --breakpoint-md: 60rem;
    --max-width: var(--breakpoint-md);
    --space-1: 0.25rem;
    --space-2: 0.5rem;
    --space-3: 0.75rem;
    --space-4: 1rem;
    --color-background: #000;
    --color-background-gray: #1b1b1b;
    --text-color: #fff;
    --color-primary: #f90505;
    --color-secondary: #03DAC6;
    --color-board-number: #1dee19;
    --color-gray: rgba(255,255,255, 0.11);
    --font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    --font-size-1: 0.625rem;
    --font-size-2: 0.75rem;
    --z-index-1: 1010;
    --z-index-2: 1020;
}

.light {
    --breakpoint-sm: 37.5rem;
    --breakpoint-md: 60rem;
    --max-width: var(--breakpoint-md);
    --space-1: 0.25rem;
    --space-2: 0.5rem;
    --space-3: 0.75rem;
    --space-4: 1rem;
    --color-background: #fff;
    --color-background-gray: #f1f1f1;
    --text-color: #000;
    --color-primary: #6200EE;
    --color-secondary: #03DAC6;
    --color-board-number: #1dee19;
    --color-gray: rgba(0,0,0, 0.11);
    --font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    --font-size-1: 0.625rem;
    --font-size-2: 0.75rem;
    --z-index-1: 1010;
    --z-index-2: 1020;
}
.ChipMove{
    position: absolute;
    transition: all .2s ease-in-out !important;
    background: transparent !important;
    z-index: 99999;
    width: 32px !important;
    height: 32px !important;
}
.ChipMove img{
    width: 37px !important;
    height: 37px !important;
}

@media (max-width: 720px) {
    .ChipMove{
        width: 24px !important;
        height: 24px !important;
    }
    .ChipMove img{
        width: 29px !important;
        height: 29px !important;
    }
}
.white-background{
    background: #fff !important;
}

#root{
    height: 100%;
}
.page-title{
    width: 100%;
    font-size: 1.3rem;
    background: #29333e;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 1em;
    color: #fff900;
}
.text-right{
    text-align: right;
}

.form-control{
    background: #1d1d1d;
    border-color: #1d1d1d;
    color: #fff;

}
.form-control:disabled{
    color: #9f9f9f;
}
.form-control:focus {
    color: #fff;
    background-color: #1d1d1d;
    border-color: #2b2b2b;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(72 72 72 / 25%);
}
.btn-dark{
    padding: 0.675rem 2rem;
    background: transparent;
    color: #fff900;
    text-transform: uppercase;
    font-weight: 500;
    border: 1px solid #b3b3b3;
}
.btn-dark:hover{
    background: #29333e;
    color: #fff;
    border: 1px solid #b3b3b3;
}
.btn-account{
    padding: 0.675rem 2rem;
    background: #d50f0a;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
}
.btn-account:hover{
    background: #910f0b;
    color: #fff;
}
.btn-copy{
    padding: 0.675rem 2rem;
    color: #fff;
    background: transparent;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border: 1px solid #b3b3b3;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
.btn-copy:hover{
    color: #d50f0a;
}
.btn-copy svg{
    font-size: 18px;
    margin-right: 10px;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545 !important;
    color: #dc3545 !important;

}
.btn-account:focus{
    box-shadow: 0 0 0 0.25rem rgba(213, 15, 10, 0.25) !important;
}
.start-center-value{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.end-center-value{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
}
.text-center-value{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.start-align-items{
    align-items: flex-start;
}
.full-width{
    width: 100%
}
.mr-1{
    margin-right: 0.2rem;
}
.mr-2{
    margin-right: 0.5rem;
}
.ml-1{
    margin-left: 0.2rem;
}
.input-group-text{
    height: 100%;
    background-color: #1c2024;
    border: 1px solid #b3b3b3;
    color: #ffeb3b;
    font-size: 14px;
    font-weight: 400;
    border-radius: 0 4px 4px 0;
}
.yellow-text{
    color: #fff900 !important;
}
.orange-text{
    color: #f78409 !important;
}
.green-text{
    color: #8bc34a !important;
}
.red-text{
    color: #f44336 !important;
}
.gray-text{
    color: #606060 !important;;
}
.hover-red{
    cursor: pointer;
}
.hover-red:hover{
    color: #f44336 !important;
    transition: all ease-in-out 0.2s;
}
.hover-green{
    cursor: pointer;
}
.hover-green:hover{
    color: #8bc34a !important;
    transition: all ease-in-out 0.2s;
}
.btn-outline{
    border: 1px solid;
    cursor: pointer;
    border-radius: 0 !important;
    line-height: 1 !important;;
    margin-right: 5px;
}
.btn-outline-green{
    color: #8bc34a !important;
    border-color: #8bc34a !important;
}
.btn-outline-red{
    color: #f44336 !important;
    border-color: #f44336 !important;
}
.btn-outline-gray{
    color: #606060 !important;
    border-color: #606060 !important;
}
.btn-outline-hover-green:hover{
    color: #8bc34a !important;
    border-color: #8bc34a !important;
    transition: all ease-in-out 0.2s;
}
.btn-outline-hover-red:hover{
    color: #f44336 !important;
    border-color: #f44336 !important;
    transition: all ease-in-out 0.2s;
}
.normal-link{
    text-decoration: underline;
    color: #fff;
    font-weight: 500;
}
.normal-link:hover{
    color: #d50f0a;
    transition: all ease-in-out 0.3s;
}

.uppercase{
    text-transform: uppercase;
}
.form-label{
    color: #fff;
    margin-bottom: 0 !important;
}
.text-left{
    text-align: left;
}
select option{
    background: #222b35;
}


.flag {
    width: 32px;
    height: 32px;
    margin-right: 5px;
    background: url(/static/media/country-flag.4631b218.png) no-repeat;
}

.flag.flag-ad {
    background-position: -32px 0;
}

.flag.flag-ae {
    background-position: -64px 0;
}

.flag.flag-af {
    background-position: -96px 0;
}

.flag.flag-ag {
    background-position: -128px 0;
}

.flag.flag-ai {
    background-position: -160px 0;
}

.flag.flag-al {
    background-position: -192px 0;
}

.flag.flag-am {
    background-position: -224px 0;
}

.flag.flag-an {
    background-position: -256px 0;
}

.flag.flag-ao {
    background-position: -288px 0;
}

.flag.flag-ar {
    background-position: -320px 0;
}

.flag.flag-as {
    background-position: -352px 0;
}

.flag.flag-at {
    background-position: -384px 0;
}

.flag.flag-au {
    background-position: -416px 0;
}

.flag.flag-aw {
    background-position: -448px 0;
}

.flag.flag-ax {
    background-position: -480px 0;
}

.flag.flag-az {
    background-position: 0 -32px;
}

.flag.flag-ba {
    background-position: -32px -32px;
}

.flag.flag-bb {
    background-position: -64px -32px;
}

.flag.flag-bd {
    background-position: -96px -32px;
}

.flag.flag-be {
    background-position: -128px -32px;
}

.flag.flag-bf {
    background-position: -160px -32px;
}

.flag.flag-bg {
    background-position: -192px -32px;
}

.flag.flag-bh {
    background-position: -224px -32px;
}

.flag.flag-bi {
    background-position: -256px -32px;
}

.flag.flag-bj {
    background-position: -288px -32px;
}

.flag.flag-bl {
    background-position: -320px -32px;
}

.flag.flag-bm {
    background-position: -352px -32px;
}

.flag.flag-bn {
    background-position: -384px -32px;
}

.flag.flag-bo {
    background-position: -416px -32px;
}

.flag.flag-br {
    background-position: -448px -32px;
}

.flag.flag-bs {
    background-position: -480px -32px;
}

.flag.flag-bt {
    background-position: 0 -64px;
}

.flag.flag-bw {
    background-position: -32px -64px;
}

.flag.flag-by {
    background-position: -64px -64px;
}

.flag.flag-bz {
    background-position: -96px -64px;
}

.flag.flag-ca {
    background-position: -128px -64px;
}

.flag.flag-cd {
    background-position: -160px -64px;
}

.flag.flag-cf {
    background-position: -192px -64px;
}

.flag.flag-cg {
    background-position: -224px -64px;
}

.flag.flag-ch {
    background-position: -256px -64px;
}

.flag.flag-ci {
    background-position: -288px -64px;
}

.flag.flag-ck {
    background-position: -320px -64px;
}

.flag.flag-cl {
    background-position: -352px -64px;
}

.flag.flag-cm {
    background-position: -384px -64px;
}

.flag.flag-cn {
    background-position: -416px -64px;
}

.flag.flag-co {
    background-position: -448px -64px;
}

.flag.flag-cr {
    background-position: -480px -64px;
}

.flag.flag-cu {
    background-position: 0 -96px;
}

.flag.flag-cv {
    background-position: -32px -96px;
}

.flag.flag-cw {
    background-position: -64px -96px;
}

.flag.flag-cy {
    background-position: -96px -96px;
}

.flag.flag-cz {
    background-position: -128px -96px;
}

.flag.flag-de {
    background-position: -160px -96px;
}

.flag.flag-dj {
    background-position: -192px -96px;
}

.flag.flag-dk {
    background-position: -224px -96px;
}

.flag.flag-dm {
    background-position: -256px -96px;
}

.flag.flag-do {
    background-position: -288px -96px;
}

.flag.flag-dz {
    background-position: -320px -96px;
}

.flag.flag-ec {
    background-position: -352px -96px;
}

.flag.flag-ee {
    background-position: -384px -96px;
}

.flag.flag-eg {
    background-position: -416px -96px;
}

.flag.flag-eh {
    background-position: -448px -96px;
}

.flag.flag-er {
    background-position: -480px -96px;
}

.flag.flag-es {
    background-position: 0 -128px;
}

.flag.flag-et {
    background-position: -32px -128px;
}

.flag.flag-eu {
    background-position: -64px -128px;
}

.flag.flag-fi {
    background-position: -96px -128px;
}

.flag.flag-fj {
    background-position: -128px -128px;
}

.flag.flag-fk {
    background-position: -160px -128px;
}

.flag.flag-fm {
    background-position: -192px -128px;
}

.flag.flag-fo {
    background-position: -224px -128px;
}

.flag.flag-fr {
    background-position: -256px -128px;
}

.flag.flag-ga {
    background-position: -288px -128px;
}

.flag.flag-gb {
    background-position: -320px -128px;
}

.flag.flag-gd {
    background-position: -352px -128px;
}

.flag.flag-ge {
    background-position: -384px -128px;
}

.flag.flag-gg {
    background-position: -416px -128px;
}

.flag.flag-gh {
    background-position: -448px -128px;
}

.flag.flag-gi {
    background-position: -480px -128px;
}

.flag.flag-gl {
    background-position: 0 -160px;
}

.flag.flag-gm {
    background-position: -32px -160px;
}

.flag.flag-gn {
    background-position: -64px -160px;
}

.flag.flag-gp {
    background-position: -96px -160px;
}

.flag.flag-gq {
    background-position: -128px -160px;
}

.flag.flag-gr {
    background-position: -160px -160px;
}

.flag.flag-gs {
    background-position: -192px -160px;
}

.flag.flag-gt {
    background-position: -224px -160px;
}

.flag.flag-gu {
    background-position: -256px -160px;
}

.flag.flag-gw {
    background-position: -288px -160px;
}

.flag.flag-gy {
    background-position: -320px -160px;
}

.flag.flag-hk {
    background-position: -352px -160px;
}

.flag.flag-hn {
    background-position: -384px -160px;
}

.flag.flag-hr {
    background-position: -416px -160px;
}

.flag.flag-ht {
    background-position: -448px -160px;
}

.flag.flag-hu {
    background-position: -480px -160px;
}

.flag.flag-ic {
    background-position: 0 -192px;
}

.flag.flag-id {
    background-position: -32px -192px;
}

.flag.flag-ie {
    background-position: -64px -192px;
}

.flag.flag-il {
    background-position: -96px -192px;
}

.flag.flag-im {
    background-position: -128px -192px;
}

.flag.flag-in {
    background-position: -160px -192px;
}

.flag.flag-iq {
    background-position: -192px -192px;
}

.flag.flag-ir {
    background-position: -224px -192px;
}

.flag.flag-is {
    background-position: -256px -192px;
}

.flag.flag-it {
    background-position: -288px -192px;
}

.flag.flag-je {
    background-position: -320px -192px;
}

.flag.flag-jm {
    background-position: -352px -192px;
}

.flag.flag-jo {
    background-position: -384px -192px;
}

.flag.flag-jp {
    background-position: -416px -192px;
}

.flag.flag-ke {
    background-position: -448px -192px;
}

.flag.flag-kg {
    background-position: -480px -192px;
}

.flag.flag-kh {
    background-position: 0 -224px;
}

.flag.flag-ki {
    background-position: -32px -224px;
}

.flag.flag-km {
    background-position: -64px -224px;
}

.flag.flag-kn {
    background-position: -96px -224px;
}

.flag.flag-kp {
    background-position: -128px -224px;
}

.flag.flag-kr {
    background-position: -160px -224px;
}

.flag.flag-kw {
    background-position: -192px -224px;
}

.flag.flag-ky {
    background-position: -224px -224px;
}

.flag.flag-kz {
    background-position: -256px -224px;
}

.flag.flag-la {
    background-position: -288px -224px;
}

.flag.flag-lb {
    background-position: -320px -224px;
}

.flag.flag-lc {
    background-position: -352px -224px;
}

.flag.flag-li {
    background-position: -384px -224px;
}

.flag.flag-lk {
    background-position: -416px -224px;
}

.flag.flag-lr {
    background-position: -448px -224px;
}

.flag.flag-ls {
    background-position: -480px -224px;
}

.flag.flag-lt {
    background-position: 0 -256px;
}

.flag.flag-lu {
    background-position: -32px -256px;
}

.flag.flag-lv {
    background-position: -64px -256px;
}

.flag.flag-ly {
    background-position: -96px -256px;
}

.flag.flag-ma {
    background-position: -128px -256px;
}

.flag.flag-mc {
    background-position: -160px -256px;
}

.flag.flag-md {
    background-position: -192px -256px;
}

.flag.flag-me {
    background-position: -224px -256px;
}

.flag.flag-mf {
    background-position: -256px -256px;
}

.flag.flag-mg {
    background-position: -288px -256px;
}

.flag.flag-mh {
    background-position: -320px -256px;
}

.flag.flag-mk {
    background-position: -352px -256px;
}

.flag.flag-ml {
    background-position: -384px -256px;
}

.flag.flag-mm {
    background-position: -416px -256px;
}

.flag.flag-mn {
    background-position: -448px -256px;
}

.flag.flag-mo {
    background-position: -480px -256px;
}

.flag.flag-mp {
    background-position: 0 -288px;
}

.flag.flag-mq {
    background-position: -32px -288px;
}

.flag.flag-mr {
    background-position: -64px -288px;
}

.flag.flag-ms {
    background-position: -96px -288px;
}

.flag.flag-mt {
    background-position: -128px -288px;
}

.flag.flag-mu {
    background-position: -160px -288px;
}

.flag.flag-mv {
    background-position: -192px -288px;
}

.flag.flag-mw {
    background-position: -224px -288px;
}

.flag.flag-mx {
    background-position: -256px -288px;
}

.flag.flag-my {
    background-position: -288px -288px;
}

.flag.flag-mz {
    background-position: -320px -288px;
}

.flag.flag-na {
    background-position: -352px -288px;
}

.flag.flag-nc {
    background-position: -384px -288px;
}

.flag.flag-ne {
    background-position: -416px -288px;
}

.flag.flag-nf {
    background-position: -448px -288px;
}

.flag.flag-ng {
    background-position: -480px -288px;
}

.flag.flag-ni {
    background-position: 0 -320px;
}

.flag.flag-nl {
    background-position: -32px -320px;
}

.flag.flag-no {
    background-position: -64px -320px;
}

.flag.flag-np {
    background-position: -96px -320px;
}

.flag.flag-nr {
    background-position: -128px -320px;
}

.flag.flag-nu {
    background-position: -160px -320px;
}

.flag.flag-nz {
    background-position: -192px -320px;
}

.flag.flag-om {
    background-position: -224px -320px;
}

.flag.flag-pa {
    background-position: -256px -320px;
}

.flag.flag-pe {
    background-position: -288px -320px;
}

.flag.flag-pf {
    background-position: -320px -320px;
}

.flag.flag-pg {
    background-position: -352px -320px;
}

.flag.flag-ph {
    background-position: -384px -320px;
}

.flag.flag-pk {
    background-position: -416px -320px;
}

.flag.flag-pl {
    background-position: -448px -320px;
}

.flag.flag-pn {
    background-position: -480px -320px;
}

.flag.flag-pr {
    background-position: 0 -352px;
}

.flag.flag-ps {
    background-position: -32px -352px;
}

.flag.flag-pt {
    background-position: -64px -352px;
}

.flag.flag-pw {
    background-position: -96px -352px;
}

.flag.flag-py {
    background-position: -128px -352px;
}

.flag.flag-qa {
    background-position: -160px -352px;
}

.flag.flag-re {
    background-position: -192px -352px;
}

.flag.flag-ro {
    background-position: -224px -352px;
}

.flag.flag-rs {
    background-position: -256px -352px;
}

.flag.flag-ru {
    background-position: -288px -352px;
}

.flag.flag-rw {
    background-position: -320px -352px;
}

.flag.flag-sa {
    background-position: -352px -352px;
}

.flag.flag-sb {
    background-position: -384px -352px;
}

.flag.flag-sc {
    background-position: -416px -352px;
}

.flag.flag-sd {
    background-position: -448px -352px;
}

.flag.flag-se {
    background-position: -480px -352px;
}

.flag.flag-sg {
    background-position: 0 -384px;
}

.flag.flag-sh {
    background-position: -32px -384px;
}

.flag.flag-si {
    background-position: -64px -384px;
}

.flag.flag-sk {
    background-position: -96px -384px;
}

.flag.flag-sl {
    background-position: -128px -384px;
}

.flag.flag-sm {
    background-position: -160px -384px;
}

.flag.flag-sn {
    background-position: -192px -384px;
}

.flag.flag-so {
    background-position: -224px -384px;
}

.flag.flag-sr {
    background-position: -256px -384px;
}

.flag.flag-ss {
    background-position: -288px -384px;
}

.flag.flag-st {
    background-position: -320px -384px;
}

.flag.flag-sv {
    background-position: -352px -384px;
}

.flag.flag-sy {
    background-position: -384px -384px;
}

.flag.flag-sz {
    background-position: -416px -384px;
}

.flag.flag-tc {
    background-position: -448px -384px;
}

.flag.flag-td {
    background-position: -480px -384px;
}

.flag.flag-tf {
    background-position: 0 -416px;
}

.flag.flag-tg {
    background-position: -32px -416px;
}

.flag.flag-th {
    background-position: -64px -416px;
}

.flag.flag-tj {
    background-position: -96px -416px;
}

.flag.flag-tk {
    background-position: -128px -416px;
}

.flag.flag-tl {
    background-position: -160px -416px;
}

.flag.flag-tm {
    background-position: -192px -416px;
}

.flag.flag-tn {
    background-position: -224px -416px;
}

.flag.flag-to {
    background-position: -256px -416px;
}

.flag.flag-tr {
    background-position: -288px -416px;
}

.flag.flag-tt {
    background-position: -320px -416px;
}

.flag.flag-tv {
    background-position: -352px -416px;
}

.flag.flag-tw {
    background-position: -384px -416px;
}

.flag.flag-tz {
    background-position: -416px -416px;
}

.flag.flag-ua {
    background-position: -448px -416px;
}

.flag.flag-ug {
    background-position: -480px -416px;
}

.flag.flag-us {
    background-position: 0 -448px;
}

.flag.flag-uy {
    background-position: -32px -448px;
}

.flag.flag-uz {
    background-position: -64px -448px;
}

.flag.flag-va {
    background-position: -96px -448px;
}

.flag.flag-vc {
    background-position: -128px -448px;
}

.flag.flag-ve {
    background-position: -160px -448px;
}

.flag.flag-vg {
    background-position: -192px -448px;
}

.flag.flag-vi {
    background-position: -224px -448px;
}

.flag.flag-vn {
    background-position: -256px -448px;
}

.flag.flag-vu {
    background-position: -288px -448px;
}

.flag.flag-wf {
    background-position: -320px -448px;
}

.flag.flag-ws {
    background-position: -352px -448px;
}

.flag.flag-ye {
    background-position: -384px -448px;
}

.flag.flag-yt {
    background-position: -416px -448px;
}

.flag.flag-za {
    background-position: -448px -448px;
}

.flag.flag-zm {
    background-position: -480px -448px;
}

.flag.flag-zw {
    background-position: 0 -480px;
}

.underline-link{
    color: #fff;
    text-decoration: underline;
}
.underline-link:hover{
    color: #fff900 !important;
    transition: all ease-in-out 0.2s;
}
.btn-action{
    color: #f6f6f6;
    font-size: 0.9em;
    padding: 0.5em 1em;
    border-radius: 5px;
    background: #29333e;
    margin: 1em 0.3em;
}
.btn-action:hover{
    color: #ccc;
}
.btn-action-success{
    background: #006f32;
}
.btn-action-success:hover{
    background: #05582b;
    color: #fff;
}
.btn-action-danger{
    background: #b31d1d;
}
.btn-action-danger:hover{
    background: #a60000;
    color: #fff;
}
.small-counter-text .small-text{
    font-size: 33px !important;
}

.btn-white{
    color: #fff;
}
.btn-white:hover{
    color: #fff900;
}
.float-calendar{
    position: absolute;
    top: 42px;
    left: 0;
    z-index: 999;
}
.calendar-container{
    position: relative;
}
.no-link{
    cursor: initial;
}
.no-link:hover{
    color: #fff;
}
.loading-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.ml-1{
    margin-left: 0.5em;
}
.ml-2{
    margin-left: 1em;
}
.ml-3{
    margin-left: 1.5em;
}
.ml-4{
    margin-left: 2em;
}
.ml-5{
    margin-left: 2.5em;
}
.mr-5{
    margin-right: 2.5em;
}
.font-size-1x{
    font-size: 1rem !important;
}
.font-size-2x{
    font-size: 1.5rem !important;
}
.font-size-3x{
    font-size: 2rem !important;
}
.white-text{
    color: #fff !important;;
}
@media (max-width: 720px) {
    .form-label{
        width: 100%;
        text-align: center;
        color: #fff900;
        margin-bottom: 0.3em !important;
    }
    .form-control{
        text-align: center;
    }
}
.details-table{
    border: 0;
    border-collapse: collapse;
}
.details-table td{
    padding: 5px;
    font-size: 13px;
}
.details-table tr td:first-child{
    font-weight: 500;
    color: #c7c7c7;
    text-transform: uppercase;
}
.details-table tr td:last-child{
    word-break: break-word;
}
.small-text{
    font-size: 0.9em;
    color: #ababab;
}
.ticket-label{
    padding: 2px 15px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
}
.ticket-open{
    background-color: #f8c611 !important;
    color: #fff !important;
}
.ticket-new{
    background-color: #f78409 !important;
    color: #fff !important;
}
.ticket-resolved{
    background-color: #8b8b8b !important;
    color: #fff !important;
}
.ticket-answered{
    background-color: #4caf50 !important;
    color: #fff !important;
}
.d-block{
    display: block;
}
.no-letter-space{
    letter-spacing: normal !important;
}
.font-bold{
    font-weight: 600 !important;
}
.rtl-font{
    font-family: "IranSANS" !important;
    font-style: normal !important;
}
.rtl{
    direction: rtl !important;
    text-align: right;
}
.rtl-section{
    direction: rtl !important;
    text-align: right !important;
    font-family: "IranSANS" !important;
    font-style: normal !important;
}
.rtl-section::-webkit-input-placeholder { text-align:right !important;; }
/* mozilla solution */
.rtl-section input:-moz-placeholder { text-align:right !important; }

.body-rtl .input-group-text{
    border-radius: 4px 0px 0px 4px;
}
.body-rtl .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.body-rtl .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu){
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0;
}
.body-rtl .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
    border-radius: 0;
}
.body-rtl .btn-copy{
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.p-font-normal{
    font-size: 16px !important;
}
@media (min-width: 1400px) and (max-width: 1499px) {
    .p-font-normal{
        font-size: 14px !important;
    }
}
@media (min-width: 1500px) and (max-width: 1600px) {
    .p-font-normal{
        font-size: 13px !important;
    }
}
@media (min-width: 1300px) and (max-width: 1399px){
    .p-font-normal{
        font-size: 12px !important;
    }
}
@media (min-width: 1024px) and (max-width: 1199px){
    .p-font-normal{
        font-size: 12px !important;
    }
}
.Loading_AppLogoContainer__3hFEA{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background: #000;
}
.Loading_AppLogo__l7JsP {
    width: 150px;
    pointer-events: none;
}

@keyframes GameTable_SpinAnimation__3DlO3 {
    to{
        transform: rotate(360deg);
    }
}
.GameTable_FeedScreen__2d5_I{
    width: 100%;
    height: 100%;
}
.GameTable_NeighboursBar__j-R-f{
    position: absolute;
    right: -77px;
}
.GameTable_WheelDate__dblpt{
    height: 45px;
    line-height: 44px;
    margin: 1em 0px 0px 0px;
    text-align: center;
    color: #1dee19;
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
    font-style: italic;
}
.GameTable_BlockAccount__3u0fP{
    width: 100%;
    height: 100%;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.GameTable_WheelNumberContainer__3yGEs{
    position: relative;
}
.GameTable_Container__37vPR{
    position: relative;
    padding: 0 2em 1em 2em;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;

}
.GameTable_TimerText__2lKg5{
    color: #3dc733;
    margin-left: 0.5em;
}
.GameTable_Display__3MkK9{
    width: 100%;
    display: flex;
    align-content: center;
}
.GameTable_TableContainer__7K6ZF{
    display: flex;
    height: 100%;
}
.GameTable_Monitor__2QtYO{
    color: #fff;
    font-family: "LCD-Font", "IranSANS";
    font-size: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    height: 100px;
    letter-spacing: 5px;
    padding: 0.2em 0em;
    border-radius: 10px;
    justify-content: center;
    background: #0000;
}
.GameTable_LogoRightOfMonitor__2rSy5{
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.GameTable_LogoRightOfMonitorRoulette__1iNGo{
    height: 110px;
}
.GameTable_LanguagesRightOfMonitor__3uoh2,
.GameTable_LanguagesRightOfMonitorMobile__WjQkM{
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}
.GameTable_LanguagesRightOfMonitor__3uoh2 a,
.GameTable_LanguagesRightOfMonitorMobile__WjQkM a
{
    color: #fff;
    padding: 5px 10px;
    background: #3e3e3e;
    margin: 0 5px;
    border-radius: 5px;
}
.GameTable_LanguagesRightOfMonitor__3uoh2 a:hover,
.GameTable_LanguagesRightOfMonitorMobile__WjQkM a:hover{
    background-color: #f90505;
    transition: background-color ease-in-out 0.3s;
}
.GameTable_LanguagesRightOfMonitorActive__3S5J5{
    background-color: #f90505 !important;
    cursor: none !important;
}
.GameTable_MobileLogoOfMonitor__3PUat{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.GameTable_Monitor__2QtYO div{
}
.GameTable_VideoMonitor__1j6zf{
    width: 100%;
    display: flex;
    height: calc(60% - 100px);
    max-height: calc(60% - 100px);
    /*height: 450px*/
}
.GameTable_WonContainer__OOsqi{
    width: 100%;
    display: flex;
    align-items: flex-start;
}
.GameTable_TopCells__1XWBw{
    width: 100px;
}
.GameTable_MonitorContainer__1WYwO{
    width: 100%;
}
.GameTable_LeftDisplay__EsL62{
    width: 52%;
    height: 100%;
    order: 1;
}
.GameTable_LeftGreenDisplay__2CtPL{
    width: 48%;
}
.GameTable_LeftGreenDisplayContainer__36VOi{
    height: 100px;
    display: flex;
    align-items: center;
}
.GameTable_GrayCircleButton__2azdG{
    background: #e4e4e4  !important;
}
.GameTable_LeftGreenDisplayContainer__36VOi .GameTable_CircleButton__3vOz2{
    width: 55px;
    height: 55px;
    margin: 0;
}
.GameTable_LeftGreenDisplayContainer__36VOi .GameTable_CircleButton__3vOz2 .GameTable_CircleButtonWrapper__3g0aB{
    height: 47px;
    width: 47px;
    min-width: 47px;
    font-size: 19px;
}
.GameTable_RedCircleButton__2dO5t{
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(236,236,236,1) 25%, rgba(210,210,210,1) 39%, rgba(13,13,13,1) 41%, rgba(24,24,24,1) 77%, rgba(83,83,83,1) 95%, rgba(156,156,156,1) 100%) !important;
}
.GameTable_RedCircleButton__2dO5t .GameTable_CircleButtonWrapper__3g0aB{
    color: #f90505 !important;
}
.GameTable_LeftGreenDisplayWrapper__L83Pp{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.GameTable_NumberCounterWrapper__12jGQ{
    display: flex;
    align-items: center;
    justify-content: center;
}
.GameTable_LeftGreenDisplayLastNumber__1kyhW{
    top: 25px;
    left: 0;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.GameTable_RightDisplay__mOcJ9{
    width: 48%;
    padding: 0 0 0 1em;
    height: 100%;
    order: 2;
}
.GameTable_RightDisplayContainer__3UOeX{
    height: calc(100% - 100px);
    display: flex;
}
.GameTable_Table__3y0Wg{
    width: 60%;
    height: 100%;
    position: relative;
    order: 1;
}
.GameTable_FullTable__3kzM7{
    width: 100%;
    direction: ltr !important;
}
.GameTable_Table__3y0Wg table{
    height: 100%;
}
.GameTable_FullTable__3kzM7 table{
    width: 70%;
    margin: 1em auto 0 auto;
    height: calc(100% - 120px) !important
}
.GameTable_Table__3y0Wg table td{
    font-family: "Agressive-Font";
    border: 0;
    max-width: 61px;
    min-width: 61px;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    position: relative;
}
.GameTable_CellNumber__2wlrH{
    padding: 0.71em 0.4em;
    position: relative;
}

.GameTable_TextSmall__3BhC-.GameTable_CellNumber__2wlrH{
    padding: 1.1em 0.4em;
}
.GameTable_TableCellActive__2jvkF{
    color: #000 !important;
}
.GameTable_Table__3y0Wg table td.GameTable_Vertical__3oplF{
    writing-mode: tb-rl;
    text-orientation: mixed;
}
.GameTable_Table__3y0Wg table td:hover{
}
.GameTable_TextGreenForce__1vDwI{
    color: #3dc733 !important;
}
.GameTable_TextGreen__2aoqr{
    color: #3dc733;
}
.GameTable_TextRight__3spcD{
    text-align: right;
}
.GameTable_TextSmall__3BhC-{
    font-size: 17px !important;
}
.GameTable_TextRed__1bpGO{
    color: #f90505;
}
.GameTable_ColAction__tsKlU{
    padding: 0 0.5em !important;
    text-align: center;
    font-size: 14px;
    color: #b70001;
    cursor: pointer;
}
.GameTable_ColAction__tsKlU:hover{
    color: #f90505;
    transition: all ease-in-out 0.4s;
}
.GameTable_MonitorScreen__16Wax{
    width: 100%;
    /*border: 20px solid #2a2a2a;*/
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

}


.GameTable_MonitorScreen__16Wax p{
    height: initial !important;
    margin: 0;
    padding: 0;
}
.GameTable_Diamond__azLW0 {
    height: 70px;
}
.GameTable_DiamondRed__3Dpma{
    overflow: hidden;
    width: 28px;
    height: 28px;
    background: #a60000;
}
.GameTable_DiamondBlack__2OWl0{
    overflow: hidden;
    width: 28px;
    height: 28px;
    background: #212121;
}
.GameTable_TableRightBorder__1nvrD{
    width: 12px;
    height: 100%;
    background: transparent;
    position: absolute;
    right: -6px;
    top: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.GameTable_VerticalLine__2Jjxr{
    width: 2px;
    height: 100%;
    background: #393939;
}
.GameTable_TableLeftBorder__8p4og{
    width: 12px;
    height: 100%;
    background: transparent;
    position: absolute;
    left: -6px;
    top: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.GameTable_TableTopBorder__17lPD{
    width: 100%;
    height: 12px;
    background: transparent;
    position: absolute;
    left: 0px;
    right: 0px;
    top: -6px;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.GameTable_WheelWrapper__3WBSe{
    margin-top: 30px;
}
.GameTable_HorizontalLine__22rSv{
    height: 2px;
    width: 100%;
    background: #393939;
}
.GameTable_TableBottomBorder__2dijK{
    width: 100%;
    height: 12px;
    background: transparent;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: -6px;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.GameTable_TableTopLeftCorner__2bGbb{
    width: 16px;
    height: 16px;
    background: transparent;
    position: absolute;
    top: -8px;
    left: -8px;
    z-index: 99999;
}
.GameTable_ToolsChipsContainer__3oTkS{
    width: 40%;
    order: 2;
}
.GameTable_SubToolsContainer__29rLl{
    width: 65%;
    margin: 1em auto 1em auto;
}
.GameTable_Chips__1jsTo{

}
.GameTable_NeighboursBar__j-R-f label{
    display: inline-block;
}
.GameTable_Tools__1Tn9U{
    width: 100%;
}
.GameTable_ChipsWrapper__1nOsq{
    padding-top: 33px;
    margin: 0 auto;
}
.GameTable_CellChipButton__23a2e{
    width: 32px;
    height: 32px;
    background: transparent;
    border: none;
    border-radius: 100%;
    position: absolute;
    z-index: 99999;

}
.GameTable_CellChipButton__23a2e img{
    width: 32px;
    height: 32px;
}
.GameTable_ChipButton__19ZSw{
    width: 62px;
    height: 62px;
    background: transparent;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.2em 2em;
}
.GameTable_ChipButton__19ZSw img{
    width: 52px;
    height: 52px;
}
.GameTable_ChipButton__19ZSw:hover, .GameTable_ChipActive__82i8U{
    background-color: #006f32;
    border: 2px solid #22b22d;

    transition: all ease-in-out 0.3s;
}
.GameTable_ToolsWrapper__22Cu-{
    padding-top: 57px;
    margin: 0 auto;
}
.GameTable_ToolsButton__2nYdB{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(236,236,236,1) 25%, rgba(210,210,210,1) 39%, rgba(13,13,13,1) 41%, rgba(24,24,24,1) 77%, rgba(83,83,83,1) 95%, rgba(156,156,156,1) 100%);
    border-radius: 45px;
    height: 45px;
    position: relative;
}
.GameTable_DateButton__3XZGw{
    height: 50px;
    border: 0;
    background: none;
}
.GameTable_DateToolsButtonWrapper__3-TmW{
    height: 41px !important;
    background: black;
    color: #1dee19 !important;
}
.GameTable_CircleGrayButton__Cs9Qs{
    height: 45px;
    width: 45px;
    max-height: 45px;
    max-width: 45px;
    min-height: 45px;
    min-width: 45px;
    background: #4f4f4f;
    border: none;
    border-radius: 100%;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
}
.GameTable_MobileFloatingMenuWrapperActive__1398j{
    color: #3dc733 !important;
}
.GameTable_ActiveCircleGrayButton__3evWx{
    background-color:#3dc733 !important;
    color: #333 !important;
    transition: all ease-in-out 0.3s;
}
.GameTable_CircleGrayButton__Cs9Qs:hover{
    background: #e4e4e4;
    transition: all ease-in-out 0.2s;
}
.GameTable_ActiveCircleGrayButton__3evWx:hover{
    background-color:#3dc733;
}
.GameTable_ToolsButtonWrapper__c2SWn{
    background: #000;
    color: #fff;
    width: 100%;
    height: 34px;
    border-radius: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    font-style: italic;
}
.GameTable_ToolsButtonDisabled__1vWc3 .GameTable_ToolsButtonWrapper__c2SWn{
    background: #000;
    cursor: initial;
}
.GameTable_ToolsButton__2nYdB:hover > .GameTable_ToolsButtonWrapper__c2SWn{
    color: #1dee19;
    transition: all ease-in-out 0.3s;
}
.GameTable_CircleButton__3vOz2{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(236,236,236,1) 25%, rgba(210,210,210,1) 39%, rgba(13,13,13,1) 41%, rgba(24,24,24,1) 77%, rgba(83,83,83,1) 95%, rgba(156,156,156,1) 100%);
    border-radius: 100%;
    height: 100px;
    width: 100px;
}
.GameTable_MenuDisabled__tbD7g{
    color: #484848 !important;
}
.GameTable_CircleButtonWrapper__3g0aB{
    background: #000;
    color: #fff;
    height: 90px;
    min-width: 90px;
    width: 90px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    font-style: italic;
}
.GameTable_CircleButton__3vOz2:hover > .GameTable_CircleButtonWrapper__3g0aB{
    color: #1dee19;
    transition: all ease-in-out 0.3s;
}
.GameTable_RedCircleButtonWrapper__1-f4n{
    background: #fb0102;
    color: #fff !important;
}
.GameTable_GreenCircleButtonWrapper__JQSiz{
    background: #02b051;
}
.GameTable_GreenBackground__2UZcN{
    background: #3dc733;
}
.GameTable_Loading__1fuoz{
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
.GameTable_Loading__1fuoz svg{
    font-size: 24px;
    margin-bottom: 3px;
}

.GameTable_StatsWrapper__3AGAv{
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.GameTable_StatsLeftBar__3U4G1{
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.GameTable_StatsCenterBar__1ldpX{
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.GameTable_StatsTableWrapper__19hsB{
    width: 100%;
    height: 100%;
}
.GameTable_StatsTable__4hQY3{
    width: 100%;
    margin-bottom: 15px;
}
.GameTable_StatsTableWrapper__19hsB table:last-child{
    margin-bottom: 0 !important;
}
.GameTable_StatsTable__4hQY3 th{
    color: #02b051;
    font-size: 13px;
    font-weight: bold;
    font-family: "Agressive-Font";
    text-align: center;
    border: 1px solid #5d5d5d;
    padding: 0.8em 0.3em;
    width: 25%;
}
.GameTable_StatsTable__4hQY3 td{
    text-align: center;
    border: 1px solid #5d5d5d;
    background: #262626;
    padding: 0.8em 1em;
    font-weight: bold;
    width: 25%;
}
.GameTable_StatsCellNoBorder__2pjjW{
    border: 0 !important;
    background: none !important;
}
.GameTable_StatsColRed__2hhNt{
    background: transparent !important;
}
.GameTable_StatsRightBar__2GkbN{
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.GameTable_StatsBarItems__2uzBC{
    width: 35px;
    border: 1px solid #5d5d5d;
    list-style: none;
    margin: 0;
    padding: 0;
}
.GameTable_StatsBarItems__2uzBC li{
    height: 35px;
    color: #e6e4e7;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.GameTable_StatsBarItems__2uzBC li.GameTable_StatsBarColdIcon__12Iyu{
    background: #054068;
}
.GameTable_StatsBarItems__2uzBC li.GameTable_StatsBarFireIcon__diVNh{
    background: #5a1706;
}
.GameTable_TopCellWrapper__3HtP6{
    width: 60px;
    margin-right: 15px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.GameTable_TopCellTable__TeU6v{
    height: 100%;
}
.GameTable_TopCellTable__TeU6v td{
    border: 1px solid #cfcfcf;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    font-family: "Agressive-Font";
}
.GameTable_TopCellTable__TeU6v td.GameTable_NoBorder__1zED0{
    border: none
}
.GameTable_TopCellTable__TeU6v .GameTable_CellRed__VVQIo{
    background: #fb0102;
}
.GameTable_TopCellTable__TeU6v .GameTable_CellGreen__2MuiL{
    background: #02b051;
}
.GameTable_WheelContainer__1kiM4{
    width: 44%;
    /*position: relative;*/
}
.GameTable_WonBoard__3oXyd{
    width: 56%;
}
.GameTable_WonBoardToolbar__3O1Iz{
    width: 100%;
    display: flex;
    align-items: center;
    margin: 1em 0px 0px 0px;
    position: relative;
}
.GameTable_NeighbourButton__OZGzC{
    background: transparent;
    color: #e2e2e2;
    font-size: 12px;
    border: 1px solid #393939;
    max-width: 35px;
    max-height: 35px;
    min-width: 35px;
    min-height: 35px;
}
.GameTable_NeighbourButton__OZGzC:hover, .GameTable_NeighbourButtonActive__1a0i4{
    background: #02b051;
    border-color: #02b051;
    transition: all ease-in-out 0.3s;
}
.GameTable_SpeakerWonBoardButton__1qTT5{

}
.GameTable_WonBoardButton__1doBd, .GameTable_SpeakerWonBoardButton__1qTT5{
    background: none;
    border: none;
    max-width: 45px;
    max-height: 45px;
    min-width: 45px;
    min-height: 45px;
    color: #a8a8a8;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 10px;
}
.GameTable_WonBoardButtonCircle__2ZTvq{
    background: #4c4c4c;
    max-width: 33px;
    max-height: 33px;
    min-width: 33px;
    min-height: 33px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}
.GameTable_VolumeBox__2KjJI{
    background: #4c4c4c;
    max-width: 170px;
    min-width: 170px;
    min-height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: calc(100% + 10px);
    left: 0;
    z-index: 99999999999;
}
  
.GameTable_WonBoardButtonActive__2jbDB, .GameTable_WonBoardButton__1doBd:hover{
    background-color: #006f32;
    border: 2px solid #22b22d;
    padding: 5px;
    transition: all ease-in-out 0.3s;

}
.GameTable_WonBoardLastButton__1TebR{
    margin-right: 8em
}
.GameTable_WonBoardButton__1doBd span{
    font-size: 10px;
    line-height: 1.1em;
    font-weight: 600;
    padding-top: 2px;
}
.GameTable_WonBoardToolbar__3O1Iz label{
    font-size: 11px;
    color: #e2e2e2;
    margin: 0 10px;
    font-weight: 600;
    text-transform: uppercase;
}
.GameTable_WonBoardWrapper__1T6Ui{
    width: 100%;
    font-family: "LCD-Font", "IranSANS";
    font-size: 16px;
    border-radius: 10px;
    margin-top: 1em;
}
.GameTable_WonBoardFooter__2pqfY{
    border-top: 1px solid #717171;
}
.GameTable_WonBoardHeader__3gmBY th{
    border-bottom: 1px solid #717171;
    padding: 0.3em 0 0.3em 0;
    font-size: 13px;
}
.GameTable_WonBoardWrapper__1T6Ui table td{
    padding: 0.3em 0 0.3em 0;
}
.GameTable_OverlayWindow__2yX9W{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
}
.GameTable_OverlayLock__xrXaj{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
}
.GameTable_MobileOverlayLock__2lGmS{
    background-color: rgba(0,0,0,0.7);
}
.GameTable_MobileOverlayWrapper__1bL00{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.GameTable_MobileOverlayWrapper__1bL00 h5{
    font-size: 24px;
    text-align: center;
    line-height: 1.7em;
}
.GameTable_MobileOverlayTimer__3kxIM{
    text-align: center;
    font-size: 110px !important;
}
.GameTable_MobileTools__3cAia{
    display: none;
}
.GameTable_MobileIcons__2e4wZ{
    display: none;
}
.GameTable_MobileIcon__3Q4Tu{
    background: none;
    border: none;
}
.GameTable_WheelSvg__17239{
    height: 100%;
}
.GameTable_MobileGreenDisplayContainer__1oxbs,
.GameTable_MobileFloatingMenu__1RUc6{
    display: none;
}
.GameTable_AccountButtons__F4vK6{
    display: flex;
    justify-content: center;
    align-items: center;
}
.GameTable_DeviceNotSupport__3Qoi-{
    display: none;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.GameTable_DeviceNotSupport__3Qoi- .GameTable_Wrapper__zBeMG{
    width: 60%;
    text-align: center;
}
.GameTable_DeviceNotSupport__3Qoi- .GameTable_Wrapper__zBeMG h1{
    color: #fff900;
}
.GameTable_MobileNumberCounterWrapper__3VIJr{
    margin-top: 10px;
}
@media (min-width: 1660px) {
    .GameTable_NeighboursBar__j-R-f{
        right: -100px;
    }
}
.GameTable_DiamondCell__2aj4W{

}

@media (max-width: 720px) {
    .GameTable_VideoMonitor__1j6zf,
    .GameTable_Monitor__2QtYO,
    .GameTable_SubToolsContainer__29rLl,
    .GameTable_LeftGreenDisplayContainer__36VOi,
    .GameTable_WonBoard__3oXyd,
    .GameTable_AccountButtons__F4vK6{
        display: none;
    }
    .GameTable_MobileGreenDisplayContainer__1oxbs{
        display: block;
    }
    .GameTable_WheelContainer__1kiM4{
        width: 100%;
        position: relative;
    }
    .GameTable_MobileNumberCounterWrapper__3VIJr{
        margin-top: 40px !important;
    }
    .GameTable_MobileNumberCounterWrapper__3VIJr .counter-cell{
        margin: 0 0.5em 0em 0.5em;
    }
    .GameTable_WheelSvg__17239{
        width: 100%;
    }
    .GameTable_Container__37vPR{
        overflow: initial;
        padding: 0 20px 1em 20px;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
    }
    .GameTable_ToolsChipsContainer__3oTkS{
        width: 100%;
    }
    .GameTable_RightDisplayContainer__3UOeX{
        display: block;
    }
    .GameTable_TableContainer__7K6ZF{
        /*display: none;*/
        display: block;
    }
    .GameTable_LeftDisplay__EsL62{
        width: 100%;
    }
    .GameTable_RightDisplay__mOcJ9{
        width: 100%;
        padding: 0;
    }
    .GameTable_Table__3y0Wg{
        width: 100%;
        height: auto;
    }
    .GameTable_Table__3y0Wg table{
        height: calc(100vh - 230px);
    }

    .GameTable_TextSmall__3BhC-{
        font-size: 11px !important;
    }
    .GameTable_Table__3y0Wg table td{
        font-size: 13px;
    }
    .GameTable_Diamond__azLW0 {
        height: 40px;
    }
    .GameTable_CellChipButton__23a2e{
        width: 24px;
        height: 24px;
    }
    .GameTable_CellChipButton__23a2e img{
        width: 24px;
        height: 24px;
    }
    .GameTable_CellNumber__2wlrH{
        padding: 0.5em 0.5em;
    }
    .GameTable_LeftGreenDisplayLastNumber__1kyhW{
        display: none;
    }
    .GameTable_Chips__1jsTo{
        position: fixed;
        top: 0;
        right: -75px;
        background: #1b1b1b;
        z-index: 999999;
        height: 100%;
        display: flex;
        align-items: center;
        transition: right linear 0.3s;
    }
    .GameTable_ChipButton__19ZSw{
        width: 42px;
        height: 42px;
        margin: 1em;
    }
    .GameTable_ChipButton__19ZSw img{
        width: 32px;
        height: 32px;
    }
    .GameTable_LogoRightOfMonitor__2rSy5{
        height: auto;
        display: none;
    }
    .GameTable_LanguagesRightOfMonitor__3uoh2{
        display: none;
    }
    .GameTable_LogoRightOfMonitor__2rSy5 img{
        width: 110px;
    }

    .GameTable_WheelWrapper__3WBSe{
        position: fixed;
        width: 100%;
        top: 70px;
        left: -100%;
        background: rgba(0,0,0,0.7);
        z-index: 999999;
        height: 100%;
        display: flex;
        align-items: center;
        transition: left linear 0.3s;
    }
    .GameTable_OpenWheelWrapper__2oCBo{
        left: 0;
        transition: left linear 0.3s;
    }
    .GameTable_WheelButton__19Ijw{
        border-radius: 100%;
        background: #606060;
        color: #fff;
        width: 50px;
        height: 50px;
        font-size: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .GameTable_TopCellWrapper__3HtP6{
        top: -56px;
        left: 0;
        padding: 0 1.5em;
        position: fixed;
        width: 100%;
        background: #000;
        z-index: 99999988 !important;
        height: 55px;
        margin: 0;
        transition: top linear 0.3s;
    }
    .GameTable_OpenTopCellWrapper__3ZMPk{
        top: 0;
        transition: top linear 0.3s;
    }
    .GameTable_TopCellWrapper__3HtP6 table{
        height: 30px;
    }
    .GameTable_TopCellWrapper__3HtP6 table td{
        line-height: 30px;
        font-size: 13px;
        min-width: 32px;
        max-width: 32px;
    }
    .GameTable_MobileTools__3cAia{
        display: flex;
        width: 100%;
        height: 60px;
        margin-top: 1em;
        position: fixed;
        bottom: 0;
        left: 0;
        background: #222;
        z-index: 9999999;
    }
    .GameTable_MobileIcons__2e4wZ{
        display: table-cell;
    }
    .GameTable_MobileIcon__3Q4Tu{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
    }
    .GameTable_OpenMobileChips__1nHJj{
        right: 0;
        transition: right linear 0.3s;
    }
    .GameTable_Tools__1Tn9U{
        position: fixed;
        top: 0;
        right: -255px;
        max-width: 250px;
        height: 100%;
        background: #1b1b1b;
        z-index: 999999;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: right linear 0.3s;
    }
    .GameTable_OpenMobileTools__1kKcD{
        right: 0;
        transition: right linear 0.3s;
    }
    .GameTable_ToolsWrapper__22Cu-{
        padding: 0;
        width: 100%;
    }
    .GameTable_MobileButtonAppBar__1QK0N{
        width: 100%;
        height: 60px;
        flex: 1 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        color: #fff;
        font-size: 21px
    }
    .GameTable_MobileButtonAppBarActive__bJ0sf{
        background: #1b1b1b;
    }
    .GameTable_MobileButtonAppBarDisabled__yEUvY{
        color: #4e4e4e;
    }
    .GameTable_MobileButtonAppBar__1QK0N:focus{
        outline: 0;
        border: 0;
    }
    .GameTable_MobileFloatingMenu__1RUc6{
        width: 250px;
        height: calc(100% - 60px);
        position: fixed;
        bottom: 60px;
        right: -255px;
        z-index: 9999998;
        background: #1b1b1b;
        display: flex;
        align-items: flex-start;
        transition: right linear 0.3s;
    }
    .GameTable_OpenMobileFloatingMenu__r9qqQ{
        right: 0px;
        transition: right linear 0.3s;
    }
    .GameTable_MobileVolumeButton__3zLJP{
        position: absolute;
        background: #333;
        border: none;
        width: 32px;
        height: 32px;
        left: 10px;
        bottom: 10px;
        border-radius: 100%;
    }
    .GameTable_MobileFloatingMenuWrapper__1QyCA{
        height: -moz-fit-content;
        height: fit-content;
        width: 100%;
    }
    .GameTable_MobileFloatingMenuWrapper__1QyCA ul{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .GameTable_MobileFloatingMenuWrapper__1QyCA ul li{
        text-align: center;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
    }
    .GameTable_MobileFloatingMenuWrapper__1QyCA ul li a{
        color: white;
        padding: 0.7em 0;
        display: block;
        font-weight: 600;
        font-size: 13px;
    }
    .GameTable_MobileFloatingMenuWrapper__1QyCA ul li a:focus{
        background: #333;
    }
    .GameTable_MobileButtonAppBar__1QK0N span{
        font-size: 11px;
        font-weight: bold;
        line-height: 1;
    }
    .GameTable_MobileFloatingMenu__1RUc6 .GameTable_MobileButtonAppBar__1QK0N{
        width: 46px;
        height: 46px;
        background: #333333;
        border-radius: 100%;
        margin: 0.5em auto;
    }
    .GameTable_OverlayLock__xrXaj{
        position: fixed;
    }
    .GameTable_NumberCounterWrapper__12jGQ{
        margin-top: 10px;
    }
    .GameTable_MobileFloatingMenuLogo__34tOX{
        text-align: center;
        display: block;
        width: 100%;
        margin: 1em 0 1em 0;
    }
    .GameTable_ToolsButton__2nYdB{
        background: none;
        border: none;
        height: auto;
        margin: 0;
        padding: 0;
        border-radius: 0;
    }
    .GameTable_ToolsButton__2nYdB:focus{
        background: #333;
    }
    .GameTable_ToolsButtonWrapper__c2SWn{
        background: none;
        height: auto;
        font-style: initial;
        color: white;
        padding: 0.9em 0;
        display: block;
        font-weight: 600;
        font-size: 14px;
        border-radius: 0;
    }
    .GameTable_StatsWrapper__3AGAv{
        position: fixed;
        bottom: 60px;
        right: -100%;
        width: 100%;
        background: #000000;
        z-index: 999999;
        padding: 2em 1em;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: right linear 0.3s;
    }
    .GameTable_Top5BoardWrapper__27xUw{

    }
    .GameTable_WonBoardWrapper__1T6Ui{
        position: fixed;
        top: 73px;
        right: -100%;
        width: 100%;
        background: #000000;
        z-index: 999999;
        padding: 1em 1em 2em 1em;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: right linear 0.3s;
        margin-top: 0;
        font-size: 16px;
        border-radius: 0;
        min-height: 30%;
    }
    .GameTable_VideoStreamWrapper__zBIfN{
        position: fixed;
        top: 73px;
        right: -200%;
        width: 100%;
        height: calc(100% - 130px);
        background: #000000;
        z-index: 9999999999;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: right linear 0.3s;
        margin-top: 0;
        font-size: 16px;
        border-radius: 0;
        min-height: 30%;
    }
    .GameTable_VideoPlayer__3z4up{

        height: 100% !important;
        overflow: visible !important;
        transform: scale(1.1);

    }
    .GameTable_VideoPlayer__3z4up video{
        width: 800px !important;
    }
    .GameTable_WonBoardHeader__3gmBY th{
        font-size: 12px;
    }
    .GameTable_OpenStatsWrapper__2Syxu,
    .GameTable_OpenWonBoardWrapper__QzgjW,
    .GameTable_OpenTop5BoardWrapper__P5qnQ,
    .GameTable_OpenVideoStreamWrapper__3U9BT{
        right: 0px;
        transition: right linear 0.3s;
    }
    .GameTable_StatsTable__4hQY3{
        font-size: 11px;
    }
    .GameTable_StatsTable__4hQY3 th {
        font-size: 10px;
    }
    .GameTable_TopCellTable__TeU6v{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .GameTable_TopCellTable__TeU6v div{
        width: 26px;
        height: 26px;
        border-radius: 100%;
        border: 2px solid #cfcfcf;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px;
    }
    .GameTable_TopCellTable__TeU6v svg{
        margin: 2px 5px;
    }
    .GameTable_TopCellTable__TeU6v .GameTable_LastNumber__3qsjk{
        border-radius: 0px;
        margin-right: 10px;
        border: 1px solid #cfcfcf;
    }
    .GameTable_WheelWrapperContainer__yvmQc{
        width: 100%;
        height: 100%;
        position: relative;
    }
    .GameTable_NeighboursBar__j-R-f{
        position: absolute;
        right: 0;
        bottom: 20px;
        z-index: 9999999;
        width: 100%;
    }
    .GameTable_NeighboursBarWrapper__3minz{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .GameTable_NeighbourButton__OZGzC{
        border-radius: 100%;
        border-width: 2px;
        margin: 0 5px;
        font-size: 14px;
        font-weight: 500;
        max-width: 45px;
        max-height: 45px;
        min-width: 45px;
        min-height: 45px;
    }
    .GameTable_NeighboursBar__j-R-f label{
        display: none;
        text-align: center;
        margin-top: 5px;
        font-size: 11px;
        font-weight: 400;
    }
    .GameTable_AccountPageContainer__1AN4e{
        position: fixed;
        top: 0px;
        bottom: 60px;
        left: -100%;
        height: 100%;
        width: 100%;
        background: #000000;
        z-index: 99999999;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: left linear 0.1s;
        margin-top: 0;
        font-size: 16px;
        border-radius: 0;
        min-height: 30%;
    }
    .GameTable_OpenAccountPageContainer__2fUXJ{
        left: 0px;
        transition: left linear 0.1s;
    }
    .GameTable_AccountPageContainer__1AN4e iframe{
        width: 100%;
        height: calc(100% - 60px);
        display: none;
    }
}
@media (max-width: 720px) and (max-height: 700px){
    .GameTable_MobileFloatingMenu__1RUc6 .mt-4{
        margin-top: 1em !important;
    }
    .GameTable_MobileFloatingMenu__1RUc6 .mb-5{
        margin-bottom: 1.1em !important;
    }

    .GameTable_Table__3y0Wg table td{
        font-size: 12px;
    }
}
.GameTable_Timer__iMQ6k{
    font-family: "LCD";
    font-size: 25px;
    color: #3dc733;
    transition: all ease-in-out;
}
@media (min-width: 721px) and (max-width: 1023px) and (max-height: 665px){
    .GameTable_Container__37vPR{
        display: none;
    }
    .GameTable_DeviceNotSupport__3Qoi-{
        display: flex;

    }
}
@media (min-width: 721px) and (max-width: 1023px) and (min-height: 666px){
    .GameTable_VideoMonitor__1j6zf,
    .GameTable_Monitor__2QtYO,
    .GameTable_SubToolsContainer__29rLl,
    .GameTable_LeftGreenDisplayContainer__36VOi,
    .GameTable_WonBoard__3oXyd,
    .GameTable_AccountButtons__F4vK6{
        display: none;
    }
    .GameTable_MobileGreenDisplayContainer__1oxbs{
        display: block;
    }
    .GameTable_WheelContainer__1kiM4{
        width: 100%;
        position: relative;
    }
    .GameTable_WheelSvg__17239{
        width: 100%;
    }
    .GameTable_Container__37vPR{
        overflow: initial;
        padding: 0 20px 1em 20px;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
    }
    .GameTable_ToolsChipsContainer__3oTkS{
        width: 100%;
    }
    .GameTable_RightDisplayContainer__3UOeX{
        display: block;
    }
    .GameTable_TableContainer__7K6ZF{
        /*display: none;*/
        display: block;
    }
    .GameTable_LeftDisplay__EsL62{
        width: 100%;
    }
    .GameTable_RightDisplay__mOcJ9{
        width: 100%;
        padding: 0;
    }
    .GameTable_Table__3y0Wg{
        width: 100%;
        height: auto;
    }
    .GameTable_OverlayWindow__2yX9W{
        top: 100px;
    }
    .GameTable_Table__3y0Wg table{
        height: calc(100vh - 230px);
    }
    .GameTable_TextSmall__3BhC-{
        font-size: 11px !important;
    }
    .GameTable_Table__3y0Wg table td{
        font-size: 13px;
    }
    .GameTable_Diamond__azLW0 {
        height: 40px;
    }
    .GameTable_CellChipButton__23a2e{
        width: 24px;
        height: 24px;
    }
    .GameTable_CellChipButton__23a2e img{
        width: 24px;
        height: 24px;
    }
    .GameTable_CellNumber__2wlrH{
        padding: 0.5em 0.5em;
    }
    .GameTable_LeftGreenDisplayLastNumber__1kyhW{
        display: none;
    }
    .GameTable_Chips__1jsTo{
        position: fixed;
        top: 0;
        right: -75px;
        background: #1b1b1b;
        z-index: 999999;
        height: 100%;
        display: flex;
        align-items: center;
        transition: right linear 0.3s;
    }
    .GameTable_ChipButton__19ZSw{
        width: 42px;
        height: 42px;
        margin: 1em;
    }
    .GameTable_ChipButton__19ZSw img{
        width: 32px;
        height: 32px;
    }
    .GameTable_LogoRightOfMonitor__2rSy5{
        height: auto;
        display: none;
    }
    .GameTable_LanguagesRightOfMonitor__3uoh2{
        display: none;
    }
    .GameTable_LogoRightOfMonitor__2rSy5 img{
        width: 110px;
    }

    .GameTable_WheelWrapper__3WBSe{
        position: fixed;
        width: 100%;
        top: 100px;
        left: -100%;
        background: rgba(0,0,0,0.7);
        z-index: 999999;
        height: 100%;
        display: flex;
        align-items: center;
        transition: left linear 0.3s;
    }
    .GameTable_OpenWheelWrapper__2oCBo{
        left: 0;
        transition: left linear 0.3s;
    }
    .GameTable_WheelButton__19Ijw{
        border-radius: 100%;
        background: #606060;
        color: #fff;
        width: 50px;
        height: 50px;
        font-size: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .GameTable_TopCellWrapper__3HtP6{
        top: -56px;
        left: 0;
        padding: 0 1.5em;
        position: fixed;
        width: 100%;
        background: #000;
        z-index: 99999988 !important;
        height: 55px;
        margin: 0;
        transition: top linear 0.3s;
    }
    .GameTable_OpenTopCellWrapper__3ZMPk{
        top: 0;
        transition: top linear 0.3s;
    }
    .GameTable_TopCellWrapper__3HtP6 table{
        height: 30px;
    }
    .GameTable_TopCellWrapper__3HtP6 table td{
        line-height: 30px;
        font-size: 13px;
        min-width: 32px;
        max-width: 32px;
    }
    .GameTable_MobileTools__3cAia{
        display: flex;
        width: 100%;
        height: 60px;
        margin-top: 1em;
        position: fixed;
        bottom: 0;
        left: 0;
        background: #333;
        z-index: 9999999;
    }
    .GameTable_MobileIcons__2e4wZ{
        display: table-cell;
    }
    .GameTable_MobileIcon__3Q4Tu{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
    }
    .GameTable_OpenMobileChips__1nHJj{
        right: 0;
        transition: right linear 0.3s;
    }
    .GameTable_Tools__1Tn9U{
        position: fixed;
        top: 0;
        right: -255px;
        max-width: 250px;
        height: 100%;
        background: #1b1b1b;
        z-index: 999999;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: right linear 0.3s;
    }
    .GameTable_OpenMobileTools__1kKcD{
        right: 0;
        transition: right linear 0.3s;
    }
    .GameTable_ToolsWrapper__22Cu-{
        padding: 0;
        width: 100%;
    }
    .GameTable_MobileButtonAppBar__1QK0N{
        width: 100%;
        height: 60px;
        flex: 1 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        color: #fff;
        font-size: 21px
    }
    .GameTable_MobileButtonAppBarActive__bJ0sf{
        background: #1b1b1b;
    }
    .GameTable_MobileButtonAppBarDisabled__yEUvY{
        color: #4e4e4e;
    }
    .GameTable_MobileButtonAppBar__1QK0N:focus{
        outline: 0;
        border: 0;
    }
    .GameTable_MobileFloatingMenu__1RUc6{
        width: 250px;
        height: calc(100% - 60px);
        position: fixed;
        bottom: 60px;
        right: -255px;
        z-index: 9999998;
        background: #1b1b1b;
        display: flex;
        align-items: flex-start;
        transition: right linear 0.3s;
    }
    .GameTable_OpenMobileFloatingMenu__r9qqQ{
        right: 0px;
        transition: right linear 0.3s;
    }
    .GameTable_MobileVolumeButton__3zLJP{
        position: absolute;
        background: #333;
        border: none;
        width: 40px;
        height: 40px;
        left: 10px;
        bottom: 10px;
        border-radius: 100%;
    }
    .GameTable_MobileFloatingMenuWrapper__1QyCA{
        height: -moz-fit-content;
        height: fit-content;
        width: 100%;
    }
    .GameTable_MobileFloatingMenuWrapper__1QyCA ul{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .GameTable_MobileFloatingMenuWrapper__1QyCA ul li{
        text-align: center;
        text-transform: uppercase;
    }

    .GameTable_MobileFloatingMenuWrapper__1QyCA ul li a{
        color: white;
        padding: 0.6em 0;
        display: block;
        font-weight: 600;
        font-size: 14px;
    }
    .GameTable_MobileFloatingMenuWrapper__1QyCA ul li a:focus{
        background: #333;
    }
    .GameTable_MobileButtonAppBar__1QK0N span{
        font-size: 11px;
        font-weight: bold;
        line-height: 1;
    }
    .GameTable_MobileFloatingMenu__1RUc6 .GameTable_MobileButtonAppBar__1QK0N{
        width: 46px;
        height: 46px;
        background: #333333;
        border-radius: 100%;
        margin: 0.5em auto;
    }
    .GameTable_OverlayLock__xrXaj{
        position: fixed;
    }
    .GameTable_NumberCounterWrapper__12jGQ{
        margin-top: 10px;
    }
    .GameTable_MobileFloatingMenuLogo__34tOX{
        text-align: center;
        display: block;
        width: 100%;
        margin: 1em 0 1em 0;
    }
    .GameTable_ToolsButton__2nYdB{
        background: none;
        border: none;
        height: auto;
        margin: 0;
        padding: 0;
        border-radius: 0;
    }
    .GameTable_ToolsButton__2nYdB:focus{
        background: #333;
    }
    .GameTable_ToolsButtonWrapper__c2SWn{
        background: none;
        height: auto;
        font-style: initial;
        color: white;
        padding: 0.9em 0;
        display: block;
        font-weight: 600;
        font-size: 14px;
        border-radius: 0;
    }
    .GameTable_StatsWrapper__3AGAv{
        position: fixed;
        bottom: 60px;
        right: -100%;
        width: 100%;
        background: #000000;
        z-index: 999999;
        padding: 2em 1em;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: right linear 0.3s;
    }
    .GameTable_Top5BoardWrapper__27xUw{

    }
    .GameTable_WonBoardWrapper__1T6Ui{
        position: fixed;
        top: 100px;
        right: -100%;
        width: 100%;
        background: #000000;
        z-index: 999999;
        padding: 1em 1em 2em 1em;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: right linear 0.3s;
        margin-top: 0;
        font-size: 16px;
        border-radius: 0;
        min-height: 30%;
    }
    .GameTable_WonBoardHeader__3gmBY th{
        font-size: 12px;
    }
    .GameTable_OpenStatsWrapper__2Syxu,
    .GameTable_OpenWonBoardWrapper__QzgjW,
    .GameTable_OpenTop5BoardWrapper__P5qnQ{
        right: 0px;
        transition: right linear 0.3s;
    }
    .GameTable_StatsTable__4hQY3{
        font-size: 11px;
    }
    .GameTable_StatsTable__4hQY3 th {
        font-size: 10px;
    }
    .GameTable_TopCellTable__TeU6v{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .GameTable_TopCellTable__TeU6v div{
        width: 26px;
        height: 26px;
        border-radius: 100%;
        border: 2px solid #cfcfcf;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px;
    }
    .GameTable_TopCellTable__TeU6v svg{
        margin: 2px 5px;
    }
    .GameTable_TopCellTable__TeU6v .GameTable_LastNumber__3qsjk{
        border-radius: 0px;
        margin-right: 10px;
        border: 1px solid #cfcfcf;
    }
}
@media (min-width: 1200px) and (max-width: 1408px){
    .GameTable_NeighboursBar__j-R-f{
        right: -64px;
    }
}

@media (min-width: 1024px) and (max-width: 1199px){
    .GameTable_LeftDisplay__EsL62{
        width: 45%;
    }
    .GameTable_RightDisplay__mOcJ9{
        width: 55%;
    }
    .GameTable_WonBoardLastButton__1TebR{
        margin-right: 0;
    }
    .GameTable_CellNumber__2wlrH{
        padding: 0.5em 0.3em;
    }
    .GameTable_FullTable__3kzM7 .GameTable_CellNumber__2wlrH{
        padding: 0.4em 0.3em;
    }
    .GameTable_Table__3y0Wg table td{
        font-size: 14px;
    }
    .GameTable_TextSmall__3BhC-{
        font-size: 12px !important;
        padding: 1em 0.3em !important;
    }
    .GameTable_CircleButton__3vOz2{
        width: 73px;
        height: 73px;
        margin: 0 10px;
    }
    .GameTable_CircleButtonWrapper__3g0aB{
        height: 60px;
        width: 60px;
        min-width: 60px;
        font-size: 11px;
    }
    .GameTable_Monitor__2QtYO{
        font-size: 18px;
    }
    .GameTable_Tools__1Tn9U{
        width: 100%;
    }
    .GameTable_WheelContainer__1kiM4{
        width: 60%;
    }
    .GameTable_WonBoard__3oXyd{
        width: 40%;
    }
    .GameTable_WonBoardWrapper__1T6Ui{
        font-size: 14px;
        margin-top: 90px;
    }
    .GameTable_ChipButton__19ZSw{
        width: 45px;
        height: 45px;
        margin: 0.6em 1.3em;
    }
    .GameTable_ChipButton__19ZSw img{
        width: 35px;
        height: 35px;
    }
    .GameTable_CircleButton__3vOz2{
        width: 63px;
        height: 63px;
        margin: 5px 10px;
    }
    .GameTable_CircleButtonWrapper__3g0aB{
        height: 50px;
        width: 50px;
        min-width: 50px;
        font-size: 11px;
    }
    .GameTable_ToolsButton__2nYdB{
        height: 32px;
        margin-bottom: 7px;
    }
    .GameTable_CircleGrayButton__Cs9Qs{
        height: 32px;
        width: 32px;
        max-height: 32px;
        max-width: 32px;
        min-height: 32px;
        min-width: 32px;
    }
    .GameTable_Diamond__azLW0{
        height: 45px;
    }
    .GameTable_ToolsButtonWrapper__c2SWn{
        font-size: 10px;
        height: 25px;
    }
    .GameTable_SubToolsContainer__29rLl{
        width: 60%;
        margin: 0.5em auto 0.5em auto;
    }
    .GameTable_ToolsWrapper__22Cu-{
        padding-top: 40px;
    }
    .GameTable_WonContainer__OOsqi{
        position: relative;
    }
    .GameTable_NeighboursBar__j-R-f{
        right: 0px;
    }
    .GameTable_WheelWrapper__3WBSe{
        margin-top: 70px;
    }
    .GameTable_StatsBarItems__2uzBC{
        width: 20px;
    }
    .GameTable_StatsBarItems__2uzBC li{
        font-size: 9px;
        height: 27px;
    }
    .GameTable_StatsTable__4hQY3 th{
        font-size: 7px;
    }
    .GameTable_StatsTable__4hQY3 td{
        font-size: 9px;
        padding: 0.8em 0.8em;
    }
    .GameTable_WonBoardHeader__3gmBY th{
        font-size: 9px;
    }
    .GameTable_WonBoardWrapper__1T6Ui td{
        font-size: 10px;
    }
    .GameTable_WonBoardToolbar__3O1Iz{
        position: absolute;
        top: 0;
        left: 0px;
        width: 100%;
    }
}
@media (min-width: 1200px) and (max-width: 1299px) {

    .GameTable_WonBoardLastButton__1TebR{
        margin-right: 0;
    }
    .GameTable_CellNumber__2wlrH{
        padding: 0.5em 0.3em;
    }
    .GameTable_FullTable__3kzM7 .GameTable_CellNumber__2wlrH{
        padding: 0.4em 0.3em;
    }
    .GameTable_Table__3y0Wg table td{
        font-size: 14px;
    }
    .GameTable_TextSmall__3BhC-{
        font-size: 12px !important;
        padding: 1em 0.3em !important;
    }
    .GameTable_CircleButton__3vOz2{
        width: 73px;
        height: 73px;
        margin: 0 10px;
    }
    .GameTable_CircleButtonWrapper__3g0aB{
        height: 60px;
        width: 60px;
        min-width: 60px;
        font-size: 11px;
    }
    .GameTable_Monitor__2QtYO{
        font-size: 18px;
    }
    .GameTable_Tools__1Tn9U{
        width: 100%;
    }
    .GameTable_WheelContainer__1kiM4{
        width: 40%;
    }
    .GameTable_WonBoard__3oXyd{
        width: 60%;
    }
    .GameTable_WonBoardWrapper__1T6Ui{
        font-size: 14px;
    }
    .GameTable_ChipButton__19ZSw{
        width: 45px;
        height: 45px;
        margin: 0.6em 1.3em;
    }
    .GameTable_ChipButton__19ZSw img{
        width: 35px;
        height: 35px;
    }
    .GameTable_CircleButton__3vOz2{
        width: 63px;
        height: 63px;
        margin: 5px 10px;
    }
    .GameTable_CircleButtonWrapper__3g0aB{
        height: 50px;
        width: 50px;
        min-width: 50px;
        font-size: 11px;
    }
    .GameTable_ToolsButton__2nYdB{
        height: 32px;
        margin-bottom: 7px;
    }
    .GameTable_CircleGrayButton__Cs9Qs{
        height: 32px;
        width: 32px;
        max-height: 32px;
        max-width: 32px;
        min-height: 32px;
        min-width: 32px;
    }
    .GameTable_Diamond__azLW0{
        height: 45px;
    }
    .GameTable_ToolsButtonWrapper__c2SWn{
        font-size: 10px;
        height: 25px;
    }
    .GameTable_SubToolsContainer__29rLl{
        width: 60%;
        margin: 0.5em auto 0.5em auto;
    }
    .GameTable_ToolsWrapper__22Cu-{
        padding-top: 40px;
    }

}
@media (min-width: 1300px) and (max-width: 1399px) {

    .GameTable_WonBoardLastButton__1TebR{
        margin-right: 0;
    }
    .GameTable_CellNumber__2wlrH{
        padding: 0.4em 0.3em;
    }
    .GameTable_FullTable__3kzM7 .GameTable_CellNumber__2wlrH{
        padding: 0.3em 0.3em;
    }
    .GameTable_SubToolsContainer__29rLl{
        width: 100%;
        margin: 1em;
    }
    .GameTable_LeftDisplay__EsL62{
        width: 50%;
    }
    .GameTable_RightDisplay__mOcJ9{
        width: 50%;
    }
    .GameTable_Table__3y0Wg table td{
        font-size: 15px;
    }
    .GameTable_WheelContainer__1kiM4{
        width: 38%;
    }
    .GameTable_WonBoard__3oXyd{
        width: 62%;
    }
    .GameTable_ChipButton__19ZSw{
        width: 50px;
        height: 50px;
        margin: 0.5em 2em;
    }
    .GameTable_ChipButton__19ZSw img{
        width: 40px;
        height: 40px;
    }
    .GameTable_TextSmall__3BhC-{
        font-size: 14px !important;
        padding: 1em 0.3em !important;
    }
    .GameTable_CircleButton__3vOz2{
        width: 73px;
        height: 73px;
        margin: 5px 10px;
    }
    .GameTable_CircleButtonWrapper__3g0aB{
        height: 60px;
        width: 60px;
        min-width: 60px;
        font-size: 11px;
    }
    .GameTable_Monitor__2QtYO{
        font-size: 19px;
    }
    .GameTable_Tools__1Tn9U{
        width: 100%;
    }
    .GameTable_ToolsButton__2nYdB{
        height: 34px;
        margin-bottom: 5px;
    }
    .GameTable_CircleGrayButton__Cs9Qs{
        height: 34px;
        width: 34px;
        max-height: 34px;
        max-width: 34px;
        min-height: 34px;
        min-width: 34px;
    }
    .GameTable_ToolsButtonWrapper__c2SWn{
        height: 25px;
        font-size: 10px;
    }
    .GameTable_SubToolsContainer__29rLl{
        width: 65%;
        margin: 0 auto 0em auto;
    }
    .GameTable_ToolsWrapper__22Cu-{
        padding-top: 40px;
    }
}
@media (min-width: 1400px) and (max-width: 1499px) {

    .GameTable_WonBoardLastButton__1TebR{
        margin-right: 0;
    }
    .GameTable_LeftDisplay__EsL62{
        width: 52%;

    }
    .GameTable_LeftGreenDisplay__2CtPL{
        width: 48%;
    }
    .GameTable_WheelContainer__1kiM4{
        width: 45%;
    }
    .GameTable_WonBoard__3oXyd{
        width: 55%;
    }
    .GameTable_CellNumber__2wlrH{
        padding: 0.61em 0.3em;
    }
    .GameTable_FullTable__3kzM7 .GameTable_CellNumber__2wlrH{
        padding: 0.51em 0.3em;
    }
    .GameTable_Table__3y0Wg table td{
        font-size: 17px;
    }
    .GameTable_TextSmall__3BhC-{
        font-size: 14px !important;
        padding: 1.1em 0.3em !important;
    }
    .GameTable_ChipButton__19ZSw{
        width: 55px;
        height: 55px;
        margin: 0.7em 2em;
    }
    .GameTable_ChipButton__19ZSw img{
        width: 45px;
        height: 45px;
    }
    .GameTable_CircleButton__3vOz2{
        width: 80px;
        height: 80px;
        margin: 0 10px;
    }
    .GameTable_CircleButtonWrapper__3g0aB{
        height: 70px;
        min-width: 70px;
        width: 70px;
    }
    .GameTable_Monitor__2QtYO{
        font-size: 21px;
    }
    .GameTable_SubToolsContainer__29rLl{
        width: 65%;
        margin: 1em auto 1em auto;
    }
    .GameTable_ToolsWrapper__22Cu-{
        padding-top: 50px;
    }
    .GameTable_ToolsButtonWrapper__c2SWn{
        height: 31px;
        font-size: 11px;
    }
    .GameTable_ToolsButton__2nYdB{
        margin-bottom: 6px;
        height: 40px;
    }
    .GameTable_CircleGrayButton__Cs9Qs{
        height: 40px;
        width: 40px;
        max-height: 40px;
        max-width: 40px;
        min-height: 40px;
        min-width: 40px;
    }
}
@media (min-width: 1500px) and (max-width: 1600px) {
    .GameTable_NeighboursBar__j-R-f label{
        display: inline-block;
    }
    .GameTable_WonBoardLastButton__1TebR{
        margin-right: 0;
    }
    .GameTable_LeftDisplay__EsL62{
        width: 52%;

    }
    .GameTable_LeftGreenDisplay__2CtPL{
        width: 48%;
    }
    .GameTable_WheelContainer__1kiM4{
        width: 35%;
    }
    .GameTable_WonBoard__3oXyd{
        width: 65%;
    }
    .GameTable_CellNumber__2wlrH{
        padding: 0.61em 0.3em;
    }
    .GameTable_FullTable__3kzM7 .GameTable_CellNumber__2wlrH{
        padding: 0.41em 0.3em;
    }
    .GameTable_Table__3y0Wg table td{
        font-size: 17px;
    }
    .GameTable_TextSmall__3BhC-{
        font-size: 16px !important;
        padding: 1em 0.3em !important;
    }
    .GameTable_ChipButton__19ZSw{
        width: 55px;
        height: 55px;
        margin: 0.7em 2em;
    }
    .GameTable_ChipButton__19ZSw img{
        width: 45px;
        height: 45px;
    }
    .GameTable_CircleButton__3vOz2{
        width: 80px;
        height: 80px;
        margin: 0 10px;
    }
    .GameTable_CircleButtonWrapper__3g0aB{
        height: 70px;
        min-width: 70px;
        width: 70px;
    }
    .GameTable_Monitor__2QtYO{
        font-size: 21px;
    }
    .GameTable_SubToolsContainer__29rLl{
        width: 65%;
        margin: 1em auto 1em auto;
    }
    .GameTable_ToolsWrapper__22Cu-{
        padding-top: 50px;
    }
    .GameTable_ToolsButtonWrapper__c2SWn{
        height: 31px;
        font-size: 11px;
    }
    .GameTable_ToolsButton__2nYdB{
        margin-bottom: 6px;
        height: 40px;
    }
    .GameTable_CircleGrayButton__Cs9Qs{
        height: 40px;
        width: 40px;
        max-height: 40px;
        max-width: 40px;
        min-height: 40px;
        min-width: 40px;
    }
}

@media (min-width: 1601px) and (max-width: 1700px) {
    .GameTable_NeighboursBar__j-R-f label{
        display: inline-block;
    }
    .GameTable_WonBoardLastButton__1TebR{
        margin-right: 0;
    }
    .GameTable_WheelContainer__1kiM4{
        width: 45%;
    }
    .GameTable_WonBoard__3oXyd{
        width: 55%;
    }
    .GameTable_CellNumber__2wlrH{
        padding: 0.77em 0.3em;
    }
    .GameTable_FullTable__3kzM7 .GameTable_CellNumber__2wlrH{
        padding: 0.51em 0.3em;
    }
    .GameTable_Table__3y0Wg table td{
        font-size: 19px;
    }
    .GameTable_ChipButton__19ZSw{
        width: 67px;
        height: 67px;

    }
    .GameTable_ChipButton__19ZSw img{
        width: 57px;
        height: 57px;
    }
    .GameTable_TextSmall__3BhC-{
        font-size: 16px !important;
        padding: 1em 0.3em !important;
    }
    .GameTable_CircleButton__3vOz2{
        width: 90px;
        height: 90px;
    }
    .GameTable_CircleButtonWrapper__3g0aB{
        height: 80px;
        min-width: 80px;
        font-size: 14px;
        width: 80px;
    }
    .GameTable_Monitor__2QtYO{
        font-size: 21px;
    }
    .GameTable_SubToolsContainer__29rLl{
        width: 65%;
        margin: 1em auto 1em auto;
    }
    .GameTable_ToolsButtonWrapper__c2SWn{
        height: 41px;
        font-size: 14px;
    }
    .GameTable_ToolsButton__2nYdB{
        margin-bottom: 10px;
        height: 50px;
    }
    .GameTable_CircleGrayButton__Cs9Qs{
        height: 50px;
        width: 50px;
        max-height: 50px;
        max-width: 50px;
        min-height: 50px;
        min-width: 50px;
    }
}

@media (min-width: 1900px) and (max-width: 1999px) {
    .GameTable_NeighboursBar__j-R-f label{
        display: inline-block;
    }
    .GameTable_WheelContainer__1kiM4{
        width: 40%;
    }
    .GameTable_CellNumber__2wlrH{
        padding: 0.55em 0.3em;
    }
    .GameTable_FullTable__3kzM7 .GameTable_CellNumber__2wlrH{
        padding: 0.4em 0.3em;
    }
    .GameTable_WonBoard__3oXyd{
        width: 60%;
    }
}
@media (min-width: 1900px){
    .GameTable_NeighboursBar__j-R-f label{
        display: inline-block;
    }
}
.GameTable_HighLightWonCells__29OuQ{
    /*background: rgb(137 255 0 / 20%);*/
    color: #fff;
    text-shadow:
            0 0 3px #0fa,
            0 0 5px #0fa,
            0 0 8px #0fa,
            0 0 42px #0fa,
            0 0 82px #0fa,
            0 0 92px #0fa,
            0 0 102px #0fa,
            0 0 151px #0fa;
}
.GameTable_HighLightWonBorder__1vriX{
    z-index: 999999;
}
.GameTable_HighLightWonBorder__1vriX .GameTable_VerticalLine__2Jjxr, .GameTable_HighLightWonBorder__1vriX .GameTable_HorizontalLine__22rSv{
    box-shadow: 0 0 7px #30ff41, 0 0 5px #30ff41;
    background: #30ff41;
}
.GameTable_HighLightHoverBorder__3R3lo{
    /*z-index: 999999;*/
}
.GameTable_HighLightHoverBorder__3R3lo .GameTable_VerticalLine__2Jjxr,.GameTable_HighLightWonBorder__1vriX .GameTable_VerticalLine__2Jjxr{
    width: 2px;
}
.GameTable_HighLightHoverBorder__3R3lo .GameTable_HorizontalLine__22rSv.GameTable_HighLightWonBorder__1vriX .GameTable_VerticalLine__2Jjxr{
    height: 1px;
}
.GameTable_HighLightHoverBorder__3R3lo .GameTable_VerticalLine__2Jjxr{
    box-shadow: 0 0 7px #e4e4e4, 0 0 5px #e4e4e4;
    background: #e4e4e4;
    transition: all ease-in-out 0.2s;
}
.GameTable_HighLightHoverBorder__3R3lo .GameTable_HorizontalLine__22rSv{
    box-shadow: 0 0 7px #e4e4e4, 0 0 5px #e4e4e4;
    background: #e4e4e4;
    transition: all ease-in-out 0.2s;
}
.GameTable_ContextMenuOverlay__3iFJ-{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
    background: rgba(0,0,0,0.1);
}
.GameTable_ContextMenu__30j9A{
    min-width: 250px;
    max-width: 250px;
    background: #333;
    position: absolute;
    z-index: 999999999;
    left: 50%;
    top: 50%;
    box-shadow: 0 0 3px #333, 0 0 3px #333;
    border-radius: 4px;
}
.GameTable_LeftContextMenu__3Kf1q{
    right: 50%;
    left: auto;
}
.GameTable_RightContextMenu__1SqcZ{
    right: 10%;
    left: auto;
}
.GameTable_ContextMenu__30j9A ul{
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
}
.GameTable_ContextMenu__30j9A ul li{
    padding: 0.5em 0.8em;
    border-bottom: 1px solid #444;
    transition: all ease-in-out 0.2s;
    font-size: 0.9em;
    color: #fff;

}
.GameTable_ContextMenu__30j9A ul li:hover{
    background: #444;
    transition: all ease-in-out 0.2s;
}
.GameTable_ContextMenu__30j9A ul li:last-child{
    border-bottom: 0;
}

.GameTable_LoadingContainer__2FIIt{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
@media (max-width: 720px) {
    .GameTable_LeftGreenDisplayContainer__36VOi{
        height: auto;
    }
}
@media (min-width: 1200px) {
    .GameTable_FullTable__3kzM7 table{
        width: 70%;
    }

}
@media (max-height: 730px) {
    .GameTable_Container__37vPR{
        overflow-y: scroll !important;
        max-height: initial !important;
    }
    .GameTable_WonBoardToolbar__3O1Iz{
        position: absolute;
        top: 0px;
        width: 100%;
    }
}
@media (max-width: 400px) {
    .GameTable_MobileButtonAppBar__1QK0N{
        font-size: 15px !important;
    }
    .GameTable_Table__3y0Wg table td.GameTable_Vertical__3oplF{
        font-size: 10px !important;
        white-space: break-spaces !important;
        padding: 2.7em 0;
    }
    .GameTable_DiamondCell__2aj4W{
        padding: 1.5em 0 !important;
    }
}
.MenuLabel_CountHolder__1X7hF{
    color: #f44336 !important;
    font-weight: 500;
}
.MenuLabel_SmallText__2QDRe{
    font-size: 0.95rem !important;
}

.Popup_PopupOverlay__3KTJ0{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.Popup_ForcePopup__kx9zt{
    z-index: 999999999;
}
.Popup_PopupContainer__2CCIh{
    background: #333;
    border-radius: 4px;
    box-shadow: 0 0 7px #101010, 0 0 10px #101010;
    display: table;
}
.Popup_Dark__1oiYB{
    background: #000;
}
.Popup_Dark__1oiYB .Popup_PopupHeader__2Sr-1{
    border-color: #000 !important;
}
.Popup_Dark__1oiYB .Popup_PopupFooter__3tyrN{
    border-color: #000 !important;
}
.Popup_SmallPopup__2GS0I{
    min-width: 33%;
    max-width: 33%;
}
.Popup_LargePopup__2vpIp{
    min-width: 40%;
    max-width: 40%;
}
.Popup_PopupHeader__2Sr-1{
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #404040;
    display: flex;
    align-items: center;
}
.Popup_PopupFooter__3tyrN{
    width: 100%;
    border-top: 1px solid #404040;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5em 1em;
}
.Popup_PopupBody__4EhHU{

    display: table-row;
    height: 100%;
}
.Popup_PopupBodyWrapper__3Z-J5{
    padding: 1em;
}
.Popup_PopupDescription__2gHOM{
    font-size: 0.9em;
    color: #ababab;
}
.Popup_PopupHeader__2Sr-1 p{
    flex: 1 1;
    color: #fff;
    margin: 0;
    padding: 0 1em;
}
.Popup_PopupHeader__2Sr-1 button{
    background: none;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-right: 10px;
}
@media (max-width: 720px) {
    .Popup_SmallPopup__2GS0I{
        min-width: 98%;
        max-width: 98%;
    }
    .Popup_PopupFooter__3tyrN button{
        font-size: 0.8em;
    }
}
.NumberCounter_Counter__1XleS{
    min-width: 100px;
    font-family: "LCD-Font", "IranSANS";
    margin: 0 2em;
}
.NumberCounter_CounterLabel__1wbBR{
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 5px;
    text-transform: uppercase;
    text-align: right;
    color: #7d7d7d;
    margin: 1em 0 0 0;
}
.NumberCounter_CounterNumber__1xhTh{
    font-size: 50px;
    font-weight: normal;
    color: var(--color-board-number);
    letter-spacing: 7px;
    text-align: right;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.NumberCounter_OffNumber__BzRtq{
    color: #fff;
    opacity: 0.16;
}
.NumberCounter_Number__3tben{
    display: flex;
    align-items: center;
    letter-spacing: 4px;
}
@media (max-width: 720px) {
    .NumberCounter_CounterLabel__1wbBR{
        font-size: 9px;
        white-space: nowrap;
    }
    .NumberCounter_CounterNumber__1xhTh{
        font-size: 20px;
    }
    .NumberCounter_Counter__1XleS{
        margin: 0 0.5em 1em 0.5em;
        min-width: auto;
    }

}
@media (min-width: 721px) and (max-width: 1023px){
    .NumberCounter_Counter__1XleS{
        margin: 0 0.5em 1em 0.5em;
        min-width: auto;
    }
    .NumberCounter_CounterNumber__1xhTh{
        font-size: 35px;
    }
    .NumberCounter_CounterLabel__1wbBR{
        font-size: 14px;;
        white-space: nowrap;
    }
}
@media (min-width: 768px) {

}
@media (min-width: 992px) {
    .NumberCounter_CounterLabel__1wbBR{
        font-size: 15px;
    }
    .NumberCounter_CounterNumber__1xhTh{
        font-size: 31px;
    }
    .NumberCounter_Counter__1XleS{
        margin: 0 0.3em;
    }
}
@media (min-width: 1024px) and (max-width: 1199px){
    .NumberCounter_CounterLabel__1wbBR{
        font-size: 12px;
    }
    .NumberCounter_CounterNumber__1xhTh{
        font-size: 22px;
    }
    .NumberCounter_Counter__1XleS{
        min-width: auto;
        margin: 0 0.3em;
    }

}
@media (min-width: 1200px) and (max-width: 1299px) {
    .NumberCounter_CounterLabel__1wbBR{
        font-size: 15px;
    }
    .NumberCounter_CounterNumber__1xhTh{
        font-size: 31px;
    }
    .NumberCounter_Counter__1XleS{
        margin: 0 0.3em;
    }
}
@media (min-width: 1300px) and (max-width: 1399px) {
    .NumberCounter_CounterLabel__1wbBR{
        font-size: 15px;
    }
    .NumberCounter_CounterNumber__1xhTh{
        font-size: 35px;
    }
    .NumberCounter_Counter__1XleS{
        margin: 0 0.7em;
    }
}
@media (min-width: 1400px) {
    .NumberCounter_CounterLabel__1wbBR{
        font-size: 15px;
    }
    .NumberCounter_CounterNumber__1xhTh{
        font-size: 46px;
    }
    .NumberCounter_Counter__1XleS{
        margin: 0 1em;
    }
}
.ContextMenu_ContextMenuOverlay__2jVg6{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
    background: rgba(0,0,0,0.5);
    writing-mode: initial;
}
.ContextMenu_ContextMenu__3YG32{
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    border-radius: 4px;
    background: #333;
    display: table;
    min-width: 230px;
    max-width: 230px;
    z-index: 999999999;
    color: #fff;
    position: absolute;
    right: 50%;
    top: 50%;
    box-shadow: 0 0 3px #333, 0 0 3px #333;
    writing-mode: initial;
}
.ContextMenu_ContextBody__1wiQA{

    display: table-row;
    height: 100%;
}
.ContextMenu_ContextBodyWrapper__32T_a{
    padding: 0em;
}
.ContextMenu_LeftContextMenu__3tbbr{
    right: 50%;
    left: auto;
}
.ContextMenu_ContextMenu__3YG32 ul{
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
}
.ContextMenu_ContextMenu__3YG32 ul li{
    padding: 0.8em 0.8em;
    border-bottom: 1px solid #444;
    transition: all ease-in-out 0.2s;
    font-size: 0.9em;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}
.ContextMenu_ContextMenu__3YG32 ul li:hover{
    background: #444;
    transition: all ease-in-out 0.2s;
}
.ContextMenu_ContextBodyTotal__30L5c{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.8em 0.8em;
    cursor: initial;

}
.ContextMenu_ContextBodyTotal__30L5c div{
    flex: 1 1;
    text-align: left;
    font-size: 0.9em;
    color: #fff;
}
.ContextMenu_ContextBodyTotal__30L5c span{
    width: 40px;
    text-align: center;
    font-size: 0.9em;
    color: #fff;
}
.ContextMenu_ContextFooter__2KGCw{
    width: 100%;
    border-top: 1px solid #404040;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5em 1em;
}
.ContextMenu_CloseButton__2g7m3{
    font-size: 0.9em;
    padding: 0.3em 1em;
    background: #202020;
    border: 1px solid #202020;
    color: #fff;
}
.ContextMenu_CloseButton__2g7m3:hover{
    background: #a60000;
    border: 1px solid #a60000;
    color: #fff;
}
@media (max-width: 720px) {
    .ContextMenu_ContextMenu__3YG32{
        min-width: 200px;
        max-width: 200px;

    }
    .ContextMenu_ContextMenuFixed__1z1tI{
        position: fixed;
    }
}
.VideoPlayer_MonitorScreenWrapper__36WQy{
    width: calc(100% - 60px);
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    /*padding-bottom: 11em;*/
    position: relative;
}
.VideoPlayer_MonitorScreenWrapper__36WQy:hover > .VideoPlayer_MonitorVideoFullscreen__1d-cK{
    visibility: visible;
    transition: visibility ease-in-out 0.3s;
}
.VideoPlayer_MobileScreenWrapper__2LM9A{
    width: 100% !important;
}
.VideoPlayer_MonitorVideo__28ulc{
    /*transform: scaleX(1.9) !important;*/
}
.VideoPlayer_MonitorVideoFullscreen__1d-cK{
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 99999999;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.VideoPlayer_DisabledMenu__q6xry{
    color: #6e6e6e !important;
    cursor: initial !important;
}

.VideoPlayer_MonitorVideoFullscreenButton__2y4W9{
    width: 92px;
    height: 92px;
    border: 7px solid #b9b9b9;
    color: #b9b9b9;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 34px;
    cursor: pointer;
    margin-right: 5px;
}
.VideoPlayer_MonitorVideoFullscreenButton__2y4W9:hover{
    border-color: #fff;
    color: #fff;
    transition: all ease-in-out 0.4ms;
}
.VideoPlayer_MonitorBackground__1VwPw{
    background-size: cover;
    height: 100%;
    width: 100%;
}

.VideoPlayer_VideoPlayer__2jYxb{

    height: 100% !important;
    overflow: visible !important;
    transform: scale(1.1);

}
.VideoPlayer_VideoPlayer__2jYxb video{
    width: 800px !important;
}
.VideoPlayer_QualityWrapper__-QPUq{
    position: absolute;
    z-index: 9999999999;
    left: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
}
.VideoPlayer_QualityWrapper__-QPUq li{
    margin-right: 10px;
    background: #3e3e3e;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}
.VideoPlayer_QualityWrapper__-QPUq li:hover,
.VideoPlayer_QualityWrapper__-QPUq li.active{
    color: #1dee19;
    transition: all ease-in-out 0.3s;
}
.VideoPlayer_QualityWrapper__-QPUq li.active{
    font-weight: bold;
}
.VideoPlayer_MobileQualityWrapper__1zvHf{
    left: auto;
    bottom: 25px;
}
.volume input[type=range]{
    display: none;
}
.volume{
    display: flex;
    justify-content: center;
    align-items: center;
}    
.bar-hoverbox{
    padding: 10px 3px;
    opacity: 0.7;
    transition: opacity .2s;
    width: calc(100% - 32px);
}    
.bar-hoverbox:hover{
    opacity: 1;
    cursor: pointer;
}
.bar{
    background: #999;
    height: 7px;
    width: 120px;
    border-radius: 15px;
    overflow: hidden;
    pointer-events: none;
}
.bar .bar-fill{
    background: #FFF;
    width: 0%;
    height: 100%;
    background-clip: border-box;
    pointer-events: none;
}
.VolumeBar_VolumeMuteButton__9gEaY{
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.VolumeBar_VolumeMuteButtonSvg__3D-pZ svg{
    stroke: #f90505 !important;
    fill: #f90505 !important;
}
.VolumeBar_VolumeMuteButton__9gEaY svg{
    stroke: #a8a8a8;
    fill: #a8a8a8;
}
.VolumeBar_VolumeMuteButton__9gEaY:hover > svg{
    stroke: #3dc733;
    fill: #3dc733;
}
.Login_LoginContainer__30Fy7{
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333f4f;
}
.Login_WebsiteLoginContainer__3cfoo{
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}
.Login_RegisterComplete__3oaNZ{
    align-items: flex-start;
}
.Login_WebsiteLoginContainer__3cfoo .Login_LoginWrapper__1_zrr{
    max-width: 100%;
    min-width: 100%;
    width: 100%;

}
.Login_LoginWrapper__1_zrr{
    min-width: 500px;
    max-width: 500px;

}

.Login_LoginBody__3RoTU{
    background-color: #222b35;
    box-shadow: 0px 0px 10px 0px #222b35;
    padding: 2em 2em;
    margin-top: 1em;
}
.Login_WebsiteLoginContainer__3cfoo label{
    font-size: 15px;
    font-weight: normal;
    line-height: 40px;
    color: #000;
}
.Login_WebsiteLoginContainer__3cfoo .Login_LoginBody__3RoTU{
    background: #fff;
    box-shadow: none;
    margin-top: 0;
}
.Login_LoginBody__3RoTU .form-control{
    background-color: transparent;
    border: 1px solid #b3b3b3;
    padding: 0.7rem 0.75rem;
}
.Login_LoginBody__3RoTU select option{
    background: #ffffff;
    color: #7a7a7a;
}

.Login_LoginBody__3RoTU h4{
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto 2em auto;
    padding: 1em;
    background: #1c242c;
    text-transform: uppercase;
    font-size: 1.3rem;
    border-radius: 4px;
}
.Login_StartCenterValue__2QONp{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.Login_StartCenterValue__2QONp label{
    margin: 0;
}
.Login_EndCenterValue__3fyLZ{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.Login_LoadingContainer__zShah{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.Login_WebsiteLoginContainer__3cfoo h5{
    font-size: 15px;
    color: #7a7a7a;
    font-weight: normal;
    line-height: 25px;
    margin-bottom: 1em;
    text-align: center;
}
.Login_WebsiteLoginContainer__3cfoo button{
    border-radius: 0 !important;
    font-size: 14px;
    padding: 13px 34px;
    text-transform: uppercase;
}
.Login_SubLoginText__10To5{
    margin-top: 2em;
    font-size: 15px;
}
.Login_WebsiteLoginContainer__3cfoo a{
    color: #d50f0a;
}
.Login_WebsiteLoginContainer__3cfoo a:hover{
    color: #333;
    transition: all ease-in-out 0.5s;
}
.Login_Label__3PHmp{
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    color: #333;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    font-size: 15px;
    font-weight: normal;
    line-height: 40px;
}
.Login_WebsiteLoginContainer__3cfoo .form-control{
    border: 2px solid #e3e3e3;
    border-radius: 0;
    color: #333;
}
.Login_WebsiteLoginContainer__3cfoo .form-control:focus{
    border: 2px solid #d50f0a;
    box-shadow: none;
}
.Login_WebsiteLoginContainer__3cfoo .form-control:disabled{
    color: #7a7a7a;
}
footer {
    clear: both;
    position: relative;
    min-height: auto;
    margin-top: 0;
    background: #242424;
    padding: 30px 0;
}
.Login_FullHeight__3Hi0r{
    height: 100%;
    background: #fff;
    overflow-y: scroll;
}

.Login_LogoCopyright__3KjYT{
    text-align: center;
}
.Login_Copyright__1-lm2{
    color: #d0cecf;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 1.9em;
}
footer ul{
    list-style: none;
    max-width: 90%;
    margin: 0px auto;

}
footer ul li{
    display: inline-block;
    line-height: 30px;
    display: inline-block;
    margin: 0 10px;
}
footer ul li a{
    color: #d0cecf;
    font-size: 11px;
}
footer ul li a:hover{
    color: #d50f0a;
    transition: all ease-in-out 0.5s;
}
.Login_FooterMenu__b74gk{
    padding-bottom: 5px;
    border-bottom: 1px solid #404040;
    margin-bottom: 20px;

}
@media (min-width: 600px) {
    .Login_WebsiteLoginContainer__3cfoo .Login_LoginWrapper__1_zrr{
        min-width: 600px !important;
        max-width: 600px !important;;
        height: 600px !important;;
    }
    footer{
        min-height: 200px;
        margin-top: -100px !important;

    }
}
.Login_NoFloat__1SQuE{
    min-height: auto;
    margin-top: 280px;
}
.Login_SuccessLogo__171ms{
    font-size: 32px;
    margin-bottom: 0.3em;
}

.Login_CountryCode__2y0y7{
    width: 80px;
    border-end-end-radius: 0px;
    border-start-end-radius: 0px;

}
.Login_CountryPhoneNumber__171Eo{
    border-start-start-radius: 0px;
    border-end-start-radius: 0px;
}
.Login_CountryCodeDropDown__11p6P .dropdown-menu{
    max-height: 380px;
    overflow-y: scroll;
}
.Login_CountryCodeDropDown__11p6P .dropdown-toggle{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-end-end-radius: 0px;
    border-start-end-radius: 0px;
    background: #fff;
    color: #333;
    height: 100%;
    border: 2px solid #e3e3e3 !important;
    padding: 0.3rem  0.75rem;
    border-right: 0 !important;
}
.show .btn-secondary.dropdown-toggle:focus{
    box-shadow: none !important;
}
.show .btn-secondary.dropdown-toggle{
    background: #fff;
    color: #333
}
.Login_CountryCodeDropDown__11p6P .dropdown-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
hr{
    color: #333;
}
.Login_RegisterFooter__k2hXM{
    min-height: auto;
    margin-top: 30px;
}
.Login_RegisterCheckBox__Bx5w6{
    display: flex;
    align-items: center;
}
.Login_RegisterCheckBox__Bx5w6 label{
    font-size: 12px;
    margin-left: 0.7em;
}
.Login_RegisterCheckBox__Bx5w6 input{
    margin-top: 0;
    width: 1.3em;
    height: 1.3em;

}
.Login_RegisterCheckBox__Bx5w6 .invalid-feedback,
.Login_RegisterCheckBox__Bx5w6 .valid-feedback{
    display: none;
}
@media (max-width: 720px) {
    footer{
        display: none;
    }
    .Login_FullHeight__3Hi0r{
        min-height: 100vh;
        height: 100vh;
    }
    .Login_LoginBody__3RoTU h5{
        font-size: 12px;
    }
    .Login_SubLoginText__10To5{
        font-size: 11px;
    }
    .Login_RegisterCheckBox__Bx5w6 label{
        line-height: 17px;
    }
    .Login_LoginWrapper__1_zrr{
        max-width: 95%;
        min-width: 95%;
    }
    .Login_LoginWrapper__1_zrr .Login_LoginBody__3RoTU{
        padding: 1em;
    }
}
.Login_SocialIcon__LOlP5{
    font-size: 19px;
    color: #fff;
    background: #d50f0a;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    display: block;
    text-align: center;
    line-height: 38px;
}
.Login_SocialIcon__LOlP5:hover{
    background-color: #fff;
    color: #d50f0a;
    transition: all ease-in-out 0.3s;
}
.Login_EyePassword__3FkpA{
    position: absolute;
    right: 24px;
    font-size: 19px;
    cursor: pointer;
}

.Login_EyePassword__3FkpA svg{
    color: #787878;
}
.Login_EyePassword__3FkpA:hover > svg{
    color: #333;
}

.AccountContainer_Container__AyDRU{
    width: 100%;
    height: 100%;
    min-height: 100%;
    background-color: #222b35;
    display: table;
}
.AccountContainer_Container__AyDRU .form-control{
    background-color: transparent;
    border: 1px solid #b3b3b3;
    padding: 0.7rem 0.75rem;
}
.AccountContainer_Container__AyDRU .form-center{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em 0;
}
.AccountContainer_Container__AyDRU .form-center-container{

}
.AccountContainer_Sidebar__1lfAc{
    width: 350px;
    min-width: 350px;
    background-color: #333f4f;
    height: 100%;
    display: table-cell;
}
.AccountContainer_AdminSidebar__16Qkm{
    font-size: 0.4em;
    width: 350px;
    min-width: 350px;
    background-color: #333f4f;
    height: 100%;
    display: table-cell;
}
.AccountContainer_RightSidebar__2VqcS{

}
.AccountContainer_SidebarWrapper__AsEYe{
}
.AccountContainer_MainContainer__3n2DC{
    width: 100%;
}
.AccountContainer_MapDashboard__3wW44{
    font-size: 1.4rem;
    background: #29333e;
    width: 100%;
    height: 3.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
}
.AccountContainer_MainWrapper__3Yiyc{
    padding: 1em;
}
.AccountContainer_MainWrapper__3Yiyc form{
    max-width: 600px;
    width: 100%;
}
.AccountContainer_SubSidebarMenu__1isLc{
    margin: 1em 0 0 0;
    list-style: none;
    padding: 0;
    background: #293340;
}
.AccountContainer_SidebarMenu__3J6fa{
    margin: 1em;
    list-style: none;
    padding: 0;
}
.AccountContainer_SidebarMenu__3J6fa li{
    text-align: center;
    padding: 0.7em 0;
    position: relative;
}
.AccountContainer_SidebarMenu__3J6fa li h5{
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1rem;
}
.AccountContainer_SidebarMenu__3J6fa li span{
    color: #fff900;
    font-size: 1rem;
    font-weight: 400;
}
.AccountContainer_LineMenuItem__2p70G{
    display: flex;
    justify-content: center;
    align-items: center;
}
.AccountContainer_MenuRadioItem__27bpN{
    font-size: 2.5em;
    margin-left: 1em;
}
.AccountContainer_HasSubMenu__3RWei{
    position: absolute;
    right: 1em;
    top: 10px;
    background: #293340;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.AccountContainer_ActiveSubMenu__2At2t{
    background: #222b36;
    padding-bottom: 0 !important;
}
.AccountContainer_ActiveSubMenuTitle__1bmhz{
    color: #fff900 !important;
    font-weight: bold;
}
.AccountContainer_LineMenuItem__2p70G h5{
    margin-bottom: 0;
    margin-right: 5px;
}
.AccountContainer_AdminSidebar__16Qkm .AccountContainer_SidebarMenu__3J6fa li{
    padding: 1.3em 0;
}
.AccountContainer_SidebarMenu__3J6fa li a svg{

}
.AccountContainer_AccountInfo__bjs0J{
    margin-top: 3em;
    display: flex;
    justify-content: center;
    align-items: center;

}
.AccountContainer_AccountInfo__bjs0J h5{
    margin-right: 0.8em;
    margin-bottom: 0;
}
.AccountContainer_SidebarMenu__3J6fa li a{
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1rem;
    transition: all ease-in-out 0.3s;
}
.AccountContainer_SidebarMenu__3J6fa li a:hover{
    color: #fff900;
    transition: all ease-in-out 0.3s;
}
.AccountContainer_SidebarMenu__3J6fa li a.AccountContainer_LinkActive__2kwYo{
    color: #fff900;
    transition: all ease-in-out 0.3s;
}
.AccountContainer_LogoutButton__UW5Aj svg{
    font-size: 28px;
}
.AccountContainer_LoadingContainer__3Yy37{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}


.AccountContainer_LineAccountInfo__nLpic{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3em 0 !important;
}
.AccountContainer_LineAccountInfo__nLpic h5{
    margin-right: 0.8em;
    margin-bottom: 0;
}
.AccountContainer_MobileNavButton__2XsSU{
    display: none;
}
.AccountContainer_SidebarLogo__2Vk5L{
    width: 150px;
}
@media (max-width: 720px) {
    .AccountContainer_SidebarLogo__2Vk5L{
        width: 100px;
    }
    .AccountContainer_OverlayLock__1Btrs{
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        position: absolute;
        top: 64px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99999999;
    }
    .AccountContainer_MapDashboard__3wW44{
        height: 64px;
    }
    .AccountContainer_Sidebar__1lfAc{
        width: 300px;
        min-width: 300px;
        position: absolute;
        left: -300px;
        top: 64px;
        transition: left linear 0.3s;
        z-index: 999999999;
        overflow-y: scroll;
    }
    .AccountContainer_AdminSidebar__16Qkm{
        width: 300px;
        min-width: 300px;
        position: absolute;
        left: -300px;
        top: 64px;
        transition: left linear 0.3s;
        z-index: 999999999;
        overflow-y: scroll;
    }
    .AccountContainer_Container__AyDRU .form-center{
        margin: 1em;
    }
    .AccountContainer_MainWrapper__3Yiyc form{
        max-width: 100% !important;
    }
    .AccountContainer_MobileNavButton__2XsSU{
        display: none;
        position: absolute;
        left: 1em;
        background: none;
        border: none;
        height: 100%;
        width: 50px;
        text-align: left;
        color: #fff;
        font-size: 20px;;
    }
    .AccountContainer_MobileNavButton__2XsSU:focus{
        outline: none;
        color: #a60000;
    }
    .AccountContainer_OpenedSidebarMenu__RQz0H{
        left: 0px;
        transition: left linear 0.3s;
        padding-bottom: 4em;
    }

    .AccountContainer_SidebarWrapper__AsEYe{
        padding-bottom: 4em;
    }

    .AccountContainer_Container__AyDRU .form-control{
        background-color: #29333e;
        border: 1px solid #29333e;
        padding: 0.7rem 0.75rem;
    }
    .AccountContainer_Container__AyDRU .input-group-text{
        background-color: #222b35;
        border: 1px solid #29333e;
        font-size: 0.9em;
    }
}

.LoadingContainer_AppLogoContainer__yfmiS{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.LoadingContainer_FullWidth__3BIjp{
    max-width:  60% !important;
}
.LoadingContainer_AppLogo__36Xc2 {
    height: 15vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .LoadingContainer_AppLogo__36Xc2 {
        animation: LoadingContainer_AppLogoSpin__1qHsi infinite 3s linear;
    }
}
@keyframes LoadingContainer_AppLogoSpin__1qHsi {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.Profile_LoginContainer__51x6a{
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333f4f;
}
.Profile_LoginWrapper__9TL7G{
    max-width: 500px;
    min-width: 500px;
    width: 500px;



}
.Profile_LoginBody__1wa9R{
    background-color: #222b35;
    box-shadow: 0px 0px 10px 0px #222b35;
    padding: 2em 2em;
    margin-top: 1em;
}
.Profile_LoginBody__1wa9R .form-control{
    background-color: transparent;
    border: 1px solid #b3b3b3;
    padding: 0.7rem 0.75rem;
}
.Profile_LoginBody__1wa9R h4{
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto 2em auto;
    padding: 1em;
    background: #1c242c;
    text-transform: uppercase;
    font-size: 1.3rem;
    border-radius: 4px;
}
.Profile_StartCenterValue__ImfMG{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.Profile_StartCenterValue__ImfMG label{
    margin: 0;
}
.Profile_EndCenterValue__1LvvK{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.Profile_CountryCode__i6toh{
    width: 80px;
    border-end-end-radius: 0px;
    border-start-end-radius: 0px;
}
.Profile_CountryPhoneNumber__jOoE1{
    border-start-start-radius: 0px;
    border-end-start-radius: 0px;
    border-left: 0 !important;
}
.Profile_CountryCodeDropDown__3p0gt .dropdown-menu{
    max-height: 380px;
    overflow-y: scroll;
}
.Profile_CountryCodeDropDown__3p0gt .dropdown-toggle{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-end-end-radius: 0px;
    border-start-end-radius: 0px;
    background: #29333e;
    height: 100%;
    border: 1px solid #b3b3b3 !important;
    padding: 0.3rem  0.75rem;
}
.Profile_CountryCodeDropDown__3p0gt .dropdown-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Deposit_FullWidth__p34Kx{
    max-width: 80% !important;
}
.Deposit_TextAlert__2Akms{
    color: #fff !important;
    text-align: center;
    margin-top: 1em;
    font-size: 1em;
}
.Deposit_PaymentImage__1C-Rn{
    max-width: 60%;
}
@media (max-width: 720px) {
    .Deposit_PaymentImage__1C-Rn{
        max-width: 100%;
    }
}
.Withdrawal_UploadBox__1jieI{
    background: #1c1c1e;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
.Withdrawal_FullWidth__3ainC{
    max-width: 90% !important;
}
.Withdrawal_TitleBox__8x_At{
    color: #fff900;
}
.AccountTable_Table__2kyDt{
    width: 90%;
    margin: 0 auto;
}
.AccountTable_FullTable__39fu_{
    width: 100%;
    margin: 0 auto;
}
.AccountTable_TableCell__Ip6S5{
    border: 1px solid #fff;
    padding: 0.7em 1em;
    text-align: center;
    font-weight: 400;
}
.AccountTable_BackArrow__S_2Z9{
    position: absolute;
    padding: 0.4em 0;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    transition: all ease-in-out 0.3s;
    left: -40px;
    top: 4px;
}
.AccountTable_NextArrow__21nBF{
    position: absolute;
    padding: 0.4em 0;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    transition: all ease-in-out 0.3s;
    right: -40px;
    top: 4px;
}
.AccountTable_RelativeRow__2aUCD{
    position: relative;
}
.AccountTable_Cell__8bE9E{
    display: flex;
    align-items: center;
    justify-content: center;
}
.AccountTable_Cell__8bE9E svg:first-child{
    margin-left: 10px;
}
.AccountTable_Cell__8bE9E svg{
    font-size: 13px;
    width: 13px !important;
    height: 13px !important;
    margin: 0 2px;
    cursor: pointer;
}
.AccountTable_Cell__8bE9E svg:hover{
    color: #fff !important;
    transition: all ease-in-out 0.2s;
}
.AccountTable_CellActive__ZhbCj{
    color: #fff !important;
}
.AccountTable_TableHeader__2rmQE{
    color: #fff900;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    padding: 0.7em 1em;
    font-size: 0.9em;
}
.AccountTable_PaginationWrapper__1MJ-j{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5em 0;
}
.AccountTable_PaginationWrapper__1MJ-j ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
}
.AccountTable_PaginationWrapper__1MJ-j ul li{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.AccountTable_NavigatorButton__1Xc5W{
    height: 38px;
    width: 55px;
    border-radius: 4px;
    border: 0;
    background: #29333e;
    margin: 0 0.3em;
    border: 1px solid #35404c;
}
.AccountTable_PaginationWrapper__1MJ-j ul li:hover{
    background: #a60000;
    border: 1px solid #a60000;
    transition: all ease-in-out 0.5s;
}
.AccountTable_NavigatorActive__34t7B{
    background: #a60000 !important;
    border: 1px solid #a60000 !important;
    transition: all ease-in-out 0.5s !important;
}
.AccountTable_NavigatorDisabled__2TI5z{
    background: #46494c !important;
    color: #b9b9b9 !important;
    border-color: #46494c !important;
    cursor: initial !important;
}
.AccountTable_Navigator__2m1PY{
    border: 1px solid #35404c;
    margin: 0 0.2em;
    border-radius: 4px;
    background: #29333e;
    min-width: 38px;
    height: 38px;
    padding: 0 0.4em;
}
.AccountTable_Navigator__2m1PY:hover{
    background: #a60000;
    transition: all ease-in-out 0.5s;
}
.AccountTable_FullWidth__oUuqZ{
    width: 100%;
}
.AccountTable_RowLink__20D2K{
    cursor: pointer;
}
.AccountTable_RowLink__20D2K:hover > td{
    background-color: #00a10d;
}
.AccountTable_ActiveCell__-87Yp{
    background-color: #00a10d;
}
.AccountTable_HiddenDesktop__2zjGS{
    display: none;
}
@media (max-width: 720px) {
    .AccountTable_TableHeader__2rmQE,
    .AccountTable_TableCell__Ip6S5{
        display: none;
    }
    .AccountTable_ShowMobileVersion__3CzkO{
        display: table-cell;
    }
    .AccountTable_HiddenDesktop__2zjGS{
        display: table-row;
    }
    .AccountTable_HiddenDesktop__2zjGS td{
        display: block;
    }
    .AccountTable_TableRow__1XwyM{
        display: table-row;
    }
    .AccountTable_TableMobileRow__fV3wF{
        display: block;
    }
    .AccountTable_TableMobileCell__6MmGG{
        display: block;
        margin-bottom: 1em;
    }
    .AccountTable_TableMobileCell__6MmGG label{
        color: #fff900;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        padding: 0.2em 1em;
        font-size: 0.9em;
        width: 100%;
    }
    .AccountTable_TableNumberBox__k-kKA{
        border-radius: 10px;
        border: 1px solid #222b35;
        background: #1b2128;
        padding: 0.7em 1em;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center
    }
    .AccountTable_PaginTableNumberBox__Fkmul{
        padding: 0;
    }
    .AccountTable_PaginTableNumberBox__Fkmul button{
        padding: 0.7em 1.5em;
    }
    .AccountTable_TableNumberBox__k-kKA span{
        flex: 1 1;
    }
    .AccountTable_TableNumberBox__k-kKA button{
        background: none;
        border: none;
        color: #fff;
    }
    .AccountTable_TableNumberBox__k-kKA button:disabled{
        color: #545454;
    }
    .AccountTable_TableNumberBox__k-kKA button:focus{
        outline: 0;
    }

    .AccountTable_TableHeader__2rmQE{
        border-radius: 10px;
        border: 1px solid #222b35;
        background: #0a0d10;
    }
    .AccountTable_TableCell__Ip6S5{
        border-radius: 10px;
        border: 1px solid #222b35;
        background: #151a20;
    }
    .form-search{
        margin: 0 !important;
        width: 100% !important;
    }
    .form-search .input-group-text {
        margin: 0;
        width: 100%;
        height: -moz-fit-content;
        height: fit-content;
        padding: 0.175rem 0;
    }
    .form-search .form-control {
        margin: 0;
        min-width: 100% !important;
    }
    .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
    .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu){
        border-top-right-radius: .25rem !important;
        border-top-left-radius: .25rem !important;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

}

.AccountTable_ArrowButtonCell__1F_bK{
    padding: 0.4em 0;
    width: 80px;
}
.AccountTable_ArrowButtonCell__1F_bK div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.AccountTable_ArrowButtonCell__1F_bK button{
    background: none;
    border: 1px solid #fff;
    color: #fff;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    transition: all ease-in-out 0.3s;
}
.AccountTable_ArrowButtonCell__1F_bK button:hover{
    background-color: #00a10d;
}
.LoadingData_AppLogoContainer__qkETp{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: transparent;
}
.LoadingData_AppLogo__WRIcw {
    height: 15vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .LoadingData_AppLogo__WRIcw {
        animation: LoadingData_AppLogoSpin__CF204 infinite 3s linear;
    }
}
@keyframes LoadingData_AppLogoSpin__CF204 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.LoadingData_AppLogoContainer__24C8N{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: transparent;
}
.LoadingData_AppLogo__1Q8qt {
    height: 15vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .LoadingData_AppLogo__1Q8qt {
        animation: LoadingData_AppLogoSpin__MzaMC infinite 3s linear;
    }
}
@keyframes LoadingData_AppLogoSpin__MzaMC {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.Journal_SearchLabel__JpMl3{
    margin-right: 1em;
}
.Journal_SearchLabels__1ucy0{
    margin: 0 1em;
}
.Journal_SearchBox__25mTX{
    width: 100%;
}
.Journal_DropDown__2-5bx{
    max-width: 200px !important;
    min-width: 200px !important;
}
.Journal_TimeDropDown__1OHjt{
    max-width: 70px !important;
    min-width: 70px !important;
}
.Journal_AutoWidth__2SXic{
    width: auto;
}
.LeftSidePopup_PopupOverlay__U5GDW{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
    background: rgba(0,0,0,0.2);
}
.LeftSidePopup_PopupContainer__36HiT{
    background: #333;
    border-radius: 4px;
    box-shadow: 0 0 7px #101010, 0 0 10px #101010;
    position: fixed;
    top: 0;
    left: 0;
}
.LeftSidePopup_Dark__E2GkL{
    background: #000;
}
.LeftSidePopup_Dark__E2GkL .LeftSidePopup_PopupHeader__2Sg6l{
    border-color: #000 !important;
}
.LeftSidePopup_Dark__E2GkL .LeftSidePopup_PopupFooter__3Ilvh{
    border-color: #000 !important;
}
.LeftSidePopup_SmallPopup__1TdQA{
    min-width: 33%;
    max-width: 33%;
}
.LeftSidePopup_LargePopup__25wJe{
    min-width: 60%;
    max-width: 60%;
    height: 100%;
    position: fixed;
    left: -60%;

}
.LeftSidePopup_PopupHeader__2Sg6l{
    width: 100%;
    height: 60px;
    font-size: 1.3em;
    border-bottom: 1px solid #404040;
    display: flex;
    align-items: center;
}
.LeftSidePopup_CloseButton__1kLo8{
    position: absolute;
    top: 30px;
    left: 10px;
    background: none;
    border: none;
    width: 32px;
    font-size: 1.5em;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    z-index: 99999;
}
.LeftSidePopup_ArrowButton__3-U0v{
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    font-size: 32px;
    color: #fff;
}
.LeftSidePopup_ArrowDisabled__3vEr3{
    color: #404040 !important;
    cursor: initial !important;
}
.LeftSidePopup_ArrowButton__3-U0v:hover{
    color: #006f32;
    transition: all ease-in-out 0.3s;
}
.LeftSidePopup_ArrowLeftButton__3f7_9{
    width: 40px;
    left: 10px;
    position: absolute;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.LeftSidePopup_ArrowRightButton__2XmpE{
    width: 40px;
    right: 25px;
    position: absolute;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.LeftSidePopup_LoadingContainer__BFFWh{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.LeftSidePopup_PopupFooter__3Ilvh{
    width: 100%;
    border-top: 1px solid #404040;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 2em;
    height: 60px;
}
.LeftSidePopup_PopupBody__f0TO0{
    height: 100%;
    position: relative;
}
.LeftSidePopup_PopupBodyWrapper__U8lw1{
    height: 100%;
}
.LeftSidePopup_PopupDescription__2UttW{
    font-size: 0.9em;
    color: #ababab;
}
.LeftSidePopup_PopupHeader__2Sg6l p{
    flex: 1 1;
    color: #fff;
    margin: 0;
    padding: 0 1em;
}
.LeftSidePopup_CloseButton__1kLo8:hover{
    color: #a60000;
    transition: all ease-in-out 0.5s;
}
@media (max-width: 720px) {
    .LeftSidePopup_ArrowRightButton__2XmpE{
        position: fixed;
        bottom: 5px;
        height: -moz-fit-content;
        height: fit-content;
        left: 0;
        top: auto;
        width: 100%;
    }
    .LeftSidePopup_CloseButton__1kLo8{
        top: 5px;
        left: 5px;
    }
    .LeftSidePopup_ArrowButton__3-U0v{
        width: 32px !important;
        height: 32px !important;;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .LeftSidePopup_ArrowButton__3-U0v svg{
        width: 24px !important;
        height: 24px !important;;
    }
    .LeftSidePopup_ArrowRightButton__2XmpE .wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .LeftSidePopup_ArrowRightButton__2XmpE .wrapper .round-number{
        font-size: 12px;
        font-weight: 600;
    }

}
@media (max-width: 720px) and (max-height: 700px){

}
@media (max-width: 1000px) {
    .LeftSidePopup_LargePopup__25wJe{
        min-width: 100%;
        max-width: 100%;
        height: 100%;
    }
}
@media (min-width: 1400px) and (max-width: 1600px) {
    .LeftSidePopup_LargePopup__25wJe{
        min-width: 45%;
        max-width: 45%;
        height: 100%;
    }
}
@media (min-width: 1600px) {
    .LeftSidePopup_LargePopup__25wJe{
        min-width: 35%;
        max-width: 35%;
        height: 100%;
    }
}

.UpdatePassword_LoginContainer__3NsHi{
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333f4f;
}
.UpdatePassword_LoginWrapper__2wKO9{
    max-width: 500px;
    min-width: 500px;
    width: 500px;



}
.UpdatePassword_LoginBody__3gf9V{
    background-color: #222b35;
    box-shadow: 0px 0px 10px 0px #222b35;
    padding: 2em 2em;
    margin-top: 1em;
}
.UpdatePassword_LoginBody__3gf9V .form-control{
    background-color: transparent;
    border: 1px solid #b3b3b3;
    padding: 0.7rem 0.75rem;
}
.UpdatePassword_LoginBody__3gf9V h4{
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto 2em auto;
    padding: 1em;
    background: #1c242c;
    text-transform: uppercase;
    font-size: 1.3rem;
    border-radius: 4px;
}
.UpdatePassword_StartCenterValue__mGygZ{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.UpdatePassword_StartCenterValue__mGygZ label{
    margin: 0;
}
.UpdatePassword_EndCenterValue__26FCC{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.UpdatePassword_LoadingContainer__3pi2D{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.UpdatePassword_EyePassword__Jn5Pl{
    position: absolute;
    right: 24px;
    font-size: 19px;
    cursor: pointer;
}

.UpdatePassword_EyePassword__Jn5Pl svg{
    color: #787878;
}
.UpdatePassword_EyePassword__Jn5Pl:hover > svg{
    color: #333;
}
.UpdateEmail_LoginContainer__1KRUk{
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333f4f;
}
.UpdateEmail_LoginWrapper__16Mkb{
    max-width: 500px;
    min-width: 500px;
    width: 500px;



}
.UpdateEmail_LogoLightRoulette__1xCKL{
    background: #fff;
    border-radius: 30px;
    box-shadow: 0px 1px 5px #fff;
    margin-top: 2em;
}
.UpdateEmail_LoginBody__3DCZD{
    background-color: #222b35;
    box-shadow: 0px 0px 10px 0px #222b35;
    padding: 2em 2em;
    margin-top: 1em;
}
.UpdateEmail_LoginBody__3DCZD .form-control{
    background-color: transparent;
    border: 1px solid #b3b3b3;
    padding: 0.7rem 0.75rem;
}
.UpdateEmail_LoginBody__3DCZD h4{
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto 2em auto;
    padding: 1em;
    background: #1c242c;
    text-transform: uppercase;
    font-size: 1.3rem;
    border-radius: 4px;
}
.UpdateEmail_StartCenterValue__1qP82{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.UpdateEmail_StartCenterValue__1qP82 label{
    margin: 0;
}
.UpdateEmail_EndCenterValue__1vwXj{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.UpdateEmail_LoadingContainer__AvnU2{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.Ticketing_BackBar__iSj1t{

}
.Ticketing_BackBar__iSj1t a {
    color: #fff;
}
.Ticketing_BackBar__iSj1t a:hover{
    color: #fff900;
}
.Ticketing_SubmitBox__1qO_k{
    padding: 3em 0;
    background: #2d3a48;
    margin-top: 1em;
    border-radius: 7px;
    box-shadow: 0px 0px 13px #343434;
}
.Ticketing_SubmitBox__1qO_k .col-md-9{
    width: 100% !important;
}
.Ticketing_SubmitBox__1qO_k .col-md-4{
    width: 20% !important;
}
.Attachments_HelpText__1GC9v{
    text-align: left;
    font-size: 12px;
    color: #ccc;
    margin-top: 4px;
    margin-bottom: 0;
    display: flex;

}
.Attachments_HelpText__1GC9v small{
    width: 100%;
}
.Attachments_HelpText__1GC9v input{
    width: 1px;
    height: 1px;
}
.Attachments_UploadedFiles__IrWKa{
    display: flex;
    flex-wrap: wrap;
    margin: 1em 0;
}
.Attachments_AttachItem__ZdgAk{
    width: 120px;
    height: 120px;
    padding: 3px;
    margin: 5px;
    background: rgba(255,255,255,0.4);
    border-radius: 3px;
    position: relative;
}
.Attachments_AttachItem__ZdgAk img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.Attachments_AttachItemRemove__3w30K{
    position: absolute;
    top: -6px;
    right: -6px;
    width: 18px;
    height: 18px;
    border: 0;
    background: #d50f0a;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}
.Errors_Container__3TNqm{
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.Errors_Error404__2CVQ8{

}
.Errors_Error404__2CVQ8 .Errors_Title__1p5vk{
    font-size: 60px;
    font-weight: bold;
}
.Errors_Error404__2CVQ8 .Errors_Description__mWXZ8{

}

.Ticket_Ticket__2X9c-{
    width: 100%;
    background: #39495a;
    padding: 10px 0px;
    border-radius: 7px;
    box-shadow: 0px 0px 13px #343434;
    margin: 10px 0;
}
.Ticket_Ticket__2X9c- .Ticket_TicketHeader__26NzH{
    padding: 5px 20px 10px 20px;
    border-bottom: 1px solid #303f4e;
    display: flex;
}
.Ticket_Ticket__2X9c- .Ticket_TicketHeaderAvatar__13oAr{
    min-width: 40px;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Ticket_Ticket__2X9c- .Ticket_TicketHeaderBase__de6CL{
    width: 100%;
}
.Ticket_Ticket__2X9c- .Ticket_TicketHeaderBaseCenter__2Siz3{
    display: flex;
    align-items: center;
}
.Ticket_Ticket__2X9c- .Ticket_TicketHeaderBaseCenter__2Siz3 h1{
    margin: 0;
}
.Ticket_Ticket__2X9c- .Ticket_TicketHeaderDate__8Eupi{
    min-width: 180px;
    width: 180px;
    height: 40px;
    font-size: 11px;
    text-align: center;
}
.Ticket_Ticket__2X9c- .Ticket_TicketHeaderAvatar__13oAr div{
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e7e7e7;
    border-radius: 100%;
    color: #6d849d;
    font-size: 21px;
    font-weight: bold;
}
.Ticket_Ticket__2X9c- .Ticket_TicketBody__29y37{
    padding: 5px 20px;
}
.Ticket_TicketBody__29y37 h6{
    margin: 9px 0;
    font-size: 14px;
    color: #fff;
}
.Ticket_Ticket__2X9c- h1{
    font-size: 14px;
    color: #fff900;
}
.Ticket_Ticket__2X9c- h3{
    font-size: 12px;

}
.Ticket_Ticket__2X9c- .Ticket_TicketBody__29y37{
    font-size: 12px;
    color: #d1d1d1;
}
.Ticket_AgentTicket__2Hu56{
    background-color: #424242;
}
.Ticket_AgentTicket__2Hu56 .Ticket_TicketHeader__26NzH{
    border-color: #383838;
}
.Ticket_OwnerTicket__1cLiV{
    background-color: #2d3a48;
}
.Ticket_FirstTicket__cYVKE{
    margin-bottom: 4em;
}
.Ticket_UploadedFiles__4FXKB{
    display: flex;
    flex-wrap: wrap;
    margin: 1em 0;
}
.Ticket_AttachItem__2-Txb{
    width: 120px;
    height: 120px;
    padding: 3px;
    margin: 5px;
    background: rgba(255,255,255,0.4);
    border-radius: 3px;
    position: relative;
}
.Ticket_AttachItem__2-Txb img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.AccountClosure_FullWidth__Nt3hm{
    max-width: 80% !important;
}
.AccountClosure_TextAlert__2OJh1{
    color: #fff !important;
    text-align: center;
    margin-top: 1em;
    font-size: 1em;
}
.AccountClosure_PaymentImage__2J-ns{
    max-width: 60%;
}
@media (max-width: 720px) {
    .AccountClosure_PaymentImage__2J-ns{
        max-width: 100%;
    }
}
.AccountClosure_Timer___a8AW{
    text-align: center;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 3px;
    padding: 1em;
}
.DeleteAccount_FullWidth__3iNbr{
    max-width: 80% !important;
}
.DeleteAccount_TextAlert__2IsLH{
    color: #fff !important;
    text-align: center;
    margin-top: 1em;
    font-size: 1em;
}
.DeleteAccount_PaymentImage__13SrF{
    max-width: 60%;
}
@media (max-width: 720px) {
    .DeleteAccount_PaymentImage__13SrF{
        max-width: 100%;
    }
}
.DeleteAccount_Timer__1Zuza{
    text-align: center;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 3px;
    padding: 1em;
}
.Limits_FullWidth__11P7x{
    max-width: 80% !important;
}
.Limits_TabsContainer__oChi4{
    max-width: 80%;
    margin: 2em auto 0 auto;
    border-bottom: 2px solid #4c5c6c;
    background: #313d4a;
}
.Limits_TabsContainer__oChi4 .nav-link{
    flex: 1 1;
    padding: 1em;
    border: 0;
    color: #fff;
    font-size: 15px;
}
.Limits_TabsContainer__oChi4 .nav-link.active,
.Limits_TabsContainer__oChi4 .nav-link:hover
{
    background: transparent;
    border-bottom: 4px solid #fff900;
    color: #fff900;
}
.Limits_TextAlert__2cLwM{
    color: #fff !important;
    text-align: center;
    margin-top: 1em;
    font-size: 1em;
}
.Limits_PaymentImage__3HkIa{
    max-width: 60%;
}
@media (max-width: 720px) {
    .Limits_PaymentImage__3HkIa{
        max-width: 100%;
    }
}
.Limits_Timer__3z-yE{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 3px;
    padding: 1em;
}

.PanelContainer_Container__4pt0p{
    width: 100%;
    height: 100%;
    min-height: 100%;
    background-color: #222b35;
    display: table;
}
.PanelContainer_Container__4pt0p .form-control{
    background-color: transparent;
    border: 1px solid #b3b3b3;
    padding: 0.7rem 0.75rem;
}
.PanelContainer_Container__4pt0p .form-center{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em 0;
}
.PanelContainer_Container__4pt0p .form-center-container{

}
.PanelContainer_Sidebar__2S-kj{
    width: 350px;
    min-width: 350px;
    background-color: #333f4f;
    height: 100%;
    display: table-cell;
}
.PanelContainer_AdminSidebar__Pyflv{
    font-size: 0.4em;
    width: 350px;
    min-width: 350px;
    background-color: #333f4f;
    height: 100%;
    display: table-cell;
    vertical-align: top;
}

.PanelContainer_SidebarWrapper__rSv7e{
}
.PanelContainer_MainContainer__33io8{
    width: 100%;
}
.PanelContainer_MapDashboard__2dxmc{
    font-size: 1.4rem;
    background: #29333e;
    width: 100%;
    height: 3.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
}
.PanelContainer_MainWrapper__2x6us{
    padding: 1em;
}
.PanelContainer_AdminMainWrapper__aKj6j{
    padding: 1em 3em;
}
.PanelContainer_MainWrapper__2x6us form{
    max-width: 600px;
    width: 100%;
}
.PanelContainer_SidebarMenu__3k24W{
    margin: 1em;
    list-style: none;
    padding: 0;
}
.PanelContainer_SidebarMenu__3k24W li{
    text-align: center;
    padding: 0.7em 0;
}
.PanelContainer_SidebarMenu__3k24W li h5{
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1rem;
}
.PanelContainer_SidebarMenu__3k24W li span{
    color: #fff900;
    font-size: 1rem;
    font-weight: 400;
}
.PanelContainer_LineMenuItem__98OEw{
    display: flex;
    justify-content: center;
    align-items: center;
}
.PanelContainer_OnlineUsersLink__2h77J{
    color: #fff900 !important;
    font-size: 0.8rem !important;
    text-decoration: underline !important;

}
.PanelContainer_OnlineUsersLink__2h77J:hover{
    color: #fff !important;
}
.PanelContainer_MenuRadioItem__2In2I{
    font-size: 2.5em;
    margin-left: 1em;
}
.PanelContainer_LineMenuItem__98OEw h5{
    margin-bottom: 0;
    margin-right: 5px;
}
.PanelContainer_AdminSidebar__Pyflv .PanelContainer_SidebarMenu__3k24W li{
    padding: 1.3em 0;
}
.PanelContainer_AccountInfo__2JbJa{
    margin-top: 3em;
    display: flex;
    justify-content: center;
    align-items: center;

}
.PanelContainer_AccountInfo__2JbJa h5{
    margin-right: 0.8em;
    margin-bottom: 0;
}
.PanelContainer_SidebarMenu__3k24W li a{
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1rem;
    transition: all ease-in-out 0.3s;
}
.PanelContainer_SidebarMenu__3k24W li a:hover{
    color: #fff900;
    transition: all ease-in-out 0.3s;
}
.PanelContainer_SidebarMenu__3k24W li a.PanelContainer_LinkActive__34h7d{
    color: #fff900;
    transition: all ease-in-out 0.3s;
}
.PanelContainer_LogoutButton__rjKYg svg{
    font-size: 28px;
}
.PanelContainer_LoadingContainer__BixX7{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}


.PanelContainer_LineAccountInfo__3F8X2{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3em 0 !important;
}
.PanelContainer_LineAccountInfo__3F8X2 h5{
    margin-right: 0.8em;
    margin-bottom: 0;
}
.PanelContainer_MobileNavButton__YgPBY{
    display: none;
}
.PanelContainer_BackNavButton__1Rohv{
    position: absolute;
    left: 1em;
    background: none;
    border: none;
    height: 100%;
    width: 80px;
    text-align: left;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.PanelContainer_BackNavButton__1Rohv:hover{
    color: #fff900;
}
.PanelContainer_BackNavButton__1Rohv svg{
    margin-right: 10px;
}
.PanelContainer_SidebarLogo__2xRSy{
    width: 150px;
}

@media (max-width: 720px) {
    .PanelContainer_SidebarLogo__2xRSy{
        width: 100px;
    }
    .PanelContainer_BackNavButton__1Rohv{
        left: auto;
        right: 0.2em;
    }
    .PanelContainer_OverlayLock__vXhWZ{
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        position: absolute;
        top: 64px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99999999;
    }
    .PanelContainer_MapDashboard__2dxmc{
        height: 64px;
    }
    .PanelContainer_Sidebar__2S-kj{
        width: 300px;
        min-width: 300px;
        position: absolute;
        left: -300px;
        top: 64px;
        transition: left linear 0.3s;
        z-index: 999999999;
        overflow-y: scroll;
    }
    .PanelContainer_AdminSidebar__Pyflv{
        width: 300px;
        min-width: 300px;
        position: absolute;
        left: -300px;
        top: 64px;
        transition: left linear 0.3s;
        z-index: 999999999;
        overflow-y: scroll;
    }
    .PanelContainer_Container__4pt0p .form-center{
        margin: 1em;
    }
    .PanelContainer_MainWrapper__2x6us form{
        max-width: 100% !important;
    }
    .PanelContainer_MobileNavButton__YgPBY{
        display: block;
        position: absolute;
        left: 1em;
        background: none;
        border: none;
        height: 100%;
        width: 50px;
        text-align: left;
        color: #fff;
        font-size: 20px;;
    }
    .PanelContainer_MobileNavButton__YgPBY:focus{
        outline: none;
        color: #a60000;
    }
    .PanelContainer_OpenedSidebarMenu__wC9eD{
        left: 0px;
        transition: left linear 0.3s;
        padding-bottom: 4em;
    }

    .PanelContainer_SidebarWrapper__rSv7e{
        padding-bottom: 4em;
    }

    .PanelContainer_Container__4pt0p .form-control{
        background-color: #29333e;
        border: 1px solid #29333e;
        padding: 0.7rem 0.75rem;
    }
    .PanelContainer_Container__4pt0p .input-group-text{
        background-color: #222b35;
        border: 1px solid #29333e;
        font-size: 0.9em;
    }
}

.PageTitle_TitleBar__3gJ9G{
    width: calc(100% - 4em);
    display: table;
    margin: 3em 2em 3em 2em;
}
.PageTitle_FullWidthTitleBar__21Jxb{
    width: 100%;
    display: table;
    margin: 3em 0em 0em 0em;

}
.PageTitle_LeftSection__3g-pk{
    min-width: 40%;
    display: table-cell;
    vertical-align: center;
}
.PageTitle_CenterSection__23q98{
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    display: table-cell;
    vertical-align: center;
    text-align: center;
}
.PageTitle_RightSection__MzNuN{
    display: table-cell;
    vertical-align: center;
}
.PageTitle_FullTitleText__3qBK0{
    width: 100% !important;
}
.PageTitle_TitleText__1yFi8{
    background-color: #333f4f;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff900;
    padding: 0.7em 1.2em;
    text-transform: uppercase;
    font-weight: 500;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
}
.PageTitle_SearchBox__3Clhk{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.PageTitle_CenterSection__23q98 .PageTitle_TitleText__1yFi8{
    margin: 0 auto;
}
.float-calendar .react-calendar__navigation{
    background: #2d3846;
}
.float-calendar abbr[title]{
    text-decoration: none !important;
}
.float-calendar abbr[data-bs-original-title]{
    text-decoration: none !important;
}
.float-calendar .react-calendar__tile{
    border: 1px solid #2d3846 !important;
}
.float-calendar .react-calendar{
    background: #333f4f;
    border: 1px solid #222b35;
    font-size: 14px;
}
.float-calendar .react-calendar button{
    color: #fff;
}
.float-calendar .react-calendar button:hover{
    background-color: #d93935;
    color: #fff;
}
.float-calendar .react-calendar__month-view__days__day--weekend{
    color: #d93935 !important;
}
.float-calendar .react-calendar__month-view__days__day--weekend:hover{
    color: #fff !important;
}
.float-calendar .react-calendar__tile--now{
    color: #333 !important;
    background-color: #fff900 !important;
}
.search-item{
    border-radius: 0;
    padding: 0.7rem 0.75rem;
    border: 1px solid #fff;
    color: #fff;
    border-left: 0px;
    min-width: 100px;
    width: 140px;
    text-align: center;
    cursor: pointer;
    position: relative;

}
.search-item a{
    color: #fff;
    display: block;
}
.search-item:hover{
    border-color: #fff;
}
.btn.search-item{
    width: -moz-fit-content;
    width: fit-content;
}
.search-item:first-child{
    border-left: 1px solid;
}
.PageTitle_BackButtonContainer__3hxHt{
    position: relative;
    display: table-cell;
    width: -moz-fit-content;
    width: fit-content;
}
.PageTitle_BackNavButton__3I2NU{
    position: absolute;
    left: 1em;
    background: none;
    border: none;
    height: 100%;
    width: 80px;
    text-align: left;
    color: #fff;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.PageTitle_BackNavButton__3I2NU:hover{
    color: #fff900;
}
.PageTitle_BackNavButton__3I2NU svg{
    margin-right: 10px;
}
.Search_FullWidth__2hX0y{
    width: 60% !important;
}
.Search_FullWidth__2hX0y .Search_FormLabel__3G8j1{
    width: 150px;
    white-space: nowrap;
    text-align: right;
    padding-right: 10px;
}
.Search_FullWidth__RBiGA{
    width: 60% !important;
}
.Search_FullWidth__RBiGA .Search_FormLabel__V_ulx{
    width: 150px;
    white-space: nowrap;
    text-align: right;
    padding-right: 10px;
}
.TransferCreditToAccount_FullWidth__3M2en{
    width: 50% !important;

}
.TransferCreditToAccount_FullWidth__3M2en .TransferCreditToAccount_FormLabel__2X9m4{
    width: 150px;
    white-space: nowrap;
    text-align: right;
    padding-right: 10px;
}
.TransferCreditToAccount_LoadingContainer__31q0R{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.TransferCreditToAccount_TextAlert__OPEqR{
    text-align: center;
    margin-top: 1em;
    font-size: 1em;
}

.MasterPasswordAuthentication_FullWidth__16xdU{
    width: 50% !important;
}
.Login_LoginContainer__4xwOH{
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333f4f;
}
.Login_WebsiteLoginContainer__WLWEB{
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}
.Login_RegisterComplete__25K7D{
    align-items: flex-start;
}
.Login_WebsiteLoginContainer__WLWEB .Login_LoginWrapper__14Zv7{
    max-width: 100%;
    min-width: 100%;
    width: 100%;

}
.Login_LoginWrapper__14Zv7{
    min-width: 500px;
    max-width: 500px;

}

.Login_LoginBody__KCZZi{
    background-color: #222b35;
    box-shadow: 0px 0px 10px 0px #222b35;
    padding: 2em 2em;
    margin-top: 1em;
}
.Login_WebsiteLoginContainer__WLWEB label{
    font-size: 15px;
    font-weight: normal;
    line-height: 40px;
    color: #000;
}
.Login_WebsiteLoginContainer__WLWEB .Login_LoginBody__KCZZi{
    background: #fff;
    box-shadow: none;
    margin-top: 0;
}
.Login_LoginBody__KCZZi .form-control{
    background-color: transparent;
    border: 1px solid #b3b3b3;
    padding: 0.7rem 0.75rem;
}
.Login_LoginBody__KCZZi select option{
    background: #ffffff;
    color: #7a7a7a;
}

.Login_LoginBody__KCZZi h4{
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto 2em auto;
    padding: 1em;
    background: #1c242c;
    text-transform: uppercase;
    font-size: 1.3rem;
    border-radius: 4px;
}
.Login_StartCenterValue__3cZEe{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.Login_StartCenterValue__3cZEe label{
    margin: 0;
}
.Login_EndCenterValue__16U1P{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.Login_LoadingContainer__SpDJl{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.Login_WebsiteLoginContainer__WLWEB h5{
    font-size: 15px;
    color: #7a7a7a;
    font-weight: normal;
    line-height: 25px;
    margin-bottom: 1em;
    text-align: center;
}
.Login_WebsiteLoginContainer__WLWEB button{
    border-radius: 0 !important;
    font-size: 14px;
    padding: 13px 34px;
    text-transform: uppercase;
}
.Login_SubLoginText__2RZ1N{
    margin-top: 2em;
    font-size: 15px;
}
.Login_WebsiteLoginContainer__WLWEB a{
    color: #d50f0a;
}
.Login_WebsiteLoginContainer__WLWEB a:hover{
    color: #333;
    transition: all ease-in-out 0.5s;
}
.Login_Label__1XSTS{
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    color: #333;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    font-size: 15px;
    font-weight: normal;
    line-height: 40px;
}
.Login_WebsiteLoginContainer__WLWEB .form-control{
    border: 2px solid #e3e3e3;
    border-radius: 0;
    color: #333;
}
.Login_WebsiteLoginContainer__WLWEB .form-control:focus{
    border: 2px solid #d50f0a;
    box-shadow: none;
}
.Login_WebsiteLoginContainer__WLWEB .form-control:disabled{
    color: #7a7a7a;
}
footer {
    clear: both;
    position: relative;
    min-height: auto;
    margin-top: 0;
    background: #242424;
    padding: 30px 0;
}
.Login_FullHeight__27RYO{
    height: 100%;
    background: #fff;
    overflow-y: scroll;
}
.Login_Copyright__2cYF9{
    color: #d0cecf;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 1.9em;
}
footer ul{
    list-style: none;
    max-width: 90%;
    margin: 0px auto;

}
footer ul li{
    display: inline-block;
    line-height: 30px;
    display: inline-block;
    margin: 0 10px;
}
footer ul li a{
    color: #d0cecf;
    font-size: 11px;
}
footer ul li a:hover{
    color: #d50f0a;
    transition: all ease-in-out 0.5s;
}
.Login_FooterMenu__2mN1H{
    padding-bottom: 20px;
    border-bottom: 1px solid #404040;
    margin-bottom: 20px;

}
@media (min-width: 600px) {
    .Login_WebsiteLoginContainer__WLWEB .Login_LoginWrapper__14Zv7{
        min-width: 600px !important;
        max-width: 600px !important;;
        height: 600px !important;;
    }
    footer{
        min-height: 200px;
        margin-top: -200px;

    }
}
.Login_NoFloat__O_RRz{
    min-height: auto;
    margin-top: 280px;
}
.Login_SuccessLogo__3WwCQ{
    font-size: 32px;
    margin-bottom: 0.3em;
}

.Login_CountryCode__3fw82{
    width: 80px;
    border-end-end-radius: 0px;
    border-start-end-radius: 0px;

}
.Login_CountryPhoneNumber__2-vRy{
    border-start-start-radius: 0px;
    border-end-start-radius: 0px;
}
.Login_CountryCodeDropDown__1KMhT .dropdown-menu{
    max-height: 380px;
    overflow-y: scroll;
}
.Login_CountryCodeDropDown__1KMhT .dropdown-toggle{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-end-end-radius: 0px;
    border-start-end-radius: 0px;
    background: #fff;
    color: #333;
    height: 100%;
    border: 2px solid #e3e3e3 !important;
    padding: 0.3rem  0.75rem;
    border-right: 0 !important;
}
.show .btn-secondary.dropdown-toggle:focus{
    box-shadow: none !important;
}
.show .btn-secondary.dropdown-toggle{
    background: #fff;
    color: #333
}
.Login_CountryCodeDropDown__1KMhT .dropdown-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
hr{
    color: #333;
}
.Login_RegisterFooter__myJGt{
    min-height: auto;
    margin-top: 30px;
}
.Login_RegisterCheckBox__aDOTX{
    display: flex;
    align-items: center;
}
.Login_RegisterCheckBox__aDOTX label{
    font-size: 12px;
    margin-left: 0.7em;
}
.Login_RegisterCheckBox__aDOTX input{
    margin-top: 0;
    width: 1.3em;
    height: 1.3em;

}
.Login_RegisterCheckBox__aDOTX .invalid-feedback,
.Login_RegisterCheckBox__aDOTX .valid-feedback{
    display: none;
}
@media (max-width: 720px) {
    footer{
        display: none;
    }
    .Login_FullHeight__27RYO{
        min-height: 100vh;
        height: 100vh;
    }
    .Login_LoginBody__KCZZi h5{
        font-size: 12px;
    }
    .Login_SubLoginText__2RZ1N{
        font-size: 11px;
    }
    .Login_RegisterCheckBox__aDOTX label{
        line-height: 17px;
    }
    .Login_LoginWrapper__14Zv7{
        max-width: 95%;
        min-width: 95%;
    }
    .Login_LoginWrapper__14Zv7 .Login_LoginBody__KCZZi{
        padding: 1em;
    }
}
.Dashboard_FullWidth__1EoKA{
    max-width:  50% !important;
    width:  50%;
    margin-right: 200px;
}
.Dashboard_FullWidth__1EoKA .Dashboard_FormLabel__1HQCK{
    width: 250px;
    white-space: nowrap;
    text-align: right;
    padding-right: 10px;
}
.Dashboard_Title__1kODf{
    font-size: 14px;
    background: #333f4f;
    margin: 0 2em 0 2em;
    color: #fff900;
    padding: 1em;
    text-align: center;
}
.PriceInput_Symbol__Cqmrw{
    font-size: 14px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.Profile_LoginContainer__2RTP_{
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333f4f;
}
.Profile_LoginWrapper__d_jib{
    max-width: 500px;
    min-width: 500px;
    width: 500px;



}
.Profile_LoginBody__1dNis{
    background-color: #222b35;
    box-shadow: 0px 0px 10px 0px #222b35;
    padding: 2em 2em;
    margin-top: 1em;
}
.Profile_LoginBody__1dNis .form-control{
    background-color: transparent;
    border: 1px solid #b3b3b3;
    padding: 0.7rem 0.75rem;
}
.Profile_LoginBody__1dNis h4{
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto 2em auto;
    padding: 1em;
    background: #1c242c;
    text-transform: uppercase;
    font-size: 1.3rem;
    border-radius: 4px;
}
.Profile_StartCenterValue__1FXdz{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.Profile_StartCenterValue__1FXdz label{
    margin: 0;
}
.Profile_EndCenterValue__LhUw7{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.Profile_CountryCode__LjKZ6{
    width: 80px;
    border-end-end-radius: 0px;
    border-start-end-radius: 0px;
}
.Profile_CountryPhoneNumber__3KlqY{
    border-start-start-radius: 0px;
    border-end-start-radius: 0px;
    border-left: 0 !important;
}
.Profile_CountryCodeDropDown__jU2uX .dropdown-menu{
    max-height: 380px;
    overflow-y: scroll;
}
.Profile_CountryCodeDropDown__jU2uX .dropdown-toggle{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-end-end-radius: 0px;
    border-start-end-radius: 0px;
    background: #29333e;
    height: 100%;
    border: 1px solid #b3b3b3 !important;
    padding: 0.3rem  0.75rem;
}
.Profile_CountryCodeDropDown__jU2uX .dropdown-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.EnterManuallyWinningNumber_FullWidth__1qlju{
    width: 50% !important;
}
.EnterManuallyWinningNumber_LoadingContainer__1v1DH{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.EnterManuallyWinningNumber_TextAlert__3giNz{
    text-align: center;
    margin-top: 1em;
    font-size: 1em;
}
.Top37Payouts_WonBoard__10Qhj{
    width: 100%;
    font-family: "LCD-Font";
    font-size: 16px;
    border-radius: 10px;
    margin-top: 1em;
}

.SendEmail_FullWidth__3A5IQ{
    width: 100% !important;

}
.SendEmail_FullWidth__3A5IQ .SendEmail_FormLabel__1ls_0{
    float: left;
    margin-bottom: 0.5em !important;
}
.SendEmail_LoadingContainer__2lYuY{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.SendEmail_TextAlert__UDXfH{
    text-align: center;
    margin-top: 1em;
    font-size: 1em;
}
.Ticketing_BackBar__4LmNa{

}
.Ticketing_BackBar__4LmNa a {
    color: #fff;
}
.Ticketing_BackBar__4LmNa a:hover{
    color: #fff900;
}
.Ticketing_SubmitBox__3uN6r{
    padding: 3em 0;
    background: #2d3a48;
    margin-top: 1em;
    border-radius: 7px;
    box-shadow: 0px 0px 13px #343434;
}
.Ticketing_SubmitBox__3uN6r .col-md-9{
    width: 100% !important;
}
.Ticketing_SubmitBox__3uN6r .col-md-4{
    width: 20% !important;
}
