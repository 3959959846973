.UploadBox{
    background: #1c1c1e;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
.FullWidth{
    max-width: 90% !important;
}
.TitleBox{
    color: #fff900;
}