.FullWidth{
    width: 50% !important;
}
.LoadingContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.TextAlert{
    text-align: center;
    margin-top: 1em;
    font-size: 1em;
}