
.Ticket{
    width: 100%;
    background: #39495a;
    padding: 10px 0px;
    border-radius: 7px;
    box-shadow: 0px 0px 13px #343434;
    margin: 10px 0;
}
.Ticket .TicketHeader{
    padding: 5px 20px 10px 20px;
    border-bottom: 1px solid #303f4e;
    display: flex;
}
.Ticket .TicketHeaderAvatar{
    min-width: 40px;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Ticket .TicketHeaderBase{
    width: 100%;
}
.Ticket .TicketHeaderBaseCenter{
    display: flex;
    align-items: center;
}
.Ticket .TicketHeaderBaseCenter h1{
    margin: 0;
}
.Ticket .TicketHeaderDate{
    min-width: 180px;
    width: 180px;
    height: 40px;
    font-size: 11px;
    text-align: center;
}
.Ticket .TicketHeaderAvatar div{
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e7e7e7;
    border-radius: 100%;
    color: #6d849d;
    font-size: 21px;
    font-weight: bold;
}
.Ticket .TicketBody{
    padding: 5px 20px;
}
.TicketBody h6{
    margin: 9px 0;
    font-size: 14px;
    color: #fff;
}
.Ticket h1{
    font-size: 14px;
    color: #fff900;
}
.Ticket h3{
    font-size: 12px;

}
.Ticket .TicketBody{
    font-size: 12px;
    color: #d1d1d1;
}
.AgentTicket{
    background-color: #424242;
}
.AgentTicket .TicketHeader{
    border-color: #383838;
}
.OwnerTicket{
    background-color: #2d3a48;
}
.FirstTicket{
    margin-bottom: 4em;
}
.UploadedFiles{
    display: flex;
    flex-wrap: wrap;
    margin: 1em 0;
}
.AttachItem{
    width: 120px;
    height: 120px;
    padding: 3px;
    margin: 5px;
    background: rgba(255,255,255,0.4);
    border-radius: 3px;
    position: relative;
}
.AttachItem img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}