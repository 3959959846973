.FullWidth{
    width: 100% !important;

}
.FullWidth .FormLabel{
    float: left;
    margin-bottom: 0.5em !important;
}
.LoadingContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.TextAlert{
    text-align: center;
    margin-top: 1em;
    font-size: 1em;
}