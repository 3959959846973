.Counter{
    min-width: 100px;
    font-family: "LCD-Font", "IranSANS";
    margin: 0 2em;
}
.CounterLabel{
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 5px;
    text-transform: uppercase;
    text-align: right;
    color: #7d7d7d;
    margin: 1em 0 0 0;
}
.CounterNumber{
    font-size: 50px;
    font-weight: normal;
    color: var(--color-board-number);
    letter-spacing: 7px;
    text-align: right;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.OffNumber{
    color: #fff;
    opacity: 0.16;
}
.Number{
    display: flex;
    align-items: center;
    letter-spacing: 4px;
}
@media (max-width: 720px) {
    .CounterLabel{
        font-size: 9px;
        white-space: nowrap;
    }
    .CounterNumber{
        font-size: 20px;
    }
    .Counter{
        margin: 0 0.5em 1em 0.5em;
        min-width: auto;
    }

}
@media (min-width: 721px) and (max-width: 1023px){
    .Counter{
        margin: 0 0.5em 1em 0.5em;
        min-width: auto;
    }
    .CounterNumber{
        font-size: 35px;
    }
    .CounterLabel{
        font-size: 14px;;
        white-space: nowrap;
    }
}
@media (min-width: 768px) {

}
@media (min-width: 992px) {
    .CounterLabel{
        font-size: 15px;
    }
    .CounterNumber{
        font-size: 31px;
    }
    .Counter{
        margin: 0 0.3em;
    }
}
@media (min-width: 1024px) and (max-width: 1199px){
    .CounterLabel{
        font-size: 12px;
    }
    .CounterNumber{
        font-size: 22px;
    }
    .Counter{
        min-width: auto;
        margin: 0 0.3em;
    }

}
@media (min-width: 1200px) and (max-width: 1299px) {
    .CounterLabel{
        font-size: 15px;
    }
    .CounterNumber{
        font-size: 31px;
    }
    .Counter{
        margin: 0 0.3em;
    }
}
@media (min-width: 1300px) and (max-width: 1399px) {
    .CounterLabel{
        font-size: 15px;
    }
    .CounterNumber{
        font-size: 35px;
    }
    .Counter{
        margin: 0 0.7em;
    }
}
@media (min-width: 1400px) {
    .CounterLabel{
        font-size: 15px;
    }
    .CounterNumber{
        font-size: 46px;
    }
    .Counter{
        margin: 0 1em;
    }
}