.AppLogoContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: transparent;
}
.AppLogo {
    height: 15vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .AppLogo {
        animation: AppLogoSpin infinite 3s linear;
    }
}
@keyframes AppLogoSpin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}