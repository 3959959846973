@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://cdn.ninthware.com/scripts/css/LCD.css');
@font-face {
    font-family: 'LCD-Font';
    src:url('../fonts/LCDBOLD.woff') format('woff'),
    url('../fonts/LCDBOLD.eot'),
    url('../fonts/LCDBOLD.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Agressive-Font';
    src:url('../fonts/AGRESSIVE.woff') format('woff'),
    url('../fonts/AGRESSIVE.eot'),
    url('../fonts/AGRESSIVE.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body,
html{
    font-family: "Poppins", "IranSANS", sans-serif;
    background-color: var(--color-background);
    color: var(--text-color);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    height: 100%;
    margin: 0;

}

a{
    text-decoration: none;
    color: var(--color-primary);
}
a:hover{
    color: var(--color-secondary);
    transition: all ease-in-out 0.3s;
}

.Link{
    text-decoration: none;
    color: #d50f0a;
}
.Link:hover{
    color: #fff;
}
.LoadingContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: transparent;
    height: calc(100vh - 100px);

}