:global(.volume) input[type=range]{
    display: none;
}
:global(.volume){
    display: flex;
    justify-content: center;
    align-items: center;
}    
:global(.bar-hoverbox){
    padding: 10px 3px;
    opacity: 0.7;
    transition: opacity .2s;
    width: calc(100% - 32px);
}    
:global(.bar-hoverbox):hover{
    opacity: 1;
    cursor: pointer;
}
:global(.bar){
    background: #999;
    height: 7px;
    width: 120px;
    border-radius: 15px;
    overflow: hidden;
    pointer-events: none;
}
:global(.bar .bar-fill){
    background: #FFF;
    width: 0%;
    height: 100%;
    background-clip: border-box;
    pointer-events: none;
}
.VolumeMuteButton{
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.VolumeMuteButtonSvg svg{
    stroke: #f90505 !important;
    fill: #f90505 !important;
}
.VolumeMuteButton svg{
    stroke: #a8a8a8;
    fill: #a8a8a8;
}
.VolumeMuteButton:hover > svg{
    stroke: #3dc733;
    fill: #3dc733;
}