.FullWidth{
    width: 50% !important;

}
.FullWidth .FormLabel{
    width: 150px;
    white-space: nowrap;
    text-align: right;
    padding-right: 10px;
}
.LoadingContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.TextAlert{
    text-align: center;
    margin-top: 1em;
    font-size: 1em;
}
