.FullWidth{
    max-width: 80% !important;
}
.TabsContainer{
    max-width: 80%;
    margin: 2em auto 0 auto;
    border-bottom: 2px solid #4c5c6c;
    background: #313d4a;
}
.TabsContainer :global(.nav-link){
    flex: 1;
    padding: 1em;
    border: 0;
    color: #fff;
    font-size: 15px;
}
.TabsContainer :global(.nav-link.active),
.TabsContainer :global(.nav-link:hover)
{
    background: transparent;
    border-bottom: 4px solid #fff900;
    color: #fff900;
}
.TextAlert{
    color: #fff !important;
    text-align: center;
    margin-top: 1em;
    font-size: 1em;
}
.PaymentImage{
    max-width: 60%;
}
@media (max-width: 720px) {
    .PaymentImage{
        max-width: 100%;
    }
}
.Timer{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 3px;
    padding: 1em;
}