
.Container{
    width: 100%;
    height: 100%;
    min-height: 100%;
    background-color: #222b35;
    display: table;
}
.Container :global(.form-control){
    background-color: transparent;
    border: 1px solid #b3b3b3;
    padding: 0.7rem 0.75rem;
}
.Container :global(.form-center){
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em 0;
}
.Container :global(.form-center-container){

}
.Sidebar{
    width: 350px;
    min-width: 350px;
    background-color: #333f4f;
    height: 100%;
    display: table-cell;
}
.AdminSidebar{
    font-size: 0.4em;
    width: 350px;
    min-width: 350px;
    background-color: #333f4f;
    height: 100%;
    display: table-cell;
    vertical-align: top;
}

.SidebarWrapper{
}
.MainContainer{
    width: 100%;
}
.MapDashboard{
    font-size: 1.4rem;
    background: #29333e;
    width: 100%;
    height: 3.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
}
.MainWrapper{
    padding: 1em;
}
.AdminMainWrapper{
    padding: 1em 3em;
}
.MainWrapper form{
    max-width: 600px;
    width: 100%;
}
.SidebarMenu{
    margin: 1em;
    list-style: none;
    padding: 0;
}
.SidebarMenu li{
    text-align: center;
    padding: 0.7em 0;
}
.SidebarMenu li h5{
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1rem;
}
.SidebarMenu li span{
    color: #fff900;
    font-size: 1rem;
    font-weight: 400;
}
.LineMenuItem{
    display: flex;
    justify-content: center;
    align-items: center;
}
.OnlineUsersLink{
    color: #fff900 !important;
    font-size: 0.8rem !important;
    text-decoration: underline !important;

}
.OnlineUsersLink:hover{
    color: #fff !important;
}
.MenuRadioItem{
    font-size: 2.5em;
    margin-left: 1em;
}
.LineMenuItem h5{
    margin-bottom: 0;
    margin-right: 5px;
}
.AdminSidebar .SidebarMenu li{
    padding: 1.3em 0;
}
.AccountInfo{
    margin-top: 3em;
    display: flex;
    justify-content: center;
    align-items: center;

}
.AccountInfo h5{
    margin-right: 0.8em;
    margin-bottom: 0;
}
.SidebarMenu li a{
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1rem;
    transition: all ease-in-out 0.3s;
}
.SidebarMenu li a:hover{
    color: #fff900;
    transition: all ease-in-out 0.3s;
}
.SidebarMenu li a.LinkActive{
    color: #fff900;
    transition: all ease-in-out 0.3s;
}
.LogoutButton svg{
    font-size: 28px;
}
.LoadingContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}


.LineAccountInfo{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3em 0 !important;
}
.LineAccountInfo h5{
    margin-right: 0.8em;
    margin-bottom: 0;
}
.MobileNavButton{
    display: none;
}
.BackNavButton{
    position: absolute;
    left: 1em;
    background: none;
    border: none;
    height: 100%;
    width: 80px;
    text-align: left;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.BackNavButton:hover{
    color: #fff900;
}
.BackNavButton svg{
    margin-right: 10px;
}
.SidebarLogo{
    width: 150px;
}

@media (max-width: 720px) {
    .SidebarLogo{
        width: 100px;
    }
    .BackNavButton{
        left: auto;
        right: 0.2em;
    }
    .OverlayLock{
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        position: absolute;
        top: 64px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99999999;
    }
    .MapDashboard{
        height: 64px;
    }
    .Sidebar{
        width: 300px;
        min-width: 300px;
        position: absolute;
        left: -300px;
        top: 64px;
        transition: left linear 0.3s;
        z-index: 999999999;
        overflow-y: scroll;
    }
    .AdminSidebar{
        width: 300px;
        min-width: 300px;
        position: absolute;
        left: -300px;
        top: 64px;
        transition: left linear 0.3s;
        z-index: 999999999;
        overflow-y: scroll;
    }
    .Container :global(.form-center){
        margin: 1em;
    }
    .MainWrapper form{
        max-width: 100% !important;
    }
    .MobileNavButton{
        display: block;
        position: absolute;
        left: 1em;
        background: none;
        border: none;
        height: 100%;
        width: 50px;
        text-align: left;
        color: #fff;
        font-size: 20px;;
    }
    .MobileNavButton:focus{
        outline: none;
        color: #a60000;
    }
    .OpenedSidebarMenu{
        left: 0px;
        transition: left linear 0.3s;
        padding-bottom: 4em;
    }

    .SidebarWrapper{
        padding-bottom: 4em;
    }

    .Container :global(.form-control){
        background-color: #29333e;
        border: 1px solid #29333e;
        padding: 0.7rem 0.75rem;
    }
    .Container :global(.input-group-text){
        background-color: #222b35;
        border: 1px solid #29333e;
        font-size: 0.9em;
    }
}
